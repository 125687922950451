import { useState, useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes, faImage } from '@fortawesome/free-solid-svg-icons';
import Lottie from 'react-lottie';
import animationData from "../../Assets/Lottie/generate-from-file.json";
import api from '../../api';


const UploadImageModal = ({ showModal, setShowModal, processData, loading, setLoading, setLimitReason, setShowLimitModal }) => {
  const [dragOver, setDragOver] = useState(false);
  const [fileUploadErrors, setFileUploadErrors] = useState("");
  const [files, setFiles] = useState([]);

  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: animationData,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice'
    }
  };

  const handleDragEnter = (e) => {
    e.preventDefault();
    e.stopPropagation();
    setDragOver(true);
  };

  const handleDragLeave = (e) => {
    e.preventDefault();
    e.stopPropagation();
    setDragOver(false);
  };

  const handleDragOver = (e) => {
    e.preventDefault();
    e.stopPropagation();
    setDragOver(true);
  };

  const handleDrop = (e) => {
    e.preventDefault();
    e.stopPropagation();
    setDragOver(false);
    if (e.dataTransfer.files && e.dataTransfer.files.length > 0) {
      processFiles(e.dataTransfer.files);
      e.dataTransfer.clearData();
    }
  };

  const processFiles = (selectedFiles) => {
    const newFiles = Array.from(selectedFiles);
    if (files.length + newFiles.length > 3) {
      setFileUploadErrors("You can upload a maximum of 3 images.");
      return;
    }
    setFileUploadErrors("");
    setFiles(prevFiles => [...prevFiles, ...newFiles]);
  };
  

  const uploadFiles = () => {
    if (files.length === 0) {
      setFileUploadErrors("You need to upload at least one image.")
      return;
    }
    setLoading(true);
    const formData = new FormData();
    files.forEach(file => {
      formData.append('image', file);
    });

    api.post("/api/flashcards/generate_flashcards/", formData, {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    })
      .then((response) => {
        console.log("Response: ", response.data);
        if (response.data.enough_images === false && response.data.enough_images !== undefined) {
          setLimitReason("tokens");
          setShowLimitModal(true);
          setShowModal(false)
          setLoading(false);
        } else {
          processData(response.data);
        }
      })
      .catch((error) => {
        console.error(error);
      });
  };







  return showModal && (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center">
      <div className="bg-gray-100 rounded-lg shadow-lg w-full relative max-w-5xl">
        {loading ? (
          <div className='h-[582px] flex flex-col items-center justify-center'>
            <Lottie options={defaultOptions} height={450} width={450} />
            <h1 className='text-xl font-semibold text-purple-500'>Generating flashcards...</h1>
          </div>
        ) : (
          <div>
            <div className='px-8 pt-8'>
              <h2 className="text-2xl font-bold">Upload Images</h2>
              <h3 className='mt-2 text-lg'>Please ensure that your images are not bigger than 10MB.</h3>
              <h3 className='text-lg mb-6'>Only PNG and JPEG files are allowed.</h3>
              <div className={`h-[300px] border-4 border-dashed rounded-lg ${dragOver ? 'border-blue-600 bg-blue-200' : 'border-blue-400 bg-blue-100'} relative`}
                onDragEnter={handleDragEnter}
                onDragOver={handleDragOver}
                onDragLeave={handleDragLeave}
                onDrop={handleDrop}>
                <input type="file" id="file-upload" className="opacity-0 w-full h-full absolute inset-0 cursor-pointer"
                  onChange={(e) => processFiles(e.target.files)}
                  accept=".png,.jpeg"
                  multiple
                />
                <label htmlFor="file-upload" className="text-center flex flex-col items-center justify-center h-full absolute inset-0">
                  {files.length === 0 && (
                    <div className="flex flex-col items-center justify-center">
                      <p className="text-blue-600 font-medium">Drag and drop your images here or click to select files.</p>
                      <p className="text-blue-600 font-medium">Only PNG and JPEG files are allowed.</p>
                    </div>
                  )}
                  {files.length > 0 && (
                    <div className={`flex w-full ${files.length === 1 ? 'justify-between' : 'justify-start'}`}>
                      {files.map((file, index) => (
                        <div key={index} className={`flex-1 flex flex-col items-center justify-center ${files.length === 1 ? 'w-1/2' : files.length === 2 ? 'w-1/3' : 'w-full'}`}>
                          <FontAwesomeIcon icon={faImage} className='text-blue-500 text-8xl' />
                          <p className='mt-4 mb-4 text-lg text-blue-600 font-medium'>{file.name}</p>
                          <button className='bg-blue-200 text-blue-600 rounded-full px-3 py-1.5 font-semibold hover:bg-blue-300 hover:text-blue-700'
                            onClick={() => setFiles(prevFiles => prevFiles.filter((_, i) => i !== index))}>
                            Delete Image
                          </button>
                        </div>
                      ))}
                      {files.length < 3 && (
                        <div className={`flex-1 flex flex-col items-center justify-center ${files.length === 1 ? 'w-1/2' : 'w-1/3'}`}>
                          
                          <div className='bg-blue-200 text-blue-600 rounded-full px-3 py-1.5 font-semibold hover:bg-blue-300 hover:text-blue-700'>
                            + Add Image
                          </div>
                        </div>
                      )}
                    </div>
                  )}
                </label>
              </div>



              <p className='text-lg font-medium text-red-500 mt-3'>{fileUploadErrors}</p>
            </div>
            <div className='border-b-2 border-blue-400 mt-8' />

            <div className="flex justify-between mt-4 px-8 pb-8">
              <button className="bg-blue-500 text-white rounded-md px-4 py-2 font-semibold" onClick={() => setShowModal(false)}>Cancel</button>
              <button className="bg-blue-500 text-white rounded-md px-4 py-2 font-semibold" onClick={uploadFiles}>Generate</button>
            </div>
            <button
              onClick={() => setShowModal(false)}
              className="absolute top-5 right-5 text-2xl hover:text-gray-500 text-gray-800">
              <FontAwesomeIcon icon={faTimes} />
            </button>
          </div>
        )}
      </div>
    </div>
  );
};

export default UploadImageModal;




















//OLD

// import { useState, useEffect } from 'react';
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
// import { faTimes, faImage } from '@fortawesome/free-solid-svg-icons';
// import Lottie from 'react-lottie';
// import animationData from "../../Assets/Lottie/generate-from-file.json";
// import api from '../../api';

// const UploadImpageModal = ({ showModal, setShowModal, processData, loading, setLoading }) => {
//   const [dragOver, setDragOver] = useState(false);
//   const [fileUploadErrors, setFileUploadErrors] = useState("");
//   const [fileSubmitted, setFileSubmitted] = useState(false);
//   const [fileName, setFileName] = useState("");
//   const [file, setFile] = useState(null);
//   const [userCharacterLimit, setUserCharacterLimit] = useState(10000);
//   const [numberOfFlashcards, setNumberOfFlashcards] = useState(1);


//   const defaultOptions = {
//     loop: true,
//     autoplay: true,
//     animationData: animationData,
//     rendererSettings: {
//       preserveAspectRatio: 'xMidYMid slice'
//     }
//   };


//   const numberOfFlashcardOptions = [];
//   for (let i = 1; i <= 5; i++) {
//     numberOfFlashcardOptions.push(
//       <option key={i} value={i}>
//         {i}
//       </option>
//     );
//   }

//   for (let i = 10; i <= 50; i += 5) {
//     numberOfFlashcardOptions.push(
//       <option key={i} value={i}>
//         {i}
//       </option>
//     );
//   }


//   useEffect(() => {
//     api.get('api/subscription/' )
//     .then((response) => {
//       console.log(response.data);
//       const plan = response.data.plan;
//       if (plan === "basic") {
//         setUserCharacterLimit(10_000);
//       }
//       if (plan === "max") {
//         setUserCharacterLimit(50_000);
//       }
//     })
//     .catch((error) => {
//       console.error(error);
//     })
//   }, []);




//   const handleDragEnter = (e) => {
//     e.preventDefault();
//     e.stopPropagation();
//     setDragOver(true);
//   };

//   const handleDragLeave = (e) => {
//       e.preventDefault();
//       e.stopPropagation();
//       setDragOver(false);
//   };

//   const handleDragOver = (e) => {
//       e.preventDefault();
//       e.stopPropagation();
//       setDragOver(true);
//   };

//   const handleDrop = (e) => {
//       e.preventDefault();
//       e.stopPropagation();
//       setDragOver(false);
//       if (e.dataTransfer.files && e.dataTransfer.files.length > 0) {
//         processFile(e.dataTransfer.files);
//         e.dataTransfer.clearData();
//       }
//   };

//   const processFile = (file) => {
//     setFileUploadErrors("");
//     setFileSubmitted(true);
//     setFileName(file[0].name);
//     console.log("Image: ", file[0]);
//     setFile(file[0]);

//     //TODO: need to change here because this time it is more than one file
//   };
  

//   const uploadFile = () => {
//     const formData = new FormData();
//     console.log("this is the image: ", file);

//     formData.append('image', file); 
//     formData.append('number_of_flashcards', numberOfFlashcards);

//     api.post("/api/flashcards/generate_flashcards/", formData, {
//       headers: {
//         'Content-Type': 'multipart/form-data'
//       }
//     })
//     .then((response) => {
//       console.log(response.data);
//     })
//     .catch((error) => {
//       console.error(error);
//     })
//   };




  
//   return showModal && (
//     <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center">
//         <div className="bg-gray-100 rounded-lg shadow-lg w-full relative max-w-5xl">
//           {loading ? (
//             <div className='h-[698px] flex flex-col items-center justify-center'>
//               <Lottie 
//                 options={defaultOptions}
//                 height={500}
//                 width={500}
//               />
//               <h1 className='text-xl font-semibold text-purple-500'>Generating flashcards...</h1>
//             </div>
//           ) : (
//             <div>
//               <div className='px-8 pt-8'>
//                 <h2 className="text-2xl font-bold">Upload Images</h2>
//                 <h3 className='mt-2 text-lg'>Please ensure that your images are not bigger than 10MB. //TODO: write this better//</h3>
//                 <h3 className='text-lg mb-6'>Only PNG and JPEG files are allowed.//TODO: it can also just be images from vocabulary books only they will work</h3>
//                 <div className={`h-[300px] border-4 border-dashed rounded-lg ${dragOver ? 'border-blue-600 bg-blue-200' : 'border-blue-400 bg-blue-100'} relative`}
//                     onDragEnter={handleDragEnter}
//                     onDragOver={handleDragOver}
//                     onDragLeave={handleDragLeave}
//                     onDrop={handleDrop}>
//                     {!fileSubmitted && (
//                       <input type="file" id="file-upload" className="opacity-0 w-full h-full absolute inset-0 cursor-pointer"
//                         onChange={(e) => processFile(e.target.files)}
//                         accept=".png,.jpeg"
//                         onClick={(event) => { event.target.value = null; }}
//                         multiple={false}
//                       />
//                     )}


//                     <label htmlFor="file-upload" className={`text-center flex flex-col items-center justify-center h-full `}>
//                       {fileSubmitted ? (
//                         <div>
//                           <FontAwesomeIcon icon={faImage} className='text-blue-500 text-8xl' />
//                           <p className='mt-4 mb-8 text-lg text-blue-600 font-medium'>{fileName}</p>
//                           <button className=' bg-blue-200 text-blue-600 rounded-full px-3 py-1.5 font-semibold hover:bg-blue-300 hover:text-blue-700' onClick={() => setFileSubmitted(false)}>
//                             Change Image
//                           </button>
//                         </div>
//                       ) : (
//                         <div>
//                           <p className="text-blue-600 font-medium">Drag and drop your images here or click to select files.</p>
//                           <p className="text-blue-600 font-medium">Only PNG and JPEG files are allowed.</p>
//                         </div>
//                       )}
//                     </label>
//                 </div>
//                 <p className='text-lg font-medium text-red-500 mt-3'>{fileUploadErrors}</p>
//                 <div className='mt-6'>
//                   <h2 className='text-xl font-bold'>Number of Flashcards</h2>
//                   <select 
//                     className='border-2 border-gray-300 rounded px-3 py-2.5 mt-3 w-[205px] font-medium'
//                     value={numberOfFlashcards}
//                     onChange={(e) => setNumberOfFlashcards(e.target.value)}
//                   >
//                     {numberOfFlashcardOptions}
                  
//                   </select>
//                 </div>
//               </div>
//               <div className='border-b-2 border-blue-400 mt-8' />

//               <div className="flex justify-between mt-4 px-8 pb-8">
//                 <button className="bg-blue-500 text-white rounded-md px-4 py-2 font-semibold" onClick={() => setShowModal(false)}>Cancel</button>
//                 <button className="bg-blue-500 text-white rounded-md px-4 py-2 font-semibold" onClick={uploadFile}>Upload</button>
//               </div>
//               <button
//                 onClick={() => setShowModal(false)}
//                 className="absolute top-5 right-5 text-2xl hover:text-gray-500 text-gray-800">
//                 <FontAwesomeIcon icon={faTimes} />
//               </button>
//             </div>
//           )}

//         </div>
//     </div>
// );
// };
// export default UploadImpageModal;