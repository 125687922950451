import axios from "axios";
import { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import Cookies from "js-cookie";
import api from "../api";

const LoginPage = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [emailError, setEmailError] = useState(false);
  const [emailErrorMessage, setEmailErrorMessage] = useState("");
  const [passwordError, setPasswordError] = useState(false);
  const [passwordErrorMessage, setPasswordErrorMessage] = useState("");
  const [nonFieldError, setNonFieldError] = useState("");
  const navigate = useNavigate();

  const fetchSubscriptionData = () => {


    api.get("/api/payment/subscriptions/")
      .then((response) => {
        console.log("This is the subscription data");
        console.log(response.data);
        const subscriptionTier = response.data.subscription_tier;
        console.log("THIS IS THE SUBSCRIPTION TIER: ");
        console.log(subscriptionTier);
        localStorage.setItem("subscriptionTier", subscriptionTier);
        console.log("\n SUCCESSFULLY FETCHED SUBSCRIPTION DATA \n");
        navigate("/");
      })
      .catch((error) => {
        console.error("Error fetching subscription data:", error);
      });
  };

  const handleButtonClicked = (event) => {
    event.preventDefault();
    const data = {
      email: email,
      password: password,
    };
    console.log("handle button clicked got called");

    axios
      .post("/api/auth/login/", data)
      .then((response) => {
        
        console.log("response: " ,response.data);

        Cookies.set("accessToken", response.data.access, {
          expires: 2,
          secure: true,
          sameSite: "strict",
        });
        Cookies.set("refreshToken", response.data.refresh, {
          expires: 90,
          secure: true,
          sameSite: "strict",
        });

        sessionStorage.setItem("isAuthenticated", true);

        fetchSubscriptionData();
      })
      .catch((error) => {
        console.error(error);
        if (error.response.data.non_field_errors) {
          setNonFieldError(error.response.data.non_field_errors);
          setEmailError(true);
          setPasswordError(true);
        }
        if (error.response.data.email) {
          setEmailError(true);
          setEmailErrorMessage(error.response.data.email);
        }
        if (error.response.data.password) {
          setPasswordError(true);
          setPasswordErrorMessage(error.response.data.password);
        }
      });
  };

  return (
    <section className="bg-gray-50 dark:bg-gray-900 min-h-screen">
      <div className="flex flex-col items-center justify-center px-6 py-8 mx-auto md:h-screen lg:py-0">
        <Link to="/" className="absolute left-6 top-6 dark:text-white">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            className="h-6 w-6 text-gray-900 dark:text-white" // Adjusted color here
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth={2}
              d="M15 19l-7-7 7-7"
            />
          </svg>
        </Link>
        <p className="flex items-center mb-6 text-2xl font-semibold text-gray-900 dark:text-white">
          <img
            className="w-8 h-8 mr-2"
            src="https://tailwindui.com/img/logos/workflow-mark-indigo-500.svg"
            alt="logo"
          />
          Study buddy
        </p>
        <div className="w-full bg-white rounded-lg shadow dark:border md:mt-0 sm:max-w-md xl:p-0 dark:bg-gray-800 dark:border-gray-700">
          <div className="p-6 space-y-4 md:space-y-6 sm:p-8">
            <h1 className="text-xl font-bold leading-tight tracking-tight text-gray-900 md:text-2xl dark:text-black">
              Sign in to your account
            </h1>
            <form className="space-y-4 md:space-y-6" onSubmit={(event) => handleButtonClicked(event)}>
              <div>
                <label htmlFor="email" className="block mb-2 text-sm font-medium text-gray-900 dark:text-black">
                  Your email
                </label>
                <input
                  type="email"
                  name="email"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  autoComplete="email"
                  id="email"
                  className={`bg-gray-50 ${emailError ? 'border-2 border-red-500' : 'border border-gray-300'} text-gray-900 sm:text-sm rounded-lg focus:ring-blue-600 focus:border-blue-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-black dark:focus:ring-blue-500 dark:focus:border-blue-500`}
                  placeholder="name@company.com"
                  required
                />
                {emailErrorMessage && <span className="text-red-500 text-sm">{emailErrorMessage}</span>}
              </div>
              <div>
                <label htmlFor="password" className="block mb-2 text-sm font-medium text-gray-900 dark:text-black">
                  Password
                </label>
                <input
                  type="password"
                  name="password"
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                  autoComplete="new-password"
                  id="password"
                  placeholder="••••••••"
                  className={`bg-gray-50 ${passwordError ? 'border-2 border-red-500' : 'border border-gray-300'} text-gray-900 sm:text-sm rounded-lg focus:ring-blue-600 focus:border-blue-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500`}
                  required
                />
                {passwordErrorMessage && <span className="text-red-500 text-sm">{passwordErrorMessage}</span>}
              </div>
              <div className="flex items-center justify-between">
                <a
                  href="/password/reset/"
                  className="text-sm font-medium text-blue-600 hover:underline dark:text-blue-500 hover:text-blue-700"
                >
                  Forgot password?
                </a>
              </div>
              {nonFieldError && <div className="text-red-500 text-sm font-bold mt-4">{nonFieldError}</div>}
              <button
                type="submit"
                className="w-full text-white bg-blue-600 hover:bg-blue-700 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
              >
                Sign in
              </button>
              <p className="text-sm font-light text-gray-500 ">
                Don't have an account yet?{" "}
                <a
                  href="/register/"
                  className="font-medium text-blue-600 hover:underline hover:text-blue-700"
                >
                  Sign up
                </a>
              </p>
            </form>
          </div>
        </div>
      </div>
    </section>
  );
};

export default LoginPage;















