import { faTimes } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const ConfirmDeletionModal = ({ showModal, setShowModal, handleDelete, header, subHeader, text, buttonLabel }) => {
  return showModal && (
    <div className="fixed inset-0 bg-black z-50 bg-opacity-50 flex justify-center items-center">
      <div className="bg-white  rounded-xl shadow-lg max-w-lg w-full m-4">
        <div className="p-8">
          <div className="flex w-full justify-end">
            <FontAwesomeIcon icon={faTimes} className="text-gray-500 hover:text-gray-600 text-2xl" onClick={() => setShowModal(false)} />
          </div>
          <h1 className="text-3xl font-bold">{header}</h1>
          {subHeader && <h2 className="text-xl font-semibold mt-2">{subHeader}</h2>}
          <p className="text-lg mt-4">{text}</p>
        </div>
        <div className="border-b-2 border-gray-200" />

        <div className="flex justify-between space-x-4 mt-6 pb-8 px-8">
          <button
            onClick={() => setShowModal(false)}
            className="px-4 py-2 bg-gray-300 text-black rounded hover:bg-gray-400 transition"
          >
            Cancel
          </button>
          <button
            onClick={() => {
              handleDelete();
              setShowModal(false);
            }}
            className="px-4 py-2 bg-red-600 text-white rounded hover:bg-red-700 transition font-semibold"
          >
            {buttonLabel}
          </button>
        </div>

      </div>
    </div>
  );
};

export default ConfirmDeletionModal;
