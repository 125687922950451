// const CorrectFooter = ({ points }) => {
//   return (
//     <div className="flex justify-between items-center my-16 px-8 md:px-10 py-3 md:py-10 bg-green-300  rounded-lg">
//       <div className="text-green-800 text-xl md:text-2xl font-medium">Correct!</div>
//       <div className="text-green-800 text-xl  md:text-2xl font-medium">{points} Points</div>
//     </div>
//   );
// };

// export default CorrectFooter;

const CorrectFooter = ({ points }) => {
  return (
    <div className="flex justify-between items-center my-16 px-8 md:px-10 lg:px-12 py-4 md:py-10 lg:py-14 bg-green-300 rounded-lg">
      <div className="text-green-800 text-lg md:text-2xl lg:text-3xl font-medium">Correct!</div>
      <div className="text-green-800 text-lg md:text-2xl lg:text-3xl font-medium">{points} Points</div>
    </div>
  );
};

export default CorrectFooter;

