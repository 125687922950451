const WrongFooter = () => {
  return (
    <div>
    <div className="flex justify-between items-center my-16 px-5 md:px-8 lg:px-10 py-4 md:py-10 lg:py-14 bg-correction-red rounded-lg border-4 border-red-500">
      <div className="text-red-800 text-3xl font-medium">Wrong!</div>
    </div>
  </div>

    )
}

export default WrongFooter