const AnswerInputFieldCorrectCorrection = ({ userAnswer, showHeader }) => (
  <div className="flex flex-col items-center">
    {showHeader && <p className={`font-medium text-2xl mb-5`}>Your answer</p>}

    <input 
      readOnly
      value={userAnswer}
      className={`mb-3 p-3 w-full border-2 border-green-400 bg-green-50 rounded-md shadow-green-500/40 shadow-xl`}
    />
  </div>
);
export default AnswerInputFieldCorrectCorrection;