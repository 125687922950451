import api from "../api";
import Cookies from "js-cookie";

const Logout = () => {   
    const handleLogoutButton = () => {
        api.post("/api/auth/logout/")
            .then((response) => {
                console.log("successfully logged out")
                window.location.href = "/";
                Cookies.remove("accessToken");
                Cookies.remove("refreshToken");
                sessionStorage.removeItem("isAuthenticated");
            })
            .catch((error) => {
            console.error("Error fetching logout data:", error);
            });
        };

    return (
        <div>
            <h1>Logout</h1>
            <div>
                <button onClick={handleLogoutButton}>Do you really want to logout</button>
            </div>
        </div>
    );
}

export default Logout;