


const UploadModal = ({ showModal, setShowModal, handleDocumentUpload, handleTextUpload, handleImageUpload }) => {


  

  return (
    <div>

    </div>
  )
};
export default UploadModal;