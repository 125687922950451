import { useState, useEffect } from 'react';
import moment from 'moment';


const TimeBlock = ({ value, label }) => (
  <div className='my-2.5 justify-center items-center flex flex-col'>
    <p className='font-semibold' style={{fontSize: 21}}>{value}</p>
    <p className=' text-gray-500' style={{fontSize: 19}}>{label}</p>
  </div>
)


const CountDownTimer = ({ lastReset }) => {
  const [timeLeft, setTimeLeft] = useState({ days: 0, hours: 0, minutes: 0, seconds: 0 });


  useEffect(() => {
    const targetDate = moment(lastReset).add(1, 'month');

    const updateCountdown = () => {
      const now = moment();
      const diff = targetDate.diff(now);

      if (diff > 0) {
        const duration = moment.duration(diff);
        setTimeLeft({
          days: duration.days(),
          hours: duration.hours(),
          minutes: duration.minutes(),
          seconds: duration.seconds(),
        });
      } else {
        setTimeLeft({ days: 0, hours: 0, minutes: 0, seconds: 0 });
        clearInterval(interval);
      }
    };

    const interval = setInterval(updateCountdown, 1000);
    return () => clearInterval(interval);
  }, [lastReset]);

  const { days, hours, minutes, seconds } = timeLeft;

  return (
    <div className="flex flex-row justify-center items-center w-full">
      <div className="flex flex-row justify-center items-center sm:gap-4 md:gap-8 lg:gap-10 bg-gray-100 rounded-full sm:px-5 md:px-10 lg:px-16">
        {days > 0 && <TimeBlock value={days} label="Days" />}
        <TimeBlock value={hours} label="Hours" />
        <TimeBlock value={minutes} label="Minutes" />
        {days === 0 && <TimeBlock value={seconds} label="Seconds" />}
      </div>
    </div>
  )
};
export default CountDownTimer;