import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import 'reactjs-popup/dist/index.css';
import NotEnoughTokensModal from "../components/OtherQuestionComponents/NotEnoughTokensModal";
import TitleInput from "../components/InputComponents/TitleInput";
import SummaryInputTextarea from "../components/SummaryQuestionComponents/SummaryInputTextarea";
import NumberOfSummaryQuestions from "../components/SummaryQuestionComponents/NumberOfSummaryQuestions";
import useAxiosGet from "../hooks/useAxiosGet";
import api from "../api";


const ExamInput = () => {
  const [summary, setSummary] = useState("");
  const [title, setTitle] = useState("");
  const [numberOfQuestionsAvailable, setNumberOfQuestionsAvailable] = useState(25);
  const [numberOfMultipleChoice, setNumberOfMultipleChoice] = useState(0);
  const [numberOfTrueFalse, setNumberOfTrueFalse] = useState(0);
  const [numberOfFillBlank, setNumberOfFillBlank] = useState(0);
  const [numberShortAnswer, setNumberShortAnswer] = useState(0);
  const [numberEssay, setNumberEssay] = useState(0);
  const [loading, setLoading] = useState(false); 
  const [notEnoughTokens, setNotEnoughTokens] = useState(false); 
  const [tokens, setTokens] = useState(0); 

  const [difficulty, setDifficulty] = useState("medium");
  const [submitted, setSubmitted] = useState(false);
  const [numberOfQuestionsError, setNumberOfQuestionsError] = useState(false);
  const [maxCharacters, setMaxCharacters] = useState(10_000);
  const [maxQuestions, setMaxQuestions] = useState(25);


  const navigate = useNavigate();

  const { data, error } = useAxiosGet("/api/subscription/");
  useEffect(() => {
    if (data) {
      console.log("subscription data", data);
      if ((data.tokens-25) >= 0) {
        setMaxQuestions(25);
      } else {
        setMaxQuestions(data.tokens);
      }

      const plan = data.plan;
      if (plan === "basic") {
        setMaxCharacters(10_000);
      }
      if (plan === "pro") {
        setMaxCharacters(25_000);
      }
      if (plan === "premium") {
        setMaxCharacters(50_000);
      }

    }
    if (error) {
      console.error(error);
    }
  }, [data, error]);


  const handleSubmit = () => {
    setSubmitted(true);

    if (numberOfQuestionsAvailable === maxQuestions) {
      setNumberOfQuestionsError(true);
    }
    if (title !== "" && summary !== "" && numberOfQuestionsAvailable != maxQuestions) {
      setLoading(true); 
      const data = {
        summary: summary,
        title: title,
        number_of_multiple_choice_questions: parseInt(numberOfMultipleChoice) || 0,
        number_of_true_or_false_questions: parseInt(numberOfTrueFalse) || 0,
        number_of_fill_in_the_blank_questions: parseInt(numberOfFillBlank) || 0,
        number_of_short_answer_questions: parseInt(numberShortAnswer) || 0,
        number_of_essay_questions: parseInt(numberEssay) || 0,
        difficulty: difficulty,
      };
      console.log(data)

        api.post("/api/summary_input/", data)
        .then((response) => {
          console.log("response from the backend")
          console.log(response.data);

          const enough_tokens = response.data.enough_tokens;
          console.log("this is the enough tokens")
          console.log(enough_tokens);
          setTokens(response.data.tokens);

          if (!enough_tokens) {
            setNotEnoughTokens(true);
          } else {
            navigate("/exam/questions/" + response.data.uuid + "/");
          }
        })
        .catch((error) => {
          console.error(error);

        })
        .finally(() => {
          setLoading(false);
        });
      }

    
  };

  console.log("NUMBER OF ", numberOfMultipleChoice)



  useEffect(() => {
    setNumberOfQuestionsAvailable(maxQuestions - (parseInt(numberOfMultipleChoice) + parseInt(numberOfTrueFalse) + parseInt(numberOfFillBlank) + parseInt(numberShortAnswer) + parseInt(numberEssay)));
  }, [numberOfMultipleChoice, numberOfTrueFalse, numberOfFillBlank, numberShortAnswer, numberEssay]);

  

  return (
    <div style={ { padding: "0 13vw"} }>

      <NotEnoughTokensModal notEnoughTokens={notEnoughTokens} setNotEnoughTokens={setNotEnoughTokens} tokens={tokens} />


      <TitleInput value={title} setValue={setTitle} title={"Title for your question set"} submitted={submitted} />

      <SummaryInputTextarea summary={summary} setSummary={setSummary} submitted={submitted} maxCharacters={maxCharacters} />
      <label className="block font-sans font-medium pt-3 pb-2">Number of questions</label>
      <div className="my-18">
          <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-3 gap-4 justify-center">

            <NumberOfSummaryQuestions title={"Multiple-choice"} value={numberOfMultipleChoice} setValue={setNumberOfMultipleChoice} maxNumber={numberOfQuestionsAvailable} error={numberOfQuestionsError} />
            
            <NumberOfSummaryQuestions title={"Boolean"} value={numberOfTrueFalse} setValue={setNumberOfTrueFalse} maxNumber={numberOfQuestionsAvailable} error={numberOfQuestionsError} />

            <NumberOfSummaryQuestions title={"Fill in the blank"} value={numberOfFillBlank} setValue={setNumberOfFillBlank} maxNumber={numberOfQuestionsAvailable} error={numberOfQuestionsError} />

            <NumberOfSummaryQuestions title={"Short-answer"} value={numberShortAnswer} setValue={setNumberShortAnswer} maxNumber={numberOfQuestionsAvailable} error={numberOfQuestionsError} />

            <NumberOfSummaryQuestions title={"Essay"} value={numberEssay} setValue={setNumberEssay} maxNumber={numberOfQuestionsAvailable} error={numberOfQuestionsError} />

            <div className="flex flex-col">
              <label className="my-3" >Difficulty Level</label>
              <select 
              className="border border-gray-300 rounded px-3 py-2.5"
              onChange={(e) => setDifficulty(e.target.value)}
              value={difficulty}
              >
                <option value="easy">Easy</option>
                <option value="medium">Medium</option>
                <option value="hard">Hard</option>
              </select>
            </div>
          </div>
        </div>
  
        <div className="my-12 flex justify-center">
          <button type="button" className="text-white bg-gradient-to-br from-purple-600 to-blue-500 hover:bg-gradient-to-bl focus:ring-4 focus:outline-none focus:ring-blue-300 dark:focus:ring-blue-800 font-medium rounded-lg text-sm px-5 py-2.5 text-center me-2 mb-2"
          onClick={handleSubmit}
          disabled={loading}
          > {loading ? "Loading..." : "Generate AI Exam"}</button>
        </div>
    </div>
  );
};

export default ExamInput;




// for (let i = 0; i <= maxNumber; i++) {
//   options.push(<option key={i} value={i}>{i}</option>);
// }


