import React, { useEffect, useRef } from "react";

const QuitModal = ({ isOpen, onQuit, onContinue}) => {
  const modalRef = useRef(null);

  const handleClickOutside = (event) => {
    if (modalRef.current && !modalRef.current.contains(event.target)) {
      onContinue()
    }
  };

  useEffect(() => {
    if (isOpen) {
      document.addEventListener("click", handleClickOutside);
    }

    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, [isOpen]);

  const handleKeyDown = (event) => {
    if (event.key === "Tab" && isOpen) {
      onContinue()
    }
  };

  return isOpen ? (
    <div
      className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50"
    >
      <div
        ref={modalRef}
        className="bg-white p-8 rounded-lg shadow-lg"
        onKeyDown={handleKeyDown}
        tabIndex={-1}
      >
        <p className="mb-4 text-lg font-medium">Do you really want to quit the exam?</p>
        <div className="flex flex-col">
          <button
            className="text-blue-700 font-semibold text-lg hover:bg-blue-100 px-4 py-2 rounded-lg transition-colors duration-200 ease-in-out hover:text-blue-800 focus:outline-none focus:ring-2 focus:ring-blue-400 focus:ring-opacity-50"
            onClick={onContinue}
          >
            Continue
          </button>
          <button
            className="text-red-600 mt-2 font-medium hover:bg-red-100 px-4 py-2 rounded-lg transition-colors duration-200 ease-in-out hover:text-red-800 focus:outline-none focus:ring-2 focus:ring-red-400 focus:ring-opacity-50"
            onClick={onQuit}
          >
            Quit
          </button>
        </div>
      </div>
    </div>
  ) : null;
};

export default QuitModal;
