const NumberOfSummaryQuestions = ({ title, maxNumber, value, setValue, error }) => {
  const options = [];
  const actualMaxNumber = value !== 0 ? Number(value) + maxNumber : maxNumber;
  for (let i = 0; i <= actualMaxNumber; i++) {
    options.push(<option key={i} value={i}>{i}</option>);
  }


  return (
    <div className="flex flex-col">
      <label className="my-3">
        {title}
      </label>
      <select
        className={`${error ? "border-2 border-rose-500": "border"} rounded px-3 py-2.5`}
        value={value}
        onChange={(e) => setValue(e.target.value)}
      >
        {options}
      </select>
    </div>
  );
};
export default NumberOfSummaryQuestions;

