// const PointsFooter = ({ maxPoints, currentPoints }) => {
//   return (
//     <div className="fixed inset-x-0 bottom-0 flex justify-center items-center p-6 w-full border-t-2 border-blue-500 rounded-t-xl bg-blue-300">
//       <div className="text-2xl font-medium text-blue-600">
//         Points: {currentPoints}/{maxPoints}
//       </div>
//     </div> 
//     )   
// }
// export default PointsFooter;





// Old code
const PointsFooter = ({ maxPoints, currentPoints }) => {
  return (
    <div className="flex justify-center my-16 px-8 md:px-10 lg:px-12 py-4 md:py-10 lg:py-14 border-2 border-black rounded-lg">
      <div className="text-lg font-medium">
        Points: {currentPoints}/{maxPoints}
      </div>
    </div> 
    )   
}
export default PointsFooter;