import useAxiosGet from "../hooks/useAxiosGet";
import { useParams, useNavigate } from "react-router-dom";
import { useState, useEffect } from "react";
import PointsBarComponent from "../components/QuestionRecap/PointsBarComponent";
import ShortAnswerRecap from "../components/QuestionRecap/ShortAnswerRecapComponent";
import api from "./../api";



const GrammarRecap = () => {
  const [questionList, setQuestionList] = useState([]);
  const [answerList, setAnswerList] = useState([]);
  const [correctIncorrectList, setCorrectIncorrectList] = useState([]);
  const [corrections, setCorrections] = useState([]);
  const [maxPoints, setMaxPoints] = useState(0);
  const [points, setPoints] = useState(0);
  const [pointsList, setPointsList] = useState([]);
  const [loading, setLoading] = useState(true);

  const navigate = useNavigate();
  const { uuid } = useParams();

  const { data, error } = useAxiosGet("/api/grammar_questions/" + uuid + "/");
  useEffect(() => {
    if (data) {
      console.log(data);
      setQuestionList(data.questions);
      setAnswerList(data.user_answers);
      setCorrections(data.corrections);
      setPointsList(data.points_list);
      setCorrectIncorrectList(data.correct_incorrect);
      setMaxPoints(data.questions.length);
      setPoints(data.points_list.reduce((a, b) => a + b, 0));
      setLoading(false);
    }
    if (error) {
      console.error(error);
    }
  }, [data, error]);



  const handleRestartButtonClicked = () => {
    api.put(`/api/delete_grammar_fields/${uuid}/`, {})
    .then(() => {
      console.log("Deleted fields");
      navigate("/grammar/questions/"+ uuid +"/");
    })
    .catch((error) => {
      console.log("Error while deleting fields:", error);
    });
  };

  return (
    <div className="min-h-screen">
      {!loading && (
        <div style={{ backgroundColor: "#F6F7FB" }} className="px-6">
          <PointsBarComponent points={points} maxPoints={maxPoints} />
          {questionList.map((question, index) => (
            <div className="flex justify-center items-center">
              <div className="max-w-[735px] w-full">
                <div className="bg-white rounded-lg shadow-md mb-4">
                  <div className="px-8 pb-12 pt-8">
                    <ShortAnswerRecap
                      question={question}
                      correctIncorrect={correctIncorrectList[index]}
                      answer={answerList[index]}
                      point={pointsList[index]}
                      correction={corrections[index]}
                    />
                  </div>
                </div>
              </div>
            </div>
            
          ))}
          <div className="flex justify-center mt-6">
            <button
              type="button"
              className="text-white bg-gradient-to-r from-cyan-400 via-cyan-500 to-cyan-600 hover:bg-gradient-to-br focus:ring-4 focus:outline-none focus:ring-cyan-300 dark:focus:ring-cyan-800 font-medium rounded-lg text-sm px-5 py-2.5 text-center me-2 mb-2"
              onClick={handleRestartButtonClicked}
              >
                Another grammar quiz?
            </button>
          </div>
        </div>
      )}
    </div>
  );
};
export default GrammarRecap;
