import { useState } from "react";
import { Navigate } from "react-router-dom";
import "reactjs-popup/dist/index.css";
import TitleInput from "../components/InputComponents/TitleInput";
import GrammarInputComponent from "../components/Grammar/GrammarInputComponent";
import NotEnoughTokensModal from "../components/OtherQuestionComponents/NotEnoughTokensModal";
import api from "./../api";


const GrammarInput = () => {
  const [language, setLanguage] = useState("");
  const [grammarTopic, setGrammarTopic] = useState("");
  const [exampleExercise, setExampleExercise] = useState("");
  const [numberOfExercises, setNumberOfExercises] = useState(1);
  const [title, setTitle] = useState("");
  const [redirectToQuestions, setRedirectToQuestions] = useState(false);
  const [notEnoughTokens, setNotEnoughTokens] = useState(false); // Add loading state
  const [tokens, setTokens] = useState(0); // Add loading state
  const [uuid, setUuid] = useState("");
  const [difficulty, setDifficulty] = useState("medium");
  const [submitted, setSubmitted] = useState(false);
  const [loading, setLoading] = useState(false);


  if (redirectToQuestions) {
    const url = `/grammar/questions/${uuid}/`;
    return <Navigate to={url} />;
  }

  const handleSave = () => {
    setSubmitted(true);
    if (
      language !== "" &&
      grammarTopic !== "" &&
      exampleExercise !== "" &&
      numberOfExercises !== ""
    ) {
      setLoading(true);
      const data = {
        language: language,
        topic: grammarTopic,
        example: exampleExercise,
        number_of_exercises: numberOfExercises,
        title: title,
        difficulty: difficulty,
      };
      console.log(
        "this is the data format I want to save in the database",
        data
      );
      console.log("SENDING A POST REQUEST TO THE BACKEND");
      api.post("/api/grammar_input/", data)
        .then((response) => {
          console.log(response.data); // Optional: log the response
          const enough_tokens = response.data.enough_tokens;
          console.log(enough_tokens);

          setTokens(response.data.tokens);
          if (enough_tokens==="false" || enough_tokens===false) {
            setNotEnoughTokens(true);
          } else {
            const uuid = response.data.uuid;
            setUuid(uuid);
            console.log("uuid", uuid);
            setRedirectToQuestions(true);
          }
        })
        .catch((error) => {
          console.error(error);
        });
    }
  };

  return (
    <div className="conatainer mx-auto" style={{ padding: "0 13vw" }}>
      <NotEnoughTokensModal showModal={notEnoughTokens} setShowModal={setNotEnoughTokens } tokensMissing={tokens} />

      <TitleInput
        value={title}
        setValue={setTitle}
        title={"Title for your question set:"}
        submitted={submitted}
      />

      <div className="grid grid-cols-1 sm:grid-cols-2 gap-4 mb-4">
        <GrammarInputComponent
          title={"Language:"}
          value={language}
          setValue={setLanguage}
          placeholder={"Language"}
          submitted={submitted}
        />
        <GrammarInputComponent
          title={"Grammar topic:"}
          value={grammarTopic}
          setValue={setGrammarTopic}
          placeholder={"Grammar topic"}
          submitted={submitted}
        />
      </div>

      <div className="my-6 flex flex-col justify-center">
        <GrammarInputComponent
          title={"Example Exercise:"}
          value={exampleExercise}
          setValue={setExampleExercise}
          placeholder={"Example Exercise"}
          submitted={submitted}
        />
      </div>

      <div className="grid grid-cols-1 sm:grid-cols-2 gap-4 mb-4">
        <div>
          <label className="mb-3 font-medium">Number of exercises:</label>
          <input
            type="number"
            className="w-full border border-gray-300 rounded-lg px-3 py-2"
            onChange={(event) => setNumberOfExercises(event.target.value)}
            value={numberOfExercises}
            max={25}
            min={1}
          />
          <p className="text-sm text-gray-500 pt-1">Max: 25</p>
        </div>
        <div>
          <label className="mb-3 font-medium">Difficulty:</label>
          <select
            className="w-full border border-gray-300 rounded-lg px-3 py-2.5"
            onChange={(event) => setDifficulty(event.target.value)}
            value={difficulty}
          >
            <option value="easy">Easy</option>
            <option value="medium">Medium</option>
            <option value="hard">Hard</option>
          </select>
        </div>
      </div>

      <button
        type="submit"
        className="text-white bg-gradient-to-br from-purple-600 to-blue-500 hover:bg-gradient-to-bl focus:ring-4 focus:outline-none focus:ring-blue-300 dark:focus:ring-blue-800 font-medium rounded-lg text-sm px-5 py-2.5 text-center me-2 mb-2"
        onClick={handleSave}
      >
        {loading ? "Loading..." : "Generate grammar exercises"}
      </button>
    </div>
  );
};

export default GrammarInput;
