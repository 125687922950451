import { useState, useEffect } from "react";



// I have just created a temporaly url for the home screen when teh user is logged in
// I would need to delete it latter and add it to the Homepage component

const HomeScreenLoggedIn = () => {
  const [userHasExams, setUserHasExams] = useState(true);
  const [userHasFlashcards, setUserHasFlashcards] = useState(true);
  const [userHasGrammarExams, setUserHasGrammarExams] = useState(true);

  //Need to change these states to false


  //Check if the user has any exams, flashcards or grammar exams


  return (
    <div style={{ backgroundColor: "#F6F7FB", minHeight: "100vh", paddingTop:"3em"}} className="min-h-screen">
      <div style={{ padding: "0 13vw" }}>
        <h1 className="flex flex-start text-3xl md:text-4xl font-semibold">
          Libary
        </h1>
        <h2 className="mt-10 flex flex-start text-2xl md:text-3xl font-semibold">
          Recently studied:
        </h2>
        <div>
          {userHasExams && (
            <div>
            <h3>Exams</h3>
            <div>
              {/* Add the content in cards here */}
            </div>
          </div>
          )}
          {userHasFlashcards && (
            <div>
              <h3>Flashcards</h3>
              <div>
                {/* Add the content in cards here */}
              </div>
            </div>
          )}
          {userHasGrammarExams && (
            <div>
              <h3>Grammar Exams</h3>
              <div>
                {/* Add the content in cards here */}
              </div>
            </div>
          )}



        </div>



      </div>
    </div>
  );
};
export default HomeScreenLoggedIn;