import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import api from "../api";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus } from "@fortawesome/free-solid-svg-icons";
import RecentQuizList from "../components/Home/RecentQuizList";
import CountDownTimer from "../components/Home/CountDownTimer";
import CircularProgress from "../components/Home/CircularProgress";


const HomePageAuth = () => {
  const [loading, setLoading] = useState(true);
  const [examData, setExamData] = useState([]);
  const [flashcardData, setFlashcardData] = useState([]);
  const [grammarData, setGrammarData] = useState([]);
  const [lastReset, setLastReset] = useState(null);
  const [maxTokens, setMaxTokens] = useState(null);
  const [currentTokens, setCurrentTokens] = useState(null);
  const [subscriptionPlan, setSubscriptionPlan] = useState(null);
  const [padding, setPadding] = useState("0px");

  const navigate = useNavigate(); 

  useEffect(() => {
    setLoading(true);
    const urls = [
      "/api/latest_uuid_titles_summary/10/",
      "/api/latest_grammar_uuid_titles/10/",
      "/api/flashcards/latest_flashcard_title_uuid/10/",
      "/api/flashcards/get_all_flashcard_drafts/",
      "/api/user-tokens/",
      "/api/payment/subscriptions/"
    ]

    Promise.all(urls.map(url => api.get(url)))
      .then(responses => {
        console.log("exam data: ", responses[0].data);
        setExamData(responses[0].data);
        setGrammarData(responses[1].data);
        const originalFlashcardData = responses[2].data;
        const flashcardDrafts = responses[3].data.map(draft => ({
          ...draft,
          type: "draft" 
        }));
  
        const combinedFlashcardData = [...flashcardDrafts, ...originalFlashcardData];
        setFlashcardData(combinedFlashcardData);

        setLastReset(responses[4].data.last_reset);
        setSubscriptionPlan(responses[5].data.subscription_plan);

        if (responses[5].data.subscription_plan === "max") {
          setMaxTokens(1);
          setCurrentTokens(1);
        } else {
          setMaxTokens(responses[4].data.max_tokens);
          setCurrentTokens(responses[4].data.tokens);
        }
        setLoading(false);
      })
      .catch(err => {
        console.log(err);
      });

  }, []);

  
  useEffect(() => {
    const handleResize = () => {
        const width = window.innerWidth; 
        const dynamicPadding = Math.max(0, 0.13 * width - 15) + 'px';
        setPadding(dynamicPadding);
    };

    handleResize();

    window.addEventListener('resize', handleResize);

    return () => window.removeEventListener('resize', handleResize);
}, []); 




  return !loading && (
    <div style={{  backgroundColor: "#F6F7FB" }} className="min-h-screen">
      <div className="pt-6 pb-12">
        {/* <h1 className="text-3xl font-medium" style={{padding: "0 13vw"}} >Welcome Back</h1> */}

        <div className="w-full flex justify-between items-center mt-8" style={{padding: "0 13vw"}}>
          <h1 className="text-2xl font-semibold mb-0 flex items-center">
            Recent Exams
          </h1>
          <button
            className="text-blue-600 hover:text-blue-700 flex items-center px-3 py-2 rounded-lg transition duration-300 ease-in-out hover:bg-blue-200"
            onClick={() => navigate("/exam/input/")}
          >
            <FontAwesomeIcon
              icon={faPlus}
              className="text-2xl mr-2"
              color="rgb(37 99 235)"
            />
            <span className="text-xl text-blue-600 font-medium">New Exam</span>
          </button>
        </div>
        
        <div style={{padding: `0 ${padding}`}}>
          <RecentQuizList questionType={"exam"} data={examData} />
        </div>

        {/* <h2 className="text-2xl font-medium mt-4" style={{padding: "0 13vw"}}>Recent Flashcard Sets</h2> */}


        <div className="w-full flex justify-between items-center mt-4" style={{padding: "0 13vw"}}>
          <h1 className="text-2xl font-semibold mb-0 flex items-center">
            Recent Flashcard Sets
          </h1>
          <button
            className="text-blue-600 hover:text-blue-700 flex items-center px-3 py-2 rounded-lg transition duration-300 ease-in-out hover:bg-blue-200"
            onClick={() => navigate("/flashcard/input/")}
          >
            <FontAwesomeIcon
              icon={faPlus}
              className="text-2xl mr-2"
              color="rgb(37 99 235)"
            />
            <span className="text-xl text-blue-600 font-medium">New Set</span>
          </button>
        </div>
        <div style={{padding: `0 ${padding}`}}>
          <RecentQuizList questionType={"flashcards"} data={flashcardData} setData={setFlashcardData} />
        </div>

        <div className="w-full flex justify-between items-center mt-4" style={{padding: "0 13vw"}}>
          <h1 className="text-2xl font-semibold mb-0 flex items-center">
            Recent Grammar Exercises
          </h1>
          <button
            className="text-blue-600 hover:text-blue-700 flex items-center px-3 py-2 rounded-lg transition duration-300 ease-in-out hover:bg-blue-200"
            onClick={() => navigate("/grammar/input/")}
          >
            <FontAwesomeIcon
              icon={faPlus}
              className="text-2xl mr-2"
              color="rgb(37 99 235)"
            />
            <span className="text-xl text-blue-600 font-medium">New Exercises</span>
          </button>
        </div>
        <div style={{padding: `0 ${padding}`}}>
          <RecentQuizList questionType={"grammar"} data={grammarData} />
        </div>

        <div style={{padding: "0 13vw"}}>
          <div className="mt-12 flex-row justify-start border-gray-300 bg-white p-4 md:p-6 lg:p-8 w-full rounded-xl shadow-lg">
            <h2 className="text-2xl font-medium">Token usage</h2>
            <div className="mt-4 mb-12">
              <CircularProgress maxTokens={maxTokens} currentTokens={currentTokens} subscriptionTier={subscriptionPlan} />
            </div>
            <CountDownTimer lastReset={lastReset} />
            <div className="flex items-center justify-center w-full mt-8">
              <button className="text-xl bg-[#f8db31] font-semibold w-1/3 py-2.5 rounded-xl text-white">Try 7 Days for Free</button>
            </div>
            
          </div>
        </div>

      </div>
    </div>
  )
};
export default HomePageAuth;