import { useState,useEffect } from 'react';
import AuthenicationModal from '../components/OtherQuestionComponents/AuthenticationModal';
import ExamPreview from "../Assets/exam_AI_preview.png";
import FlashcardPreview from "../Assets/flashcard_ai_preview.png";
import GrammarPreview from "../Assets/Grammar_ai_preview.png";
import api from "../api";
import { useAuth } from '../router/isAuthenticated';


const HomePage = () => {
  const [showAuthModal, setShowAuthModal] = useState(false);
  const { isAuthenticated } = useAuth();

  const navigateToApp = () => {
    const appScheme = 'studybuddyapp://login/';
    window.location = appScheme;
  }

  


  return (
    <div>
      <AuthenicationModal showModal={showAuthModal} setShowModal={setShowAuthModal} homeRedirect={false} />
      <div style={{padding:"0 13vw"}} className='mt-5'>
        <div className='flex flex-col justify-center items-center gap-3'>
          <h2 className='text-2xl font-medium mb-2 underline'>Study Buddy</h2>
          <p className="text-5xl font-semibold mb-8 text-center leading-[50px]">
            Study faster with the
            <br className=" inline"/> 
             power of AI
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 24 24"
              fill="currentColor"
              aria-hidden="true"
              className="inline-block w-12 h-12 text-purple-400 ml-1"
            >
            <path
              fillRule="evenodd"
              d="M9 4.5a.75.75 0 01.721.544l.813 2.846a3.75 3.75 0 002.576 2.576l2.846.813a.75.75 0 010 1.442l-2.846.813a3.75 3.75 0 00-2.576 2.576l-.813 2.846a.75.75 0 01-1.442 0l-.813-2.846a3.75 3.75 0 00-2.576-2.576l-2.846-.813a.75.75 0 010-1.442l2.846-.813A3.75 3.75 0 007.466 7.89l.813-2.846A.75.75 0 019 4.5zM18 1.5a.75.75 0 01.728.568l.258 1.036c.236.94.97 1.674 1.91 1.91l1.036.258a.75.75 0 010 1.456l-1.036.258c-.94.236-1.674.97-1.91 1.91l-.258 1.036a.75.75 0 01-1.456 0l-.258-1.036a2.625 2.625 0 00-1.91-1.91l-1.036-.258a.75.75 0 010-1.456l1.036-.258a2.625 2.625 0 001.91-1.91l.258-1.036A.75.75 0 0118 1.5zM16.5 15a.75.75 0 01.712.513l.394 1.183c.15.447.5.799.948.948l1.183.395a.75.75 0 010 1.422l-1.183.395c-.447.15-.799.5-.948.948l-.395 1.183a.75.75 0 01-1.422 0l-.395-1.183a1.5 1.5 0 00-.948-.948l-1.183-.395a.75.75 0 010-1.422l1.183-.395c.447-.15.799-.5.948-.948l.395-1.183A.75.75 0 0116.5 15z"
              clipRule="evenodd"
            ></path>
          </svg>
          </p>
          <button
            type="button"
            className="text-white bg-gradient-to-r from-purple-500 via-purple-600 to-purple-700 hover:bg-gradient-to-br focus:ring-4 focus:outline-none focus:ring-purple-300 dark:focus:ring-purple-800 shadow-xl shadow-purple-500/50 dark:shadow-xl dark:shadow-purple-800/80 font-bold rounded-lg px-5 py-2.5 text-center me-2 mb-28"
            onClick={() => setShowAuthModal(true)}
          >
            Get started for free
          </button>


        </div>
        
      </div>
      <a href='/home/'>Home</a>
      {/* <button onClick={navigateToApp}>navigate to app</button> */}



      <div className='mt-4'>
        <div className='flex flex-col items-center bg-blue-300 py-5'>
          <span className='text-5xl font-bold'>Exam AI</span>
        </div>
        <div className='py-10 bg-sky-200 px-8 md:px-14 lg:px-24'>
          <span className='font-semibold text-3xl block mb-8'>Features</span>
          <div className='grid md:grid-cols-2 gap-10'> 
            <div className='flex flex-col items-start gap-3'>
              <li className='text-2xl font-medium'>Exam from your summary</li>
              <li className='text-2xl font-medium'>Grading system</li>
              <li className='text-2xl font-medium'>AI feedback</li>
            </div>
            <div>
              <img src={ExamPreview} alt="Random Image" className="w-[650px] max-h-[355px] rounded-lg" />
            </div>
          </div>
          <div className='mt-12 flex flex-col items-center'>
            <button
              type="button"
              className="text-white bg-gradient-to-r from-cyan-400 via-cyan-500 to-cyan-600 hover:bg-gradient-to-br focus:ring-4 focus:outline-none focus:ring-cyan-300 dark:focus:ring-cyan-800 shadow-xl shadow-cyan-500/50 dark:shadow-xl dark:shadow-cyan-800/80 font-bold rounded-lg px-5 py-2.5 text-center me-2 mb-2"
            >
              Start exam for free
            </button>
          </div>
        </div>
      </div>


      <div className='flex flex-col items-center bg-navbar-green py-5'>
        <span className='text-5xl font-bold'>Flashcard AI</span>
      </div>
      <div className='py-10 bg-navbar-green-light px-8 md:px-14 lg:px-24'>
        <span className='font-semibold text-3xl block mb-8'>Features</span>
        <div className='grid md:grid-cols-2 gap-10'> 
          <div className='flex flex-col items-start gap-3'>
            <li className='text-2xl font-medium'>No need for definitions anymore.</li>
            <li className='text-2xl font-medium'>Your answer are AI corrected</li>
            <li className='text-2xl font-medium'>AI feedback to learn faster</li>
          </div>
          <div>
            <img src={FlashcardPreview} alt="Random Image" className="w-[650px] max-h-[355px] rounded-lg" />
          </div>
        </div>
        <div className='mt-12 flex flex-col items-center'>
          <button 
            type="button" 
            className="text-white bg-gradient-to-r from-green-400 via-green-500 to-green-600 hover:bg-gradient-to-br focus:ring-4 focus:outline-none focus:ring-green-300 dark:focus:ring-green-800 shadow-xl shadow-green-500/50 dark:shadow-xl dark:shadow-green-800/80 font-bold rounded-lg px-5 py-2.5 text-center me-2 mb-2"
          >
            Start studying for free
          </button>

        </div>
      </div>

      <div className='flex flex-col items-center bg-grammar-violet py-5'>
        <span className='text-5xl font-bold'>Grammar AI</span>
      </div>
      <div className='py-10 bg-grammar-violet-light px-8 md:px-14 lg:px-24'>
        <span className='font-semibold text-3xl block mb-8'>Features</span>
        <div className='grid md:grid-cols-2 gap-10'> 
          <div className='flex flex-col items-start gap-3'>
            <li className='text-2xl font-medium'>Exam from one example exercise</li>
            <li className='text-2xl font-medium'>Personalized feedback</li>
            <li className='text-2xl font-medium'>Realistic grading</li>
          </div>
          <div>
            <img src={GrammarPreview} alt="Random Image" className="w-[650px] max-h-[355px] rounded-lg" />
          </div>
        </div>
        <div className='mt-12 flex flex-col items-center'>
        <button 
          type="button" 
          className="text-white bg-gradient-to-r from-blue-500 via-blue-600 to-blue-700 hover:bg-gradient-to-br focus:ring-4 focus:outline-none focus:ring-blue-300 dark:focus:ring-blue-800 shadow-xl shadow-blue-500/50 dark:shadow-xl dark:shadow-blue-800/80 font-bold text-md px-5 py-2.5 text-center me-2 mb-2 rounded-lg"
        >
          Start exam for free
        </button>

        </div>
      </div>

    </div>
  );
}

export default HomePage;
