const AnswerInputFieldIncorrectCorrection = ({ userAnswer, correction, correctionHeader }) => (
  <div className="flex flex-col items-center">
    <p className="font-medium text-2xl mb-5">Your answer</p>
    <input 
      readOnly
      value={userAnswer}
      className="mb-10 p-4 w-full border border-red-400 bg-red-50 rounded-md shadow-xl shadow-red-500/40"
    />
    <p className="font-medium text-2xl mb-5">{correctionHeader}</p>
    <input 
      readOnly
      value={correction}
      className="p-4 w-full border border-green-400 bg-green-50 rounded-md shadow-xl shadow-green-500/40"
    />
  </div>
);
export default AnswerInputFieldIncorrectCorrection;