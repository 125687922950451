const PointsBarComponent = ({ points, maxPoints }) => {
  return (
    <div>
      <div className="flex items-center justify-center py-6">
        <div className="w-full bg-correction-red  h-7 rounded-full overflow-hidden max-w-[735px]">
          <div
            style={{ width: `${(points / maxPoints) * 100}%` }}
            className="bg-navbar-green h-full rounded-tl-lg rounded-bl-lg"
          ></div>
        </div>
      </div>
      <div className="text-center mb-16 text-lg font-medium text-gray-500">
        Total Points: {points}/{maxPoints} - {Math.round((points / maxPoints) * 100)}%
      </div>
    </div>
  );
};
export default PointsBarComponent;
