import { useState, useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'; 
import { faTimes, faTriangleExclamation, faCheckCircle } from '@fortawesome/free-solid-svg-icons';


const LimitModal = ({ showModal, setShowModal, limitReason }) => {
  const [header, setHeader] = useState("");
  const [subheader, setSubheader] = useState("");

  const features = [
    "Unlimited Tokens",
    "Unlimited Image Scans",
    "Unlimited Flashcards per Set",
    "50'000 Character Text Upload Limit",
  ];


  useEffect(() => {
    if (limitReason === "tokens") {
      setHeader("You've Hit Your Monthly Token Limit!");
      setSubheader("You've used up all your tokens for this month. Upgrade your account now to continue.");
    } else if (limitReason === "flashcards") {
      setHeader("You've Reached Your Flashcard Limit!");
      setSubheader("Upgrade your plan to add unlimited flashcards and enhance your learning experience.");
    } else if (limitReason === "text") {
      setHeader("You've Reached Your Text Character Limit!");
      setSubheader("Upgrade your account to expand your character limit and continue adding more content.");
    }    
  }, [limitReason]);







  return showModal && (
    <div className='fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50'>
      <div className="bg-white rounded-xl shadow-lg w-full relative max-w-2xl p-10">
        <div className='w-full flex flex-col items-center justify-center'>
          <FontAwesomeIcon icon={faTriangleExclamation} className='text-6xl text-red-500' />
          <h1 className='mt-3 font-semibold text-3xl'>{header}</h1>
          <p className='text-lg mt-4 text-center'>{subheader}</p>
          <h2 className='mt-4 text-xl text-start w-full font-semibold'>What you get</h2>
        </div>

        <ul className='list-none pl-4'>
          {features.map((feature, index) => (
            <li key={index} className='flex items-center mt-2.5'>
              <FontAwesomeIcon icon={faCheckCircle} className='text-[#FFD700] text-xl mr-2.5' />
              <span className='text-lg font-medium'>{feature}</span>
            </li>
          ))}
        </ul>
        <button className='bg-[#f8db31] hover:bg-[#f0cc2a] px-2.5 rounded-full items-center mt-8 text-white font-bold w-full py-2.5 transition duration-300 ease-in-out transform hover:scale-105'>
          Try 7 Days Free
        </button>

        <button
            onClick={() => setShowModal(false)}
            className="absolute top-5 right-5 text-2xl hover:text-gray-400 text-gray-700">
            <FontAwesomeIcon icon={faTimes} />
        </button>
      </div>
    </div>
  );
};
export default LimitModal;