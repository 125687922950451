import { useParams, useNavigate } from "react-router-dom";
import { useState, useEffect } from "react";
import useAxiosGet from "../hooks/useAxiosGet";
import PointsBarComponent from "../components/QuestionRecap/PointsBarComponent";
import OptionsCorrectionComponent from "../components/SummaryQuestionComponents/OptionsCorrectionComponent";
import ShortAnswerRecap from "../components/QuestionRecap/ShortAnswerRecapComponent";
import api from "../api";


const ExamRecap = () => {
  const [userAnswers, setUserAnswers] = useState([]);
  const [questions, setQuestions] = useState([]);
  const [corrections, setCorrections] = useState([]);
  const [correctIncorrect, setCorrectIncorrect] = useState([]);
  const [pointsList, setPointsList] = useState([]);
  const [maxPoints, setMaxPoints] = useState(0);
  const [points, setPoints] = useState(0);

  const { uuid } = useParams();
  const navigate = useNavigate();

  const { data, loading, error } = useAxiosGet("/api/summary/" + uuid + "/");

  const getMaxPointsForQuestionType = (type) => {
    if (type === "multipleChoice") {
      return 1;
    } else if (type === "trueOrFalse") {
      return 1;
    } else if (type === "fillInTheBlank") {
      return 2;
    } else if (type === "shortAnswer") {
      return 5;
    } else if (type === "essay") {
      return 10;
    }
  };



  useEffect(() => {
    if (data) {
      setUserAnswers(eval(data.user_answers));
      setCorrections(eval(data.corrections));
      setCorrectIncorrect(eval(data.correct_incorrect));
      let totalPointsCalc = 0;
      eval(data.questions).forEach((question) => {
        totalPointsCalc += getMaxPointsForQuestionType(question.type);
      });
      setMaxPoints(totalPointsCalc);
      
      setPointsList(eval(data.points_list));
      setPoints(eval(data.points_list).reduce((a, b) => a + b, 0));
      setQuestions(eval(data.questions));
      console.log("these are the quesitons", questions);
      console.log(data);
    }
    if (loading) {
      console.log("Loading");
    }
    if (error) {
      console.log(error);
    }
  }, [data, loading, error]);

  console.log("this is the max points", maxPoints)
  console.log("this is the student points", points)
  console.log("this is the pointsList", pointsList)


  const getChoiceIndex = (choices, userAnswer) => {
    console.log("this is the user answer", userAnswer);
    console.log("choices", choices.indexOf(userAnswer));
    return choices.indexOf(userAnswer);
  };


  const handleRepeatButtonClicked = () => {
    api.put(`/api/delete_summary_fields/${uuid}/`, {})
    .then(() => {
      console.log("Deleted fields");
      navigate("/exam/questions/"+uuid+"/");
    })
    .catch((error) => {
      console.log("Error while deleting fields:", error);
    });
  };



  return (
    <div style={{ backgroundColor: "#F6F7FB" }} className="px-6 min-h-screen">
      {questions.length != 0 && (
        <div>
          <PointsBarComponent points={points} maxPoints={maxPoints} />
          {questions.map((question, index) => (
            <div key={index}>
              <div className="flex justify-center items-center">
                <div className="max-w-[735px] w-full">
                  <div className="bg-white rounded-lg shadow-md mb-4">
                    <div className="px-8 pb-12 pt-8">
                      <div className="text-xl mb-8">{question.question}</div>
                      {question.type === "multipleChoice" && (
                        <div>
                          {correctIncorrect[index] ? (
                            <div>
                              <OptionsCorrectionComponent
                                optionsList={question.choices}
                                correctIndex={getChoiceIndex(
                                  question.choices,
                                  userAnswers[index]
                                )}
                              />
                            </div>
                          ) : (
                            <div>
                              <OptionsCorrectionComponent
                                optionsList={question.choices}
                                correctIndex={corrections[index]}
                                incorrectIndex={getChoiceIndex(
                                  question.choices,
                                  userAnswers[index]
                                )}
                              />
                            </div>
                          )}
                        </div>
                      )}
                      {question.type === "trueOrFalse" && (
                        <div>
                          {correctIncorrect[index] ? (
                            <div>
                              <OptionsCorrectionComponent
                                optionsList={["True", "False"]}
                                correctIndex={getChoiceIndex(
                                  ["True", "False"],
                                  userAnswers[index]
                                )}
                              />
                            </div>
                          ) : (
                            <div>
                              <OptionsCorrectionComponent
                                optionsList={["True", "False"]}
                                correctIndex={corrections[index]}
                                incorrectIndex={getChoiceIndex(
                                  ["True", "False"],
                                  userAnswers[index]
                                )}
                              />
                            </div>
                          )}
                        </div>
                      )}
                      {question.type === "fillInTheBlank" && (
                        <ShortAnswerRecap
                          correctIncorrect={correctIncorrect[index]}
                          answer={userAnswers[index]}
                          point={pointsList[index]}
                          correction={corrections[index]}
                        />
                      )}

                      {question.type === "shortAnswer" && (
                        <ShortAnswerRecap
                          correctIncorrect={correctIncorrect[index]}
                          answer={userAnswers[index]}
                          point={pointsList[index]}
                          correction={corrections[index]}
                        />
                      )}
                      {question.type === "essay" && (
                        <ShortAnswerRecap
                          correctIncorrect={correctIncorrect[index]}
                          answer={userAnswers[index]}
                          point={pointsList[index]}
                          correction={corrections[index]}
                        />
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          ))}
          <div className="flex justify-center mt-6">
            <button
              type="button"
              className="text-white bg-gradient-to-r from-cyan-400 via-cyan-500 to-cyan-600 hover:bg-gradient-to-br focus:ring-4 focus:outline-none focus:ring-cyan-300 dark:focus:ring-cyan-800 font-medium rounded-lg text-sm px-5 py-2.5 text-center me-2 mb-2"
              onClick={handleRepeatButtonClicked}
              >
              Repeat exam
            </button>
          </div>

        </div>
      )}
    </div>
  );
};
export default ExamRecap;