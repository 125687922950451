import axios from 'axios';
import Cookies from 'js-cookie';

const axiosInstance = axios.create({
  headers: {
    'Content-Type': 'application/json',
  },
});

export default axiosInstance;

axiosInstance.interceptors.request.use(
  config => {
    const token = Cookies.get('accessToken');
    if (token) {
      config.headers['Authorization'] = `Bearer ${token}`;
    }
    return config;
  },
  error => Promise.reject(error)
);




axiosInstance.interceptors.response.use(
  response => response,
  async error => {
    const originalRequest = error.config;

    if (error.response && error.response.status === 401 && !originalRequest._retry) {
      originalRequest._retry = true;

      const refreshToken = Cookies.get('refreshToken');
      if (refreshToken) {
        try {

          const tokenResponse = await axios.post('/token/refresh/', { refresh: refreshToken });
          const newAccessToken = tokenResponse.data.access;


          Cookies.set('accessToken', newAccessToken);
          Cookies.set('refreshToken', tokenResponse.data.refresh); 
          originalRequest.headers['Authorization'] = `Bearer ${newAccessToken}`;
          return axiosInstance(originalRequest);
        } catch (refreshError) {
          console.error('Refresh token is invalid or the request for a new token failed', refreshError);
          Cookies.remove('accessToken');
          Cookies.remove('refreshToken');
          window.location.href = '/login/';
          return Promise.reject(refreshError);
        }
      } else {
        console.error('No refresh token available');
        Cookies.remove('accessToken');
        Cookies.remove('refreshToken');
        window.location.href = '/login/';
        return Promise.reject(error);
      }
    }


    if (error.response) {
      console.error('API Error:', error.response.status, error.response.data);
    } else {
      console.error('Network or CORS error', error);
    }

    return Promise.reject(error);
  }
);

























// axiosInstance.interceptors.response.use(
//   response => response,
//   async error => {
//     const originalRequest = error.config;
//     console.error("ERORR STATUS: ", error.response.status);
//     console.log("first: ", error.response && error.response.status === 401)
//     console.log("second: ", !originalRequest._retry)
//     if (error.response && error.response.status === 401 && !originalRequest._retry) {
//       originalRequest._retry = true;
//       const refreshToken = Cookies.get('refreshToken');
//       if (refreshToken) {
//         try {

//           const tokenResponse = await axios.post('/token/refresh/', { refresh: refreshToken });
//           const newAccessToken = tokenResponse.data.access;  
//           Cookies.set('accessToken', newAccessToken); 
//           Cookies.set('refreshToken', tokenResponse.data.refresh);
//           originalRequest.headers['Authorization'] = `Bearer ${newAccessToken}`;
//           return axiosInstance(originalRequest);
//         } catch (refreshError) {
//           Cookies.remove('accessToken');
//           Cookies.remove('refreshToken');
//           window.location.href = '/login/';
//           console.error('Refresh token is invalid or the request for a new token failed', refreshError);

//           return Promise.reject(refreshError); 
//         }
//       }
//     } else {
//       console.error('No refresh token available', error);
//       window.location.href = '/login/';
//     }
//     return Promise.reject(error);
//   }
// );