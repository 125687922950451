import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/free-solid-svg-icons";


const NotEnoughTokensModal = ({ showModal, setShowModal, tokensMissing}) => {

  return (
    <div>
      {showModal && (
        <div className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50">
          <div className="bg-white p-6 rounded-lg w-[480px] mx-4 sm:mx-auto relative">
            <div className="absolute top-5 right-5">
              <FontAwesomeIcon
                icon={faTimes}
                className="text-gray-600 cursor-pointer"
                onClick={() => setShowModal(false)}
                fontSize={25}
              />
            </div>

            <div className="bg-white px-8 pt-10 flex flex-col self-center text-center">
              <div className="w-full flex justify-center">
                <img
                  src="https://tailwindui.com/img/logos/workflow-mark-indigo-500.svg"
                  className="h-10 w-10 mb-5 mt-2"
                />
              </div>
              <h1 className="text-2xl font-semibold mb-3 text-correction-red">
                Not enough tokens
              </h1>
              <h2 className="text-rg mb-20">
                You need at least {tokensMissing} more tokens to access this feature. 
              </h2>
              <div className="flex flex-col gap-3 w-full">
                <a
                  className="font-semibold h-[50px] text-lg flex justify-center items-center text-center rounded-xl border-2 border-green-400 hover:bg-green-100 transition"
                  href="/pricing/"
                >
                  Get tokens
                </a>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}
export default NotEnoughTokensModal