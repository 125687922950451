// if correct and wrong: set incorretIndex and correctIndex
// if only correct: set correctIndex and incorrectIndex to null
const OptionsCorrectionComponent = ({ optionsList, incorrectIndex, correctIndex } ) => {
  return (
    <div>
      {optionsList.map((option, index) => (
        <div key={index} className="flex flex-col items-center">
          <label
            htmlFor={`option${index}`}
            className={`cursor-pointer select-none font-medium py-3 px-6 rounded-xl transition-colors duration-300 border-2 mt-2 w-full ${index === incorrectIndex ? 'bg-correction-red border-red-500' : (index === correctIndex ? 'bg-green-300 border-green-500' : "text-gray-400")}`}
            style={{ maxWidth: "325px" }}
          >
            {option}
          </label>
        </div>
      ))}
    </div>
  );
};
export default OptionsCorrectionComponent;