import AppleSignin from 'react-apple-signin-auth';
import axios from 'axios';

const AppleAuthButton = () => {
	const handleSuccess = (data) => {
    const { authorization, user } = data
    console.log('Authorization', authorization) 
    const code = authorization.code
    const id_token = authorization.id_token
    console.log("code", code)
    console.log("id_token", id_token)
    axios.post("api/auth/apple/login/", { code: code })
      .then((response) => {
        console.log(response.data);
      })
      .catch((error) => {
        console.error(error);
      });
  }


  return (
    <>
      <AppleSignin
        authOptions={{
          clientId: String(process.env.REACT_APP_APPLE_CLIENT_ID),
          redirectURI: "https://studybuddyai.me/api/apple/callback/",
          nonce: 'nonce',
          scope: 'email name',
          usePopup: false,
        }}  
        uiType='dark'
        noDefaultStyle={false}
        skipScript={false}
        className="apple-auth-btn"
        onSuccess={handleSuccess}
        onError={(error) => console.error(error)}
        buttonExtraChildren="Continue with Apple"
        iconProp={{ style: { marginTop: '100px' } }} 
      />


    </>
  );
};
export default AppleAuthButton;