import { useState, useEffect } from "react"
const TitleInput = ({ value, setValue, title, submitted }) => {
  const [error, setError] = useState(false);
  useEffect(() => {
    if (submitted && value.trim() === "") {
      setError(true);
    }
    else {
      setError(false);
    }
  }, [submitted, value]);

  return (
    <div className="my-6">
      <label htmlFor="title" className="block font-sans font-medium">{title}</label>
      <input
        id="title" 
        type="text"
         className={`mb-1.5 mt-3 p-2.5 lg:w-1/3 md:w-1/3 sm:w-1/2  ${error ? "border-2 border-red-500" : "border-1 border-grey-300"} rounded-md focus:outline-none focus:border-blue-500`} placeholder="Enter title" 
        onChange={(event) => setValue(event.target.value)}
        value={value}
        />
        {error && <p className="text-red-500 text-sm ">Field is required</p>}
  </div>
  )

}
export default TitleInput