import React, { useState } from "react";
import axios from "axios";
import { useNavigate, useParams, Link } from "react-router-dom";

const PasswordResetConfirm = () => {
  const [password1, setPassword1] = useState("");
  const [password2, setPassword2] = useState("");
  const [password1Error, setPassword1Error] = useState(false);
  const [password1ErrorMessage, setPassword1ErrorMessage] = useState("");
  const [password2Error, setPassword2Error] = useState(false);
  const [password2ErrorMessage, setPassword2ErrorMessage] = useState("");
  const [nonFieldError, setNonFieldError] = useState("");
  const navigate = useNavigate();
  const { uidb64, token } = useParams();

  const handleSubmitButtonClicked = (event) => {
    event.preventDefault();
    const data = {
      uid: uidb64,
      token: token,
      new_password1: password1,
      new_password2: password2,
    };

    axios
      .post("/api/auth/password/reset/confirm/", data)
      .then((response) => {
        console.log(response.data); // Optional: log the response
        navigate("/login")
      })
      .catch((error) => {
        console.error(error);
        if (error.response.data.new_password1) {
          setPassword1Error(true);
          setPassword1ErrorMessage(error.response.data.new_password1);
        }
        if (error.response.data.new_password2) {
          setPassword2Error(true);
          setPassword2ErrorMessage(error.response.data.new_password2);
        }
        if (error.response.data.non_field_errors) {
          setPassword1Error(true);
          setPassword2Error(true);
          setNonFieldError(error.response.data.non_field_errors);
        }
      });
  };

  return (
    <section className="bg-gray-50 dark:bg-gray-900">
      <div className="flex flex-col items-center justify-center px-6 py-8 mx-auto md:h-screen lg:py-0">
        <Link to="/" className="absolute left-6 top-6 dark:text-white">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            className="h-6 w-6 text-gray-900 dark:text-white" // Adjusted color here
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth={2}
              d="M15 19l-7-7 7-7"
            />
          </svg>
        </Link>
        <p className="flex items-center mb-6 text-2xl font-semibold text-gray-900 dark:text-white">
          <img
            className="w-8 h-8 mr-2"
            src="https://tailwindui.com/img/logos/workflow-mark-indigo-500.svg"
            alt="logo"
          />
          Study buddy
        </p>
        <div className="w-full bg-white rounded-lg shadow dark:border md:mt-0 sm:max-w-md xl:p-0 dark:bg-gray-800 dark:border-gray-700">
          <div className="p-6 space-y-4 md:space-y-6 sm:p-8">
            <h1 className="text-xl font-bold leading-tight tracking-tight text-gray-900 md:text-2xl dark:text-black">
              Reset your password
            </h1>
            <form className="space-y-4 md:space-y-6" onSubmit={(event) => handleSubmitButtonClicked(event)}>
              <div>
                <label
                  htmlFor="password1"
                  className="block mb-2 text-sm font-medium text-gray-900 dark:text-black"
                >
                  New password
                </label>
                <input
                  type="password"
                  name="password1"
                  value={password1}
                  onChange={(e) => setPassword1(e.target.value)}
                  autoComplete="new-password"
                  id="password1"
                  className={`bg-gray-50 ${password1Error ? 'border-2 border-red-500' : 'border border-gray-300'} text-gray-900 sm:text-sm rounded-lg focus:ring-blue-600 focus:border-blue-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-black dark:focus:ring-blue-500 dark:focus:border-blue-500`}
                  placeholder="••••••••"
                  required
                />
                {password1ErrorMessage && <span className="text-red-500 text-sm">{password1ErrorMessage}</span>}
              </div>
              <div>
                <label
                  htmlFor="password2"
                  className="block mb-2 text-sm font-medium text-gray-900 dark:text-black"
                >
                  Confirm new Password
                </label>
                <input
                  type="password"
                  name="password2"
                  value={password2}
                  onChange={(e) => setPassword2(e.target.value)}
                  autoComplete="new-password"
                  id="password2"
                  placeholder="••••••••"
                  className={`bg-gray-50 ${password2Error ? 'border-2 border-red-500' : 'border border-gray-300'} text-gray-900 sm:text-sm rounded-lg focus:ring-blue-600 focus:border-blue-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500`}
                  required
                />
                {password2ErrorMessage && <span className="text-red-500 text-sm">{password2ErrorMessage}</span>}
              </div>
              {nonFieldError && <div className="text-red-500 text-sm font-bold mt-4">{nonFieldError}</div>}
              <button
                type="submit"
                className="w-full text-white bg-blue-600 hover:bg-blue-700 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
              >
                Reset Password
              </button>
              
            </form>
          </div>
        </div>
      </div>
    </section>
  );
};

export default PasswordResetConfirm;
