import { createContext, useContext, useState, useEffect } from 'react';
import axios from 'axios';
import Cookies from 'js-cookie';
import LoadingSpinner from '../components/General/LoadingSpinner';


const AuthContext = createContext({});

export const AuthProvider = ({ children }) => {
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [loading, setLoading] = useState(true); 

  const verifyToken = () => {
    const token = Cookies.get("accessToken");
    if (!token && !Cookies.get("refreshToken")) {
      setLoading(false);
      return;
    } else if (!token) {
      setIsAuthenticated(false);
      setLoading(false);
      return;
    }

    axios.post("/api/auth/token/verify/", { token })
      .then(response => {
        if (response.status === 200) {
          console.log("Token is valid");
          setIsAuthenticated(true);
        } else {
          console.error("Access Token is invalid");
          refreshAccessToken();
        }
      })
      .catch(error => {
        console.error("Token verification failed", error);
        setIsAuthenticated(false);
      })
      .finally(() => setLoading(false));  
  };

  const refreshAccessToken = () => {
    axios.post("/token/refresh/", { refresh: Cookies.get("refreshToken") })
      .then(response => {
        const newAccessToken = response.data.access;
        Cookies.set("accessToken", newAccessToken);
        Cookies.set("refreshToken", response.data.refresh);
        setIsAuthenticated(true);
      })
      .catch(error => {
        console.error("Refresh Token is invalid", error);
        Cookies.remove('accessToken');
        Cookies.remove('refreshToken');
        window.location.href = '/login/';
        setIsAuthenticated(false);
      })
      .finally(() => setLoading(false)); 
  };

  useEffect(() => {
    verifyToken();
  }, []);

  return (
    <AuthContext.Provider value={{ isAuthenticated, loading }}>
      {loading ? <LoadingSpinner /> : children} 
    </AuthContext.Provider>
  );
};

export const useAuth = () => useContext(AuthContext);
