import React, { useState } from "react";
import { loadStripe } from "@stripe/stripe-js";
import api from "../api";

const PaymentStripe = () => {
  const [sessionId, setSessionId] = useState(null);



  const handleSubmit = async (priceId) => {
    try {
      const response = await api.post(
        "/api/payment/stripesubscription/",
        { price_id: priceId });

      // Check if the response contains a session_id
      if (response.data.session_id) {
        // Store the session_id in state
        setSessionId(response.data.session_id);

        // Redirect to Stripe Checkout using the session_id
        const stripe = await loadStripe(process.env.REACT_APP_STRIPE_SESSION_KEY); // Replace with your Stripe publishable key
        const { error } = await stripe.redirectToCheckout({
          sessionId: response.data.session_id,
        });

        if (error) {
          console.error("Error redirecting to Stripe Checkout:", error);
        }
      } else {
        console.error("Session ID not found in response");
      }
    } catch (error) {
      // Handle errors
      console.error("Error subscribing:", error);
    }
  };
  return (
    <div className="bg-red-500">
      <h1>PaymentStripe</h1>
      <div>
        <h2>Premium Plan</h2>
        <h3>Subscribe to our monthly plan</h3>
        <h3>Get unlimited access to all our features</h3>
        <h3>Cancel anytime</h3>
        <h3>Only 8.99$ / month</h3>
        <button
          className="btn-month"
          onClick={() => handleSubmit(process.env.REACT_APP_PRICE_ID_1)}
        >
          8.99$ / month
        </button>
      </div>
      <br></br>
      <div>
        <h2>Pro Plan</h2>
        <h3>Get limited access</h3>
        <h3>Only 4.99$ / month</h3>
        <button
          className="btn-month"
          onClick={() => handleSubmit(process.env.REACT_APP_PRICE_ID_2)}
        >
          4.99$ / month
        </button>
      </div>
    </div>
  );
};
export default PaymentStripe;

