import React from 'react';
import AppRouter from './router/Router';
import './styles/styles.css';
import { AuthProvider } from './router/isAuthenticated';
import NotificationProvider from './providers/NotificationProvider';

const App = () => {
  return (
    <AuthProvider>
      <NotificationProvider>
        <AppRouter />
      </NotificationProvider>
    </AuthProvider>
  );
}

export default App;
