import { useState, useEffect } from "react";
const OptionsComponent = ({ optionsList, setUserAnswer, userAnswer, submitted }) => {
  const [error, setError] = useState(false);
  
  const handleOptionClick = (option) => {
    setUserAnswer(option);
  };

  useEffect(() => {
    if (submitted && userAnswer.trim() === "") {
      setError(true);
    }
    else {
      setError(false);
    }
  }, [submitted, userAnswer]);
  console.log("this is the error", error);
  return (
    <div>
      {optionsList.map((option, index) => (
        <div key={index} className="flex flex-col items-center">
          <input type="radio" id={`option${index}`} name="options" className="hidden" value={userAnswer}/>
          <label
            htmlFor={`option${index}`}
            className={`cursor-pointer select-none hover:border-gray-500 font-medium py-3 px-6 rounded-xl transition-colors duration-300 ${error ? "border-2 border-red-500" : "border-2"} mt-2 w-full`}
            style={{ maxWidth: "325px"}}
            onClick={() => handleOptionClick(option)}
          >
            {option}
          </label>
        </div>
      ))}
      <div className="flex flex-col items-center mt-4">
        {error && <p className="text-red-500 text-sm">You need to choose one of the options</p>}
      </div>
    </div>
  );
};

export default OptionsComponent;
