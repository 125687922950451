// //Widths:
// // For small use: maxWidth="375px or 325px"
// // For medium use: maxWidth="525px or 475px"

import { useEffect, useState } from "react";

const InputFieldComponent = ({
  value,
  setValue,
  maxWidth,
  placeholder,
  submitted,
}) => {
  const [error, setError] = useState(false);
  useEffect(() => {
    if (submitted && value.trim() === "") {
      setError(true);
    }
    else {
      setError(false);
    }
  }, [submitted, value]);

  return (
    <div className="flex flex-col items-center">
      <div className={`mx-auto flex w-full max-w-[${maxWidth}] flex-col items-center`}>
        <div className="flex w-full flex-col items-start">
          <input
            id="title"
            type="text"
            className={`m-1.5 p-2.5 w-full rounded-md  ${
              error ? "border-2 border-red-500" : "border-1 border-gray-300"
            } p-2.5 focus:outline-none`}
            placeholder={placeholder}
            onChange={(event) => setValue(event.target.value)}
            value={value}

          />
          {error && <p className="text-red-500 text-sm">Field is required</p>}
        </div>
      </div>
    </div>
  );
};
export default InputFieldComponent;
