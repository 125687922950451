import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useParams } from "react-router-dom";
import ProgressBarComponent from "../components/OtherQuestionComponents/ProgressBarComponent";
import QuestionBox from "../components/OtherQuestionComponents/QuestionBox";
import useAxiosGet from "../hooks/useAxiosGet";
import OptionsComponent from "../components/OtherQuestionComponents/OptionsComponent";
import AnswerSubmitButton from "../components/OtherQuestionComponents/AnswerSubmitButton";
import InputFieldComponent from "../components/OtherQuestionComponents/InputFieldComponent";
import OptionsCorrectionComponent from "../components/SummaryQuestionComponents/OptionsCorrectionComponent";
import NextQuestionButton from "../components/OtherQuestionComponents/NextQuestionButton";
import WrongFooter from "../components/OtherQuestionComponents/WrongFooter";
import CorrectFooter from "../components/OtherQuestionComponents/CorrectFooter";
import PointsFooter from "../components/OtherQuestionComponents/PointsFooter";
import Modal from "../components/OtherQuestionComponents/ReloadModal";
import api from "../api";

function ExamQuestions() {
  const [questions, setQuestions] = useState([]);
  const [numberOfQuestions, setNumberOfQuestions] = useState(0);
  const [questionIndex, setQuestionIndex] = useState(0);

  const [userAnswer, setUserAnswer] = useState("");
  const [correction, setCorrection] = useState("");
  const [correctIncorrect, setCorrectIncorrect] = useState(null);

  const [pointsForQuestion, setPointsForQuestion] = useState(0);
  const [points, setPoints] = useState(0);
  const [maxPoints, setMaxPoints] = useState(0);
  const [maxPointsUntilNow, setMaxPointsUntilNow] = useState(0);
  const [optionsUserAnswerIndex, setOptionsUserAnswerIndex] = useState(0);


  const [showResponse, setShowResponse] = useState(false);
  const [loadingSite, setLoadingSite] = useState(true);
  const [essayLength, setEssayLength] = useState(0);
  const [showModal, setShowModal] = useState(false);
  const [previousQuestionIndex, setPreviousQuestionIndex] = useState(0);
  const [submitted, setSubmitted] = useState(false);
  const [essayInputEmpty, setEssayInputEmpty] = useState(false);
  const navigate = useNavigate();

  const { uuid } = useParams();

  sessionStorage.setItem("loadNavbar", "false");


  const { data: axiosGetData, error: axiosGetError } = useAxiosGet(
    `/api/summary_questions/${uuid}/`
  );

  useEffect(() => {
    if (axiosGetData) {
      console.log(axiosGetData);
      console.log("the question data is called");
      setNumberOfQuestions(axiosGetData.questions.length);

      setQuestions(eval(axiosGetData.questions));

      if (axiosGetData.question_index != 0) {
        setShowModal(true);
        setPreviousQuestionIndex(axiosGetData.question_index);
      }
      //getting the max points based on the question type
      let totalPointsCalc = 0;
      questions.forEach((question) => {
        totalPointsCalc += getMaxPoints(question.type);
      });
      setMaxPoints(totalPointsCalc);
      setMaxPointsUntilNow(getMaxPoints(eval(axiosGetData.questions)[0].type));

      setLoadingSite(false);
    }

    if (axiosGetError) {
      console.log(axiosGetError);
    }
  }, [axiosGetData, axiosGetError]);

  const getMaxPoints = (currentQuestionType) => {
    if (currentQuestionType === "multipleChoice") {
      return 1;
    } else if (currentQuestionType === "trueOrFalse") {
      return 1;
    } else if (currentQuestionType === "fillInTheBlank") {
      return 2;
    } else if (currentQuestionType === "shortAnswer") {
      return 5;
    } else if (currentQuestionType === "essay") {
      return 10;
    }
  };

  const handleContinueFromSavedPoint = () => {
    setShowModal(false);
    setQuestionIndex(previousQuestionIndex + 1);
  };


  const handleRestart = () => {
    api.put(`/api/delete_summary_fields/${uuid}/`, {})
    .then(() => {
      console.log("Deleted fields");
      setShowModal(false);
      setQuestionIndex(0);
    })
    .catch((error) => {
      console.log("Error while deleting fields:", error);
      setShowModal(false);
      setQuestionIndex(0);
    });
  };
  

  console.log("this is the maxPoints until now", maxPointsUntilNow);

  const handleNextQuestion = () => {
    setShowResponse(false);
    setMaxPointsUntilNow(
      (prevPoints) => prevPoints + getMaxPoints(questions[questionIndex].type)
    );
    setUserAnswer("");
    setCorrection(null);
  };

  const handleRedirect = () => {
    const recapUrl = `/exam/recap/${uuid}/`;
    navigate(recapUrl);
  };

  const handleShowResponse = () => {
    setSubmitted(true);
    if (userAnswer != "") {
      setLoadingSite(true);

      if (questions[questionIndex].type === "multipleChoice") {
        setOptionsUserAnswerIndex(
          questions[questionIndex].choices.indexOf(userAnswer)
        );
      }

      console.log(
        "this is the user answer that is getting send to the backend",
        userAnswer
      );

      setCorrectIncorrect(true);
      setCorrection(null);

      api.post("/api/summary_answers/" + uuid + "/", { answer: userAnswer, questionIndex: questionIndex })
        .then(() => {
          api.get("/api/summary_correction/" + uuid + "/" + questionIndex + "/")
            .then((response) => {
              const responseData = response.data;

              setCorrection(responseData.corrections.slice(-1)[0]);
              setPointsForQuestion(responseData.points_list.slice(-1)[0]);
              setPoints(
                (prevPoints) =>
                  prevPoints + responseData.points_list.slice(-1)[0]
              );
              setCorrectIncorrect(responseData.correct_incorrect.slice(-1)[0]);
              setLoadingSite(false);
              setShowResponse(true);
              setQuestionIndex((prevIndex) => prevIndex + 1);
            })
            .catch((error) => {
              console.log("Error while getting correction data:", error);
            });
        })
        .catch((error) => {
          console.log("Error while posting answer:", error);
        });
      setSubmitted(false)
    }
  };

  const handleEssayChange = (event) => {
    const { value } = event.target;
    setEssayLength(value.length);
    if (essayLength <= 40000) {
      setUserAnswer(value);
    }
  };

  useEffect(() => {
    if (submitted && userAnswer === "" && questions[questionIndex].type === "essay") {
      setEssayInputEmpty(true);
    }
    else {
      setEssayInputEmpty(false);
    }
  }, [userAnswer, submitted]);


  return (
    <div style={{ padding: "0 13vw" }}>
      <ProgressBarComponent
        index={questionIndex}
        numberOfQuestions={numberOfQuestions}
      />

      <Modal
        isOpen={showModal}
        onContinue={handleContinueFromSavedPoint}
        onRestart={handleRestart}
      />
      {!showResponse && questions.length != 0 && (
        <div>
          {console.log("this is the type", questions[questionIndex].type)}
          <QuestionBox
            maxPoints={getMaxPoints(questions[questionIndex].type)}
            question={questions[questionIndex].question}
          />

          {questions[questionIndex].type === "multipleChoice" && (
            <OptionsComponent
              optionsList={questions[questionIndex].choices}
              userAnswer={userAnswer}
              setUserAnswer={setUserAnswer}
              submitted={submitted}
            />
          )}

          {questions[questionIndex].type === "trueOrFalse" && (
            <OptionsComponent
              optionsList={["True", "False"]}
              userAnswer={userAnswer}
              setUserAnswer={setUserAnswer}
              submitted={submitted}
            />
          )}

          {questions[questionIndex].type === "fillInTheBlank" && (
            <InputFieldComponent
              value={userAnswer}
              setValue={setUserAnswer}
              maxWidth="375px"
              placeholder={"Enter answer"}
              submitted={submitted}
            />
          )}
          {questions[questionIndex].type === "shortAnswer" && (
            <InputFieldComponent
              value={userAnswer}
              setValue={setUserAnswer}
              maxWidth="525px"
              placeholder={"Enter answer"}
              submitted={submitted}
            />
          )}

          {questions[questionIndex].type === "essay" && (
            <div>
              <textarea
                className={`w-full mt-3 mb-1.5 p-2.5 h-64 ${essayInputEmpty ? "border-2 border-red-500" : "border-1 border-grey-300"} rounded-md focus: outline-none resize-none`}
                placeholder="Enter your essay"
                onChange={handleEssayChange}
                maxLength={40000}
              />
              <p id="charCount" className="text-sm text-gray-500">
                {essayLength} / 40'000 characters
              </p>
              {essayInputEmpty && (
                <p className="text-red-500 text-sm">Essay is required</p>
              )}
            </div>
          )}
          <AnswerSubmitButton
            onClick={handleShowResponse}
            loadingState={loadingSite}
          />

          <PointsFooter maxPoints={maxPointsUntilNow} currentPoints={points} />
        </div>
      )}

      {showResponse && correctIncorrect === false && correction != null && (
        <div>
          <QuestionBox
            maxPoints={getMaxPoints(questions[questionIndex - 1].type)}
            question={questions[questionIndex - 1].question}
          />
          {questions[questionIndex - 1].type === "multipleChoice" && (
            <div>
              <OptionsCorrectionComponent
                optionsList={questions[questionIndex - 1].choices}
                incorrectIndex={optionsUserAnswerIndex}
                correctIndex={Number(correction)}
              />
            </div>
          )}
          {questions[questionIndex - 1].type === "trueOrFalse" && (
            <div>
              <OptionsCorrectionComponent
                optionsList={["True", "False"]}
                incorrectIndex={userAnswer === "True" ? 0 : 1}
                correctIndex={userAnswer === "True" ? 1 : 0}
              />
            </div>
          )}
          {questions[questionIndex - 1].type === "fillInTheBlank" && (
            <div className="flex flex-col items-center ">
              <div className="mb-2 font-medium">Your answer</div>
              <div
                className="mb-4 p-2.5 foucus:outline-none w-full border-3 border-red-500 rounded-md"
                style={{ maxWidth: "375px" }}
              >
                {userAnswer}
              </div>

              <label className="mb-2 font-medium">Possible correct word</label>
              <div
                className="mb-5 p-2.5 foucus:outline-none w-full border-3 border-green-500 rounded-md"
                style={{ maxWidth: "375px" }}
              >
                {correction}
              </div>
            </div>
          )}
          {questions[questionIndex - 1].type === "shortAnswer" && (
            <div className="flex flex-col items-center ">
              <label className="mb-2 font-medium">Your answer</label>
              <div
                className="mb-4 p-2.5 foucus: outline-none w-full border-3 border-red-500 rounded-md"
                style={{ maxWidth: "525px" }}
              >
                {userAnswer}
              </div>

              <label className="mb-2 font-medium">Feedback</label>
              <div
                className="mb-5 p-2.5 foucus: outline-none w-full border-3 border-green-500 rounded-md"
                style={{ maxWidth: "525px" }}
              >
                {correction}
              </div>
            </div>
          )}

          {questions[questionIndex - 1].type === "essay" && (
            <div className="flex flex-col items-center">
              <label className="mb-2 font-medium">Your essay</label>
              <div className="w-full my-3 p-2.5 h-64 border-3 border-red-500 rounded-md focus: outline-none resize-none">
                {userAnswer}
              </div>
              <label className="mb-2 font-medium">Feedback</label>
              <div className="mb-5 p-2.5 foucus: outline-none w-full border-3 border-green-500 rounded-md">
                {correction}
              </div>
            </div>
          )}

          <NextQuestionButton
            questionIndex={questionIndex}
            numberOfQuestions={numberOfQuestions}
            handleRedirect={handleRedirect}
            handleNextQuestion={handleNextQuestion}
          />

          <WrongFooter />
        </div>
      )}

      {showResponse && correctIncorrect === true && (
        <div>
          <QuestionBox
            maxPoints={getMaxPoints(questions[questionIndex - 1].type)}
            question={questions[questionIndex - 1].question}
          />
          {questions[questionIndex - 1].type === "multipleChoice" && (
            <div>
              <OptionsCorrectionComponent
                optionsList={questions[questionIndex - 1].choices}
                incorrectIndex={NaN}
                correctIndex={optionsUserAnswerIndex}
              />
            </div>
          )}

          {questions[questionIndex - 1].type === "trueOrFalse" && (
            <div>
              <OptionsCorrectionComponent
                optionsList={["True", "False"]}
                incorrectIndex={NaN}
                correctIndex={userAnswer === "True" ? 0 : 1}
              />
            </div>
          )}

          {questions[questionIndex - 1].type === "fillInTheBlank" && (
            <div className="flex flex-col items-center ">
              <div
                className="p-2.5 foucus:outline-none w-full border-3 border-green-500 rounded-md"
                style={{ maxWidth: "375px" }}
              >
                {userAnswer}
              </div>
            </div>
          )}
          {questions[questionIndex - 1].type === "shortAnswer" && (
            <div className="flex flex-col items-center ">
              <div
                className="p-2.5 foucus: outline-none w-full border-3 border-green-500 rounded-md"
                style={{ maxWidth: "525px" }}
              >
                {userAnswer}
              </div>
            </div>
          )}
          {questions[questionIndex - 1].type === "essay" && (
            <div>
              <div className="p-2.5 foucus: outline-none w-full border-3 border-green-500 rounded-md h-64">
                {userAnswer}
              </div>
              <label className="mb-2 font-medium mt-4 flex flex-col items-center">Feedback</label>
              <div className="mb-5 p-2.5 foucus: outline-none w-full border-3 border-green-500 rounded-md">
                {correction}
              </div>
            </div>
          )}

          <NextQuestionButton
            questionIndex={questionIndex}
            numberOfQuestions={numberOfQuestions}
            handleRedirect={handleRedirect}
            handleNextQuestion={handleNextQuestion}
          />
          <CorrectFooter points={pointsForQuestion} />
        </div>
      )}

      {/* {showResponse == NaN && (
        <div>
          <QuestionCorrection
            title={"Correction"}
            answer={userAnswer}
            question={questions[questionIndex - 1]["question"]}
            correction={correction}
            correctIncorrect={correctIncorrect}
            maxPoints={maxPoints}
            pointsEarned={pointsForQuestion}
          />
          {questionIndex === numberOfQuestions ? (
            <button variant="primary" onClick={handleRedirect}>
              Show results
            </button>
          ) : (
            <button variant="primary" onClick={handleNextQuestion}>
              Next question
            </button>
          )}
        </div>
      )} */}
    </div>
  );
}

export default ExamQuestions;
