import api from "./../api";
import { useEffect, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus } from "@fortawesome/free-solid-svg-icons";
import { useNavigate, useParams } from "react-router-dom";

const AllPage = () => {
  const [loading, setLoading] = useState(true);
  const [selectedBoxIndex, setSelectedBoxIndex] = useState(null);
  const [data, setData] = useState([]);

  const { type } = useParams();

  const navigate = useNavigate();

  const handleRepeatButtonClicked = (index) => {
    const url = type === "grammar" ? "/api/delete_grammar_fields/" : "/api/delete_summary_fields/";
    const redirectUrl = type === "grammar" ? "/grammar/questions/" : "/exam/questions/";
    api.put(`${url}${data[index].uuid}/`,{})
      .then(() => {
        console.log("Deleted fields");
        navigate(redirectUrl + data[index].uuid + "/");
      })
      .catch((error) => {
        console.log("Error while deleting fields:", error);
      });
  };

  useEffect(() => {
    setSelectedBoxIndex(null);
    setLoading(true);
    const url = type === "grammar" ? "/api/grammar_title_uuid/" : "/api/summary_title_uuid/";
    api.get(url)
      .then((response) => {
        console.log(response.data);
        setData(response.data);
        setLoading(false);
      })
      .catch((error) => {
        console.error(error);
      });
  }, [type]);

  const handleBoxClick = (index) => {
    setSelectedBoxIndex(index === selectedBoxIndex ? null : index);
  };

  return (
    <div style={{ padding: "0 13vw", backgroundColor: "#F6F7FB" }} className="min-h-screen">
      {!loading && (
        <div>
          <div className="w-full flex justify-between items-center py-10">
            <h1 className="text-3xl font-semibold mb-0 flex items-center">
              {type === "grammar" ? "Grammar exercises library" : "Exam library"}
            </h1>
            <button
              className="text-blue-600 hover:text-blue-700 flex items-center px-3 py-2 rounded-lg transition duration-300 ease-in-out hover:bg-blue-200"
              onClick={() => navigate(type === "grammar" ? "/grammar/input/" : "/exam/input/")}
            >
              <FontAwesomeIcon
                icon={faPlus}
                className="text-2xl mr-2"
                color="rgb(37 99 235)"
              />
              <span className="text-xl text-blue-600 font-medium">Create</span>
            </button>
          </div>

          <div className="grid gird-cols-1 md:grid-cols-1 lg:grid-cols-2 gap-4">
            {data.map((set, index) => (
              <div key={index}>
                <div
                  className={`bg-white shadow transition duration-300 ease-in-out transform hover:scale-105 border-2 border-gray-300 rounded-xl p-3 flex flex-col items-start ${
                    selectedBoxIndex !== index ? "hover:bg-gray-100" : ""
                  }`}
                  onClick={() => handleBoxClick(index)}
                >
                  <div className="font-semibold text-lg mb-3">{set.title}</div>
                  <div className="rounded-full bg-gray-200 px-2 py-1 text-sm font-medium">
                    Questions: {set.number_of_questions}
                  </div>

                  <div className="w-full font-semibold mb-2 mt-4 text-sm">
                    {set.performance}% learned
                  </div>
                  <div className="h-4 mb-4 bg-gray-200 rounded-full w-full">
                    <div
                      className={`bg-green-400 h-4 rounded-full`}
                      style={{ width: `${set.performance}%` }}
                    ></div>
                  </div>

                  {selectedBoxIndex === index && (
                    <div className="flex justify-between gap-3 w-full mt-3">
                      <button
                        className="flex-grow border-2 border-gray-300 p-2.5 rounded-lg hover:bg-blue-100 hover:border-blue-500 active:bg-blue-100 active:border-blue-500 text-center font-medium w-1/2"
                        onClick={() => handleRepeatButtonClicked(index)}
                      >
                        Repeat {type === "grammar" ? "exercises" : "exam"}
                      </button>
                      <button
                        className="flex-grow border-2 border-gray-300 p-2.5 rounded-lg hover:bg-blue-100 hover:border-blue-500 active:bg-blue-100 active:border-blue-500 text-center font-medium w-1/2"
                        onClick={() =>
                          navigate((type === "grammar" ? "/grammar/recap/" : "/exam/recap/") + set.uuid)
                        }
                      >
                        Show recap
                      </button>
                    </div>
                  )}
                </div>
              </div>
            ))}
          </div>
        </div>
      )}
    </div>
  );
};

export default AllPage;
