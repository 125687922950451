import React, { useState, useEffect } from 'react';
import '../../styles/FlashcardComponent.css'; // Ensure this points to your updated CSS
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faStar as farStar } from '@fortawesome/free-regular-svg-icons';
import { faStar } from "@fortawesome/free-solid-svg-icons";
import api from '../../api';




const FlashcardComponent = ({ cards, askMode, cardIndex, setCardIndex, setNumberOfCorrect, setNumberOfIncorrect, setShowRecap, flashcardSetUuid, questionType }) => {

  const [isFlipped, setIsFlipped] = useState(false);
  const [animationDirection, setAnimationDirection] = useState('');
  const [isStarClicked, setIsStarClicked] = useState(false);
  const [windowSize, setWindowSize] = useState(window.innerWidth);
  const [flashcardSize, setFlashcardSize] = useState('');
  const [isRequestPending, setIsRequestPending] = useState(false);





  useEffect(() => {
    if (askMode === "correction"){
      setFlashcardSize('w-[325px] h-[440px] sm:w-[585px] sm:h-[375px] md:w-[732px] md:h-[500px] lg:w-[975px] lg:h-[550px]');
    }
    else {
      setFlashcardSize('max-w-[800px] mx-auto h-[440px] sm:h-[360px] md:h-[432px] lg:h-[450px]');
    }
    function handleResize() {
      setWindowSize(window.innerWidth);
    }

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const iconSize = windowSize < 768 ? 'xs' : 'sm';



  const toggleStar = () => {
    setIsStarClicked(!isStarClicked);
    const data = {
      "uuid": cards[cardIndex].uuid,
      "starred": !isStarClicked,
    }
    api.put("/api/flashcards/update_starred/", data)
  };




  const handleNavigation = (direction) => {
    setIsFlipped(false); 
    setAnimationDirection(direction);
    
    if (direction === 'next') {
      setCardIndex((prevIndex) => (prevIndex + 1) % cards.length);
    }
    else if (direction === 'prev') {
      setCardIndex((prevIndex) => (prevIndex - 1 + cards.length) % cards.length);
    }
  };



  const handleNavigationCorrection = (direction) => {
    setIsFlipped(false); 
    setIsRequestPending(true); // Disable buttons by setting request pending to true

    const data = {
      "uuid": cards[cardIndex].uuid,
      "correct_incorrect": direction,
      "flashcard_set_uuid": flashcardSetUuid,
      "question_type": questionType,
    }

    api.put("/api/flashcards/flashcard/correct_incorrect/", data)
      .then(() => {
        if (direction === 'true') {
          setNumberOfCorrect((prev) => prev + 1);
        } else {
          setNumberOfIncorrect((prev) => prev + 1);
        }

        if (cardIndex === cards.length - 1) {
          setAnimationDirection('');
          setShowRecap(true);
        } else {
          setAnimationDirection(direction);
          setTimeout(() => {
            setCardIndex((current) => (current + 1) % cards.length);
            setAnimationDirection('next');
          }, 500);
        }
      })
      .catch((error) => {
        console.error("Error updating flashcard status:", error);
      })
      .finally(() => {
        setIsRequestPending(false); // Re-enable buttons once the request completes
      });
  };




  const toggleFlip = () => {
    setIsFlipped(!isFlipped);
  };

  return (
    <div className={`perspective-container ${flashcardSize} relative`}>

      <div
        className={`card relative w-full h-full cursor-pointer rounded-2xl border-2 border-gray-300 ${isFlipped ? 'flipped' : ''} ${animationDirection}`}
        onAnimationEnd={() => setAnimationDirection('')}
        onClick={toggleFlip}
      >
        <div className="card-face card-front absolute w-full h-full flex items-center justify-center text-2xl md:text-3xl text-black select-none">
          {cards[cardIndex]?.front}
          {!isFlipped && askMode === "correction" && (
            <button onClick={(e) => {e.stopPropagation(); toggleStar();}} className="absolute top-0 left-0 m-4 outline-none">
              {isStarClicked ? (
                <FontAwesomeIcon icon={faStar} className="text-yellow-400" size={iconSize} />
              ) : (
                <FontAwesomeIcon icon={farStar} className="text-gray-600" size={iconSize} />
              )}
            </button>
          )}

        </div>
        <div className="card-face card-back absolute w-full h-full flex items-center justify-center text-3xl text-black select-none">
          {cards[cardIndex]?.back}
        </div>
      </div>

      
        {askMode === "correction" ? (
          <div className="controls flex justify-center gap-20 mt-4">
            <div className='bg-white flex justify-center items-center rounded-full'>
              <button className='hover:bg-red-200 p-2 rounded-full border-2 border-red-500 hover:border-red-600 focus:outline-none transition duration-150 ease-in-out' onClick={() => handleNavigationCorrection("false")} disabled={isRequestPending}>
                <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="#EF4444" strokeWidth="2.5">
                  <path strokeLinecap="round" strokeLinejoin="round" d="M6 6l12 12M6 18L18 6" />
                </svg>
              </button>
            </div>

            <div className='bg-white flex justify-center items-center rounded-full'>
              <button className='hover:bg-green-200 p-2 rounded-full border-2 border-green-500 hover:border-green-600 focus:outline-none transition duration-150 ease-in-out' onClick={() => handleNavigationCorrection("true")} disabled={isRequestPending}>
                <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="#10B981" strokeWidth="2">
                  <path strokeLinecap="round" strokeLinejoin="round" d="M5 13l4 4L19 7" />
                </svg>
              </button>
            </div>
          </div>
        ):(
          <div className='controls flex justify-center gap-20 mt-4'>
            <div className='bg-white flex justify-center items-center rounded-full'>
              <button className='hover:bg-gray-200 p-2 rounded-full border-2 border-gray-300 hover:border-gray-400 focus:outline-none transition duration-150 ease-in-out' onClick={() => handleNavigation("prev")}>
                <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="gray">
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M15 19l-7-7 7-7" />
                </svg>
              </button>
            </div>
            <div className='bg-white flex justify-center items-center rounded-full'>
              <button className='hover:bg-gray-200 p-2 rounded-full border-2 border-gray-300 hover:border-gray-400 focus:outline-none transition duration-150 ease-in-out' onClick={() => handleNavigation("next")}>
                <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="gray">
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M9 5l7 7-7 7" />
                </svg>
              </button>
            </div>
          </div>
        )}
    </div>
  );
};

export default FlashcardComponent;
