import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/free-solid-svg-icons";
import "../styles/Button.css";
import { Navigate, useNavigate } from "react-router-dom";

const TailwindTest = () => {
  sessionStorage.setItem("loadNavbar", "false");
  const navigate = useNavigate();
  const handleHomeButtonClicked = () => {
    sessionStorage.setItem("loadNavbar", null);
    navigate("/");
  };

  return (
    <div style={{ padding: "0 13vw" }}>
      {/* This should be the progress bar component */}

      {/* ----------------------------------------- */}
      <div className="py-6">
        <div className="flex justify-between items-center">
          <div className="w-2/12 h-7 flex items-center justify-center">
            <FontAwesomeIcon
              onClick={handleHomeButtonClicked}
              icon={faTimes}
              style={{ fontSize: "28px" }}
              className="text-zinc-600"
            />
          </div>
          <div className="w-8/12 h-7">
            <div className="w-full bg-gray-200 rounded-full h-7 mb-4 dark:bg-gray-700 ">
              <div
                className="bg-navbar-green h-7 rounded-full dark:bg-green-500"
                style={{ width: "45%" }}
              ></div>
            </div>
          </div>
          <div className="w-2/12 h-7 flex items-center justify-center">
            <div className="text-xl text-zinc-600">1/30</div>
          </div>
        </div>
      </div>
      {/* ----------------------------------------- */}

      {/* This should be the Question box component */}
      {/* ----------------------------------------- */}
      <div className="flex justify-center items-center">
        <div className="my-11 flex-col justify-center items-center gap-2.5 inline-flex">
          <div className="text-neutral-700 text-2xl font-medium">
            How many wheels does a car have?
          </div>
          <div className="text-sm text-gray-500">Max Points: 2</div>
        </div>
      </div>
      {/* ----------------------------------------- */}
      <div className="flex flex-col items-center">
        <input type="radio" id="option1" name="options" className="hidden" disabled />
        <label
          htmlFor="option1"
          className="cursor-pointer select-none bg-green-300 text-black font-medium py-3 px-6 rounded-xl transition-colors duration-300 border-1 border-black mb-2 w-full"
          
          style={{ maxWidth: "325px" }}
        >
          Option 1
        </label>

        <input type="radio" id="option2" name="options" className="hidden" disabled/>
        <label
          htmlFor="option2"
          className="cursor-pointer select-none bg-correction-red text-black font-medium py-3 px-6 rounded-xl transition-colors duration-300 border-1 border-black mb-2 w-full"
          style={{ maxWidth: "325px" }}
        >
          Option 2
        </label>

        <input type="radio" id="option3" name="options" className="hidden" />
        <label
          htmlFor="option3"
          className="cursor-pointer select-none bg-blue-200 hover:bg-blue-300  text-black font-medium py-3 px-6 rounded-xl transition-colors duration-300 border-1 border-black mb-2 w-full"
          style={{ maxWidth: "325px"}}
        >
          Option 3
        </label>

        <input type="radio" id="option4" name="options" className="hidden" />
        <label
          htmlFor="option4"
          className="cursor-pointer select-none hover:border-gray-500 focus: font-medium py-3 px-6 rounded-xl transition-colors duration-300 border-2 w-full focus:border-option-blue-border"
          style={{ maxWidth: "325px" }}
        >
          Option 4
        </label>
      </div>
      <div className="flex justify-center my-12">
        <button type="button" class="text-white bg-gradient-to-br from-green-400 to-blue-600 hover:bg-gradient-to-bl focus:ring-4 focus:outline-none focus:ring-green-200 dark:focus:ring-green-800 font-medium rounded-lg text-sm px-5 py-2.5 text-center me-2 mb-2">
          Submit  
        </button>
      </div>
      <div className="border-2 border-black flex justify-center py-6 my-12 w-full text-xl font-semibold">
        Points: 3/30
      </div>


    </div>
  );
};
export default TailwindTest;
