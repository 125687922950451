import { useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import PointsBarComponent from "../components/QuestionRecap/PointsBarComponent";
import ShortAnswerRecap from "../components/QuestionRecap/ShortAnswerRecapComponent";
import LoadingSpinner from "../components/General/LoadingSpinner";
import api from "../api";


const FlashcardRecap = () => {
  const [maxPoints, setMaxPoints] = useState(0);
  const [points, setPoints] = useState(0);
  const [allCorrect, setAllCorrect] = useState(false);
  const [flashcards, setFlashcards] = useState([]);

  const navigate = useNavigate();
  const { uuid } = useParams();


  const askTerms = (flashcardInput) => {
    return flashcardInput.map(flashcard => ({
      uuid: flashcard.uuid,
      question: flashcard.definition,
      answer: flashcard.user_answer,
      correctIncorrect: flashcard.correct_incorrect[flashcard.correct_incorrect.length - 1],
      correction: flashcard.correction,
      point: flashcard.points
    }));
  }
  const askDefinitions = (flashcardInput) => {
    return flashcardInput.map(flashcard => ({
      uuid: flashcard.uuid,
      question: flashcard.word,
      answer: flashcard.user_answer,
      correctIncorrect: flashcard.correct_incorrect[flashcard.correct_incorrect.length - 1],
      correction: flashcard.correction,
      point: flashcard.points
    }));
  }

  useEffect(() => {
    api.get("/api/flashcards/saved_write_mode_flashcards/"+uuid+"/")
    .then((response)=>{
      console.log("this is the response data 34", response.data)
      let flashcardData = response.data;
      if (sessionStorage.getItem("askType") === "term") {
        flashcardData = askTerms(flashcardData);
      }
      else {
        flashcardData = askDefinitions(flashcardData);
      }
      console.log("this is the flashcard data 40", flashcardData)
      let isAllCorrect = flashcardData.every(item => {return item.correctIncorrect === true;});
      setAllCorrect(isAllCorrect);
      setPoints(flashcardData.reduce((acc, item) => acc + item.point, 0));
      setMaxPoints(flashcardData.length);
      
      setFlashcards(flashcardData);
    })
    .catch((error) => {
      console.error("Failed to fetch correction data:", error);
    });
  }, []);

  const handleButtonClicked = () => {
    if (allCorrect) {
      navigate("/flashcard/all/")
    }
    else {
      navigate("/flashcard/mode/write/" + uuid + "/incorrect/")
    }
  };

  console.log("this is the flashcards 57", flashcards)

  if (flashcards.length === 0) {
    return (
      <LoadingSpinner />
    );
  }


  return (
    <div style={{ backgroundColor: "#F6F7FB" }} className="px-6 min-h-screen">
      <PointsBarComponent points={points} maxPoints={maxPoints} />
      {flashcards.map((flashcard, index) => (
        <div className="flex justify-center items-center">
          <div className="max-w-[735px] w-full">
            <div className="bg-white rounded-lg shadow-md mb-4">
              <div className="px-8 pb-12 pt-8">
                <ShortAnswerRecap
                  key={index}
                  question={flashcard.question}
                  correctIncorrect={flashcard.correctIncorrect}
                  answer={flashcard.answer}
                  point={flashcard.point}
                  correction={flashcard.correction}
                />
              </div>
            </div>
          </div>
        </div>
      ))}
      <div className="flex flex-col items-center mt-8">
        <button
          type="button"
          className="text-white bg-gradient-to-r from-cyan-400 via-cyan-500 to-cyan-600 hover:bg-gradient-to-br focus:ring-4 focus:outline-none focus:ring-cyan-300 dark:focus:ring-cyan-800 font-medium rounded-lg text-sm px-5 py-2.5 text-center me-2 mb-2"
          onClick={handleButtonClicked}
        >
          {allCorrect ? "Repeat all words" : "Repeat wrong words"}
        </button>
      </div>
    </div>

  );
};
export default FlashcardRecap;
