import { useState, useEffect } from "react";
import useAxiosGet from "../hooks/useAxiosGet";
import { useParams, useNavigate } from "react-router-dom";
import QuestionBox from "../components/OtherQuestionComponents/QuestionBox";
import InputFieldComponent from "../components/OtherQuestionComponents/InputFieldComponent";
import ProgressBarComponent from "../components/OtherQuestionComponents/ProgressBarComponent";
import NextQuestionButton from "../components/OtherQuestionComponents/NextQuestionButton";
import AnswerSubmitButton from "../components/OtherQuestionComponents/AnswerSubmitButton";
import WrongFooter from "../components/OtherQuestionComponents/WrongFooter";
import CorrectFooter from "../components/OtherQuestionComponents/CorrectFooter";
import PointsFooter from "../components/OtherQuestionComponents/PointsFooter";
import Modal from "../components/OtherQuestionComponents/ReloadModal";
import api from "./../api";


const GrammarQuestions = () => {
  const [question, setQuestion] = useState([]);
  const [questionIndex, setQuestionIndex] = useState(0);
  const [userAnswer, setUserAnswer] = useState("");
  const [correction, setCorrection] = useState("");

  const [loading, setLoading] = useState(true);

  const [pointsForQuestion, setPointsForQuestion] = useState(0);
  const [points, setPoints] = useState(0);
  const [maxPoints, setMaxPoints] = useState(0);

  const [correctIncorrect, setCorrectIncorrect] = useState("");
  const [numberOfQuestions, setNumberOfQuestions] = useState(0);
  const [showResponse, setShowResponse] = useState(false);
  const [showRecap, setShowRecap] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [previousQuestionIndex, setPreviousQuestionIndex] = useState(0);
  const [submitted, setSubmitted] = useState(false);

  const { uuid } = useParams();
  const navigate = useNavigate();

  const { data, error } = useAxiosGet("/api/grammar_questions/" + uuid + "/");

  useEffect(() => {
    sessionStorage.setItem("loadNavbar", "false");

    if (data) {
      console.log(data);
      const questionData = data.questions;
      setQuestion(questionData);
      setMaxPoints(questionData.length);
      setNumberOfQuestions(questionData.length);
      console.log("This is the question index",questionData.question_index)

      if (data.question_index != 0) {
        setShowModal(true);
        setPreviousQuestionIndex(data.question_index);
      }
      setLoading(false);
    }

    if (error) {
      console.error(error);
    }
  }, [data, error]);

  const handleContinueFromSavedPoint = () => {
    setShowModal(false);
    setQuestionIndex(previousQuestionIndex + 1);
  };


  const handleRestart = () => {
    api.put(`/api/delete_grammar_fields/${uuid}/`, {})
    .then(() => {
      console.log("Deleted fields");
      setShowModal(false);
      setQuestionIndex(0);
    })
    .catch((error) => {
      console.log("Error while deleting fields:", error);
      setShowModal(false);
      setQuestionIndex(0);
    });
  };

  const handleShowCorrection = () => {
    setSubmitted(true);    
    if (userAnswer != "") {
      setLoading(true);
      console.log("handle show correction is getting called");
      const correction_data = {
        user_answer: userAnswer,
      };



      api.post("/api/grammar_answers/" + uuid + "/", correction_data)
        .then((response) => {
          api.get(
              "/api/grammar_corrections/" + uuid + "/" + questionIndex + "/")
            .then((response) => {
              const responseData = response.data;
              console.log("this is the response data", response.data);
              setCorrection(responseData.corrections.slice(-1)[0]);
              setPointsForQuestion(responseData.points_list.slice(-1)[0]);
              setPoints((prevPoints) => prevPoints + pointsForQuestion);
              setCorrectIncorrect(responseData.correct_incorrect.slice(-1)[0]);
              setQuestionIndex((prevIndex) => prevIndex + 1);
              setLoading(false);
              setShowResponse(true);
            })
            .catch((error) => {
              console.error(error);
            });
        })
        .catch((error) => {
          console.error(error);
        });
      setSubmitted(false);
    }
  };

  const handleNextQuestion = () => {
    setShowResponse(false);
    setUserAnswer("");
  };

  const handleFinishRedirect = () => {
    const recapUrl = `/grammar/recap/${uuid}/`;
    navigate(recapUrl);
  };

  return (
    <div style={{ padding: "0 13vw" }}>
      <ProgressBarComponent
        index={questionIndex}
        numberOfQuestions={maxPoints}
      />
      <Modal
        isOpen={showModal}
        onContinue={handleContinueFromSavedPoint}
        onRestart={handleRestart}
      />

      {!showRecap && !showResponse && question.length > 0 && (
        <div>
          <QuestionBox maxPoints={1} question={question[questionIndex]} />
          <InputFieldComponent
            value={userAnswer}
            setValue={setUserAnswer}
            maxWidth="525px"
            placeholder={"Enter answer"}
            submitted={submitted}
          />
          <AnswerSubmitButton
            loadingState={loading}
            onClick={handleShowCorrection}
          />
          <PointsFooter maxPoints={questionIndex} currentPoints={points} />
        </div>
      )}

      {showResponse && correctIncorrect === false && (
        <div>
          <QuestionBox maxPoints={1} question={question[questionIndex - 1]} />
          <div className="flex flex-col items-center ">
            <label className="mb-2 font-medium">Your answer</label>
            <div
              className="mb-4 p-2.5 foucus: outline-none w-full border-3 border-red-500 rounded-md"
              style={{ maxWidth: "525px" }}
            >
              {userAnswer}
            </div>

            <label className="mb-2 font-medium">Feedback</label>
            <div
              className="mb-5 p-2.5 foucus: outline-none w-full border-3 border-green-500 rounded-md"
              style={{ maxWidth: "525px" }}
            >
              {correction}
            </div>
          </div>
          <NextQuestionButton
            questionIndex={questionIndex}
            numberOfQuestions={numberOfQuestions}
            handleRedirect={handleFinishRedirect}
            handleNextQuestion={handleNextQuestion}
          />
          <WrongFooter />
        </div>
      )}

      {showResponse && correctIncorrect === true && (
        <div>
          <QuestionBox maxPoints={1} question={question[questionIndex - 1]} />
          <div className="flex flex-col items-center ">
            <div
              className="p-2.5 foucus: outline-none w-full border-3 border-green-500 rounded-md"
              style={{ maxWidth: "525px" }}
            >
              {userAnswer}
            </div>
          </div>
          <NextQuestionButton
            questionIndex={questionIndex}
            numberOfQuestions={numberOfQuestions}
            handleRedirect={handleFinishRedirect}
            handleNextQuestion={handleNextQuestion}
          />
          <CorrectFooter points={pointsForQuestion} />
        </div>
      )}


     
    </div>
  );
};

export default GrammarQuestions;
