import { useState } from "react";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import api from "./../api";
import { Navigate } from "react-router-dom";
import { GoogleLoginButton } from "react-social-login-buttons";

// TODO: Add react social login buttons, Link: https://www.npmjs.com/package/react-social-login-buttons
// TODO: secure the googlel login url savly


const AuthenticationPage = () => {
    const [authMode, setAuthMode] = useState("signin")
    const [registerUsername, setRegisterUsername] = useState("");
    const [registerPassword1, setRegisterPassword1] = useState("");
    const [registerPassword2, setRegisterPassword2] = useState("");
    const [registerEmail, setRegisterEmail] = useState("");
    const [loginUsername, setLoginUsername] = useState("");
    const [loginPassword, setLoginPassword] = useState("");
    const [loginEmail, setLoginEmail] = useState("");
    const [handleRedirectHome, setHandleRedirectHome] = useState(false);
    const [handleRedirectConfirmEmail, setHandleRedirectConfrimEmail] = useState(false);
    const [handleRedirectResetPassword, setHandleRedirectResetPassword] = useState(false);


    const redirectGoogleLogin = () => {

      window.location.href = process.env.REACT_APP_GOOGLE_LOGIN_URL;

    }



    if (handleRedirectConfirmEmail) {
        return <Navigate to="/confirm-email" />;
    }

    if (handleRedirectHome) {
        return <Navigate to="/" />;
    }


    const changeAuthMode = () => {
        setAuthMode(authMode === "signin" ? "signup" : "signin")

    }


    const fetchSubscriptionData = () => {

        api
          .get("/api/payment/subscriptions/")
          .then((response) => {
            console.log("This is the subscription data");
            console.log(response.data);
            const subscriptionTier = response.data.subscription_tier
            console.log("THIS IS THE SUBSCRIPTION TIER: ");
            console.log(subscriptionTier);
            localStorage.setItem("subscriptionTier", subscriptionTier);
          })
          .catch((error) => {
            console.error("Error fetching subscription data:", error);
          });
      };

    const handleLoginButtonClicked = (event) => {
        event.preventDefault();
        console.log("Login button clicked");
        const data = {
            "username": loginUsername,
            "email" : loginEmail,
            "password": loginPassword,
        };

        api
        .post("/api/auth/login/", data)
        .then((response) => {
            console.log(response.data); // Optional: log the response
            sessionStorage.setItem("isAuthenticated", true);
            // get the user subscirption tier
            fetchSubscriptionData();
            setHandleRedirectHome(true);
        }
        )
        .catch((error) => {
            console.error("Login Error:", error);

            if (error.response) {
              if (error.response.data.email) {
                toast.error(error.response.data.email[0]); // Display email-related error
              } else if (error.response.data.password) {
                toast.error(error.response.data.password[0]); // Display password-related error
              } else if (error.response.data.username) {
                toast.error(error.response.data.username[0]); // Display username-related error
              } else if (error.response.data.non_field_errors) {
                toast.error(error.response.data.non_field_errors[0]); // Display non-field errors
              } else {
                toast.error("An error occurred during login."); // Display a generic error message
              }
            } else {
              toast.error("Network error. Please try again."); // Handle network errors
            }
          });
    }

    const handleRegisterButtonClicked = (event) => {
        event.preventDefault();
        console.log("Register button clicked");
        const data = {
            "username": registerUsername,
            "email" : registerEmail,
            "password1": registerPassword1,
            "password2": registerPassword2,
        };
        console.log("Data: ", data);
        api
        .post("api/auth/register/", data)
        .then((response) => {
            console.log(response.data); // Optional: log the response
            setHandleRedirectConfrimEmail(true);
        }
        )
        .catch((error) => {
            console.error("Register Error:", error);
            if (error.response) {
                if (error.response.data.email) {
                  toast.error(error.response.data.email[0]); // Display email-related error
                } else if (error.response.data.password) {
                  toast.error(error.response.data.password[0]); // Display password-related error
                } else if (error.response.data.username) {
                  toast.error(error.response.data.username[0]); // Display username-related error
                } else if (error.response.data.non_field_errors) {
                  toast.error(error.response.data.non_field_errors[0]); // Display non-field errors
                } else {
                  toast.error("An error occurred during login."); // Display a generic error message
                }
              } else {
                toast.error("Network error. Please try again."); // Handle network errors
              }
            });
    }





    if (authMode === "signin") {
        return (
        <div className="Auth-form-container">
            <form className="Auth-form">
            <div className="Auth-form-content">
                <h3 className="Auth-form-title">Sign In</h3>
                <div className="text-center">
                Not registered yet?{" "}
                <span className="link-primary" onClick={changeAuthMode}>
                    Sign Up
                </span>
                </div>
                <div className="d-grid gap-2 mt-3">
                <GoogleLoginButton
                    onClick={redirectGoogleLogin}
                />
                </div>
                <div className="form-group mt-3">
                <label>Username</label>
                <input
                    type="text"
                    className="form-control mt-1"
                    placeholder="Enter username"
                    value={loginUsername}
                    onChange={(event) => setLoginUsername(event.target.value)}
                />
                </div>
                <div className="form-group mt-3">
                <label>Email address</label>
                <input
                    type="Email"
                    className="form-control mt-1"
                    placeholder="Enter email"
                    value={loginEmail}
                    onChange={(event) => setLoginEmail(event.target.value)}
                />
                </div>
                <div className="form-group mt-3">
                <label>Password</label>
                <input
                    type="password"
                    className="form-control mt-1"
                    placeholder="Enter password"
                    value={loginPassword}
                    onChange={(event) => setLoginPassword(event.target.value)}
                />
                </div>
                <div className="d-grid gap-2 mt-3">
                <button type="submit" className="btn btn-primary" onClick={handleLoginButtonClicked}>
                    Submit
                </button>
                <ToastContainer />
                </div>
                <p className="text-center mt-2">
                Forgot <a href="password/reset">password?</a>
                </p>
            </div>
            </form>
        </div>
        )
    }

    return (
        <div className="Auth-form-container">
        <form className="Auth-form">
            <div className="Auth-form-content">
            <h3 className="Auth-form-title">Register</h3>
            <div className="text-center">
                Already registered?{" "}
                <span className="link-primary" onClick={changeAuthMode}>
                Sign In
                </span>
            </div>
            <div className="d-grid gap-2 mt-3">
                <GoogleLoginButton
                    onClick={redirectGoogleLogin}
                />
                </div>
            <div className="form-group mt-3">
                <label>Username</label>
                <input
                type="Username"
                className="form-control mt-1"
                placeholder="Enter username"
                value={registerUsername}
                onChange={(event) => setRegisterUsername(event.target.value)}
                />
            </div>
            <div className="form-group mt-3">
                <label>Email address</label>
                <input
                type="email"
                className="form-control mt-1"
                placeholder="Email Address"
                value={registerEmail}
                onChange={(event) => setRegisterEmail(event.target.value)}
                />
            </div>
            <div className="form-group mt-3">
                <label>Password</label>
                <input
                type="password"
                className="form-control mt-1"
                placeholder="Password"
                value={registerPassword1}
                onChange={(event) => setRegisterPassword1(event.target.value)}
                />
            </div>
            <div className="form-group mt-3">
                <label>Confirm password</label>
                <input
                type="password"
                className="form-control mt-1"
                placeholder="Confirm password"
                value={registerPassword2}
                onChange={(event) => setRegisterPassword2(event.target.value)}
                />
            </div>
            <div className="d-grid gap-2 mt-3">
                <button type="submit" className="btn btn-primary" onClick={handleRegisterButtonClicked}>
                Submit
                </button>
                <ToastContainer />
            </div>
            <p className="text-center mt-2">
                Forgot <a href="password/reset">password?</a>
            </p>
            </div>
        </form>
        </div>
    )

}

export default AuthenticationPage;
