import { useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import axios from "axios";
import LoadingSpinner from "../components/General/LoadingSpinner";
import Cookies from "js-cookie";

const AppleSignInCallbackScreen = () => {
  const { code, id_token } = useParams();
  const navigate = useNavigate();
  useEffect(() => {
    axios.post("/api/auth/apple/login/", { access_token: code, id_token: id_token })
      .then((response) => {
        console.log(response.data);
        sessionStorage.setItem("isAuthenticated", true);
        Cookies.set("accessToken", response.data.access, {
          expires: 2,
          secure: true,
          sameSite: "strict",
        });
        Cookies.set("refreshToken", response.data.refresh, {
          expires: 90,
          secure: true,
          sameSite: "strict",
        });
        navigate("/")

      })
      .catch((error) => {
        console.error(error);
      });
  }, [code, id_token]);



  return <LoadingSpinner/>;
};
export default AppleSignInCallbackScreen;