import { useState, useEffect } from "react";
const GrammarInputComponent = ({
  title,
  value,
  setValue,
  placeholder,
  submitted,
}) => {
  const [error, setError] = useState(false);

  useEffect(() => {
    if (submitted && value.trim() === "") {
      setError(true);
    }
  }, [submitted, value]);


  return (
    <div>
      <label className="mb-3 font-medium">
        {title}
      </label>
      <input
        className={`w-full ${error ? "border-2 border-red-500": "border-1 border-grey-300"} rounded-lg p-2.5 mb-1.5`}
        type={"text"}
        placeholder={placeholder}
        value={value}
        onChange={(event) => setValue(event.target.value)}
      />
      {error && <p className="text-red-500 text-sm">Field is required</p>}
    </div>
  );
}

export default GrammarInputComponent;
