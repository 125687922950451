import { useRef, useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronLeft, faChevronRight, faTrash } from '@fortawesome/free-solid-svg-icons';
import api from '../../api';
import ConfirmDeletionModal from '../ConfirmDeletionModal';
import { notify } from './../../providers/NotificationProvider';


const RecentQuizList = ({ data, setData, questionType }) => {
  const scrollContainerRef = useRef(null);
  const [containerWidth, setContainerWidth] = useState(0);
  const [itemWidth, setItemWidth] = useState(300);
  const [minItemWidth, setMinItemWidth] = useState(300);
  const [showButtons, setShowButtons] = useState(false);
  const [isScrollable, setIsScrollable] = useState(false);
  const [canScrollLeft, setCanScrollLeft] = useState(false);
  const [canScrollRight, setCanScrollRight] = useState(false);
  const [visibleIndexes, setVisibleIndexes] = useState({ first: 0, last: 0 });
  const [selectedBoxIndex, setSelectedBoxIndex] = useState(null);
  const [showConfirmDeletionModal, setShowConfirmDeletionModal] = useState(false);
  const [deleteDraftUuid, setDeleteDraftUuid] = useState(null);

  const navigate = useNavigate();


  const handleScroll = (direction) => {
    const container = scrollContainerRef.current;
    const item = container.children[0];
    if (!item) return;
    const itemWidth = item.offsetWidth;

    const scrollDistance = (itemWidth) * (direction === 'left' ? -1 : 1);
  
    container.scrollBy({
      left: scrollDistance,
      behavior: 'smooth',
    });
    updateVisibleIndexes();
    updateScrollButtons();
  };



  const updateScrollButtons = () => {
    const container = scrollContainerRef.current;
    if (container) {

      setCanScrollLeft(container.scrollLeft > 0);
      setCanScrollRight(container.scrollLeft < container.scrollWidth - container.clientWidth);
    }
  };

  const updateDimensions = () => {
    const width = window.innerWidth;
    const minItemWidth = width >= 768 ? 450 : 300;
    setMinItemWidth(minItemWidth);
    
    
    const container = scrollContainerRef.current;
    if (container) {
      const width = container.clientWidth;
      setContainerWidth(width);
      const itemsThatFit = Math.floor(width / minItemWidth);
      const dynamicItemWidth = width / itemsThatFit;
      setItemWidth(dynamicItemWidth);

      const contentOverflow = container.scrollWidth > container.clientWidth;
      setIsScrollable(contentOverflow);

      updateScrollButtons();
      updateVisibleIndexes();
    }
  };




  
  
  


  useEffect(() => {
    const container = scrollContainerRef.current;
    if (container) {
      container.addEventListener('scroll', updateScrollButtons);
      container.addEventListener('scroll', updateVisibleIndexes);
      window.addEventListener('resize', updateDimensions);
      updateDimensions();
      updateScrollButtons(); 
    }
    return () => {
      if (container) {
        container.removeEventListener('scroll', updateScrollButtons);
        container.removeEventListener('scroll', updateVisibleIndexes);
      }
      window.removeEventListener('resize', updateDimensions);
    };
  }, []);

  

  const updateVisibleIndexes = () => {
    const container = scrollContainerRef.current;
    if (!container) return;

    const children = Array.from(container.children);
    const visibleWidth = container.offsetWidth;
    let firstVisibleIndex = -1;
    let lastVisibleIndex = -1;

    children.forEach((child, index) => {
      const childLeft = child.offsetLeft - container.scrollLeft;
      const childRight = childLeft + child.offsetWidth;

      if (childLeft < visibleWidth && childRight > 0) {
        lastVisibleIndex = index;
        if (firstVisibleIndex === -1) {
          firstVisibleIndex = index;
        }
      }

      if ((childLeft === 0 && childLeft < visibleWidth) || (childRight === 0 && childRight < visibleWidth) || childRight === -1 || childRight === 1 || childLeft === 1 || childLeft === -1 ) {
        firstVisibleIndex = index;
      }
    });

    setVisibleIndexes({ first: firstVisibleIndex, last: lastVisibleIndex });
  };

  useEffect(() => {
    setTimeout(() => {
      updateDimensions();
      updateScrollButtons();
      updateVisibleIndexes();
    }, 1);
  }, []);

  useEffect(() => {
    setTimeout(() => {
      updateVisibleIndexes();
    }, 1);
  }, [containerWidth])
  

  const handleBoxClicked = (index) => {
    if (questionType === "flashcards") {
      if (data[index].type === "draft") {
        navigate(`/flashcard/input/${data[index].uuid}/`);
      } else {
        navigate(`/flashcard/preview/${data[index].uuid}/`);
      }
    } else {
      setSelectedBoxIndex(index === selectedBoxIndex ? null : index);
    }
  };


  const handleRepeatButtonClicked = (index) => {
    const url = questionType === "grammar" ? "/api/delete_grammar_fields/" : "/api/delete_summary_fields/";
    const redirectUrl = questionType === "grammar" ? "/grammar/questions/" : "/exam/questions/";
    api.put(`${url}${data[index].uuid}/`,{})
      .then(() => {
        console.log("Deleted fields");
        navigate(redirectUrl + data[index].uuid + "/");
      })
      .catch((error) => {
        console.log("Error while deleting fields:", error);
      });
  };

  const handleDeleteDraft = () => {
    api.delete(`/api/flashcards/delete_flashcard_draft/${deleteDraftUuid}/`)
    .then((response) => {
      console.log(response.data);
      const updatedData = data.filter(item => item.uuid !== deleteDraftUuid);
      setData(updatedData); 
      notify({message: "Draft deleted successfully!", type: "success"});

    })
    .catch((error) => {
      console.error(error);
    });
  };




  const buttonStyle = {
    transition: 'opacity 0.3s ease-in-out, visibility 0.3s ease-in-out',
    opacity: showButtons ? 1 : 0,
    visibility: showButtons ? 'visible' : 'hidden',
    zIndex: 20,
  };

  const shadowStyle = {
    background: 'linear-gradient(to right, rgba(246, 247, 251, 1) 15%, transparent 70%)',
    position: 'absolute',
    width: '10%',
    height: '100%',
    transition: 'opacity 0.3s ease-in-out',
    opacity: showButtons ? 1 : 0,
    zIndex: 10,
  };

  const scrollContainerStyle = {
    overflowX: 'auto',
    scrollbarWidth: 'none',
    msOverflowStyle: 'none',
    '&::-webkit-scrollbar': {
      display: 'none',
    },
  };

  console.log("this is the data: ", data);







  return (
    <div
      className="relative w-full flex items-center overflow-auto"
      onMouseEnter={() => setShowButtons(true)}
      onMouseLeave={() => setShowButtons(false)}
    >
      <ConfirmDeletionModal showModal={showConfirmDeletionModal} setShowModal={setShowConfirmDeletionModal} handleDelete={handleDeleteDraft} header={"Delete Draft?"} text={"Are you sure you want to delete the draft? This action cannot be undone."} buttonLabel={"Delete"} />

      {isScrollable && canScrollLeft && (
        <>
          <div style={{ ...shadowStyle, left: 0 }}></div>
          <div className="absolute left-0 flex items-center justify-center z-5">
            <button
              onClick={() => handleScroll('left')}
              style={buttonStyle}
              className="p-2 bg-blue-500 text-white rounded-full hover:bg-blue-600 shadow-md"
            >
              <FontAwesomeIcon icon={faChevronLeft} size={20} />
            </button>
          </div>
        </>
      )}






      <div
        className="flex overflow-auto scroll-smooth snap-x snap-mandatory w-full py-4"
        ref={scrollContainerRef}
        style={scrollContainerStyle}
      >
        {data.map((set, index) => (
          <div
            key={set.id || index}
      
            className="flex-shrink-0 snap-start"
            style={{ minWidth: minItemWidth, width: itemWidth, paddingLeft: index === visibleIndexes.first ? '15px' : '12px', paddingRight: index === visibleIndexes.last ? '15px' : '12px'}}
          >
            <div 
              className={`border-2 border-gray-200 p-4 rounded-lg bg-white cursor-pointer hover:border-blue-500 ${visibleIndexes.first <= index && index <= visibleIndexes.last && "shadow"}`} onClick={() => handleBoxClicked(index)}
            >
              {set.type === "draft" ? (
                <div className='flex justify-start space-x-2'>
                  <p className="font-semibold text-lg mb-3">
                  {set.title || "Untitled"}
                  </p>
                  <span className="text-blue-600 font-semibold text-lg">(Draft)</span>
                </div>
              ) : (
                <h3 className="font-semibold mb-2 text-lg">{set.title}</h3>
              )}
              <div className="rounded-full bg-gray-200 px-2 py-1 text-sm font-medium inline-block">
                {set.type !== "draft" ? (questionType === "flashcards" ? `Terms: ${set.number_of_flashcards}` : `Questions: ${set.number_of_questions}`) : `Terms: ${set.words.length}`}
              </div>
              {set.type === "draft" ? (
                <div className='w-full flex justify-end'>
                  <button 
                    className="mt-3 flex justify-end items-center font-medium text-red-500 gap-2.5 py-1.5 px-2.5 rounded-lg hover:bg-red-100" 
                    onClick={(e) => {
                      e.stopPropagation();
                      setDeleteDraftUuid(set.uuid);
                      setShowConfirmDeletionModal(true);
                    }}
                  >
                    <FontAwesomeIcon icon={faTrash} />
                    <p>Delete Draft</p>
                  </button>
                </div>
              ) : (
                <>
                  <p className="font-medium text-sm mt-3">{set.performance}% learned</p>
                  <div className="h-4 mt-2 bg-gray-200 rounded-full w-full">
                    <div
                      className={`bg-green-400 h-4 rounded-full`}
                      style={{ width: `${set.performance}%` }}
                    />
                  </div>
                </>
              )}

              {selectedBoxIndex === index && (
                <div className="flex justify-between gap-3.5 w-full mt-4">
                  <button
                    className="flex-grow border-2 border-gray-300 p-2.5 rounded-lg hover:bg-blue-100 hover:border-blue-500 active:bg-blue-100 active:border-blue-500 text-center font-medium w-1/2"
                    onClick={() => handleRepeatButtonClicked(index)}
                  >
                    Repeat {questionType === "grammar" ? "exercises" : "exam"}
                  </button>
                  <button
                    className="flex-grow border-2 border-gray-300 p-2.5 rounded-lg hover:bg-blue-100 hover:border-blue-500 active:bg-blue-100 active:border-blue-500 text-center font-medium w-1/2"
                    onClick={() =>
                      navigate((questionType === "grammar" ? "/grammar/recap/" : "/exam/recap/") + set.uuid)
                    }
                  >
                    Show recap
                  </button>
                </div>
              )}
            </div>
          </div>
        ))}
      </div>


      {isScrollable && canScrollRight && (
        <>
          <div
            style={{
              ...shadowStyle,
              right: 0,
              background: 'linear-gradient(to left, rgba(246, 247, 251, 1) 15%, transparent 70%)',
            }}
          ></div>
          <div className="absolute right-0 flex items-center justify-center z-20">
            <button
              onClick={() => handleScroll('right')}
              style={buttonStyle}
              className="p-2 bg-blue-500 text-white rounded-full hover:bg-blue-600 shadow-md"
            >
              <FontAwesomeIcon icon={faChevronRight} size={20} />
            </button>
          </div>
        </>
      )}
    </div>
  );
};

export default RecentQuizList;

