import { useState, useEffect } from "react";

const AnswerInputField = ({ value, setValue, placeHolder, submitted, onSubmit }) => {
  const [error, setError] = useState(false);

  useEffect(() => {
    if (submitted && value.trim() === "") {
      setError(true);
    } else {
      setError(false);
    }
  }, [submitted, value]);

  const handleOnSubmit = (event) => {
    event.preventDefault();
    if (value.trim().length > 0) {
      onSubmit();
    } else {
      setError(true);
    }
  };

  return (
    <form className="flex flex-row items-center w-full" onSubmit={handleOnSubmit}>
      <input
        autoFocus={true}
        type="text"
        placeholder={placeHolder}
        value={value}
        onChange={(event) => setValue(event.target.value)}
        className={`shadow p-2.5 w-full focus:outline-none rounded-md border-2 bg-white ${error ? "border-red-500" : "border-gray-300"}`}
      />
      {error && <p className="text-red-500 text-sm">Field is required</p>}
    </form>
  );
};
export default AnswerInputField;