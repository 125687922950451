import React, { useEffect, useState } from 'react';
import { CircularProgressbar, buildStyles } from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';

const CircularProgress = ({ maxTokens, currentTokens, subscriptionTier }) => {

  const getResponsiveSize = (width) => {
    const maxSize = 225;
    const minSize = 150; // Ensuring a larger minimum size for smaller screens
    const desktopLimit = 1600;
    const fixedMaxSize = 250;
    if (width >= desktopLimit) {
      return fixedMaxSize;
    }
    if (width >= 1200) {
      return maxSize;
    } else if (width >= 992) {
      return Math.max(minSize, maxSize - 25);
    } else if (width >= 768) {
      return Math.max(minSize, maxSize - 50);
    } else if (width >= 576) {
      return Math.max(minSize, maxSize - 75);
    } else {
      return minSize;
    }
  };
  
  const [size, setSize] = useState(getResponsiveSize(window.innerWidth));

  useEffect(() => {
    const handleResize = () => {
      setSize(getResponsiveSize(window.innerWidth));
    };

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const strokeWidth = Math.max(10, Math.sqrt(size / 250) * 10); // Increased minimum stroke width to 6
  const percentage = currentTokens === 0 ? 0 : (currentTokens / maxTokens) * 100;
  const displayText = subscriptionTier === 'max' ? '∞' : `${currentTokens}/${maxTokens}`;

  return (
    <div style={{ width: size, height: size, margin: '0 auto' }}>
      <CircularProgressbar
        value={percentage}
        text={displayText}
        strokeWidth={strokeWidth}
        styles={buildStyles({
          strokeLinecap: 'round',
          pathColor: '#4ade80',
          trailColor: '#D1D5DB',
          textSize: `clamp(16px, ${Math.max(16, Math.sqrt(size / 250) * 12)}px, 20px)`, // Increased minimum text size to 16px
          textColor: '#000',
        })}
      />
    </div>
  );
};

export default CircularProgress;
