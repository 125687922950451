import { faTimes } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import api from "./../../api";

const StartFlashcardSession = ({
  showModal,
  setShowModal,
  askMode,
}) => {
  const { uuid } = useParams();
  const navigate = useNavigate();

  const [isMobile, setIsMobile] = useState(false);
  const [userHasIncorrect, setUserHasIncorrect] = useState();
  const [userHasStarred, setUserHasStarred] = useState(false);
  const [askRandomly, setAskRandomly] = useState(false);
  const [aiFeedback, setAiFeedback] = useState(false);
  const [askType, setAskType] = useState("definition");
  const [questionFilter, setQuestionFilter] = useState("all");


  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 640); 
    };
    handleResize(); 
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize); 
    };
  }, []);

  useEffect(() => {
    api.get("/api/flashcards/starred/"+uuid+"/")
    .then((response) => {
      if (response.data.length > 0) {
        setUserHasStarred(true);
      }
    })

    if (askMode === "write") {
      api.get("/api/flashcards/incorrect/write_mode/"+uuid+"/")
      .then((response) => {
        if (response.data.length > 0) {
          setUserHasIncorrect(true);
        }
      })
    }


  }, []);



  const handleStartSession= () => {
    sessionStorage.setItem("askRandomly", askRandomly);
    sessionStorage.setItem("aiFeedback", aiFeedback);
    sessionStorage.setItem("askType", askType);



    let baseUrl = "/"
    if (askMode === "write") {
      baseUrl = `/flashcard/mode/write/${uuid}/`
    }
    else {
      baseUrl = `/flashcard/mode/flashcard/${uuid}/`
    }
    if (questionFilter === "starred") {
      navigate(baseUrl + "starred/")
    }
    if (questionFilter === "incorrect") {
      navigate(baseUrl +"incorrect/")
    }
    if (questionFilter === "all") {
      navigate(baseUrl + "all/")
    }
  }

  const handleModalClose = () => {
    sessionStorage.setItem("askRandomly", askRandomly);
    sessionStorage.setItem("aiFeedback", aiFeedback);
    sessionStorage.setItem("askType", askType);
  
    setShowModal(false); 
  }
 

  return showModal ? (
    <div className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50">
      <div
        className={`bg-white pt-6  px-10 pb-10 rounded-xl ${
          isMobile ? "w-full h-full" : "w-[750px] sm:mx-auto relative lg:w-[825px]"
        }`}
      >

        <button
          onClick={handleModalClose}
          className="absolute top-7 left-10 focus:outline-none"
        >
          <FontAwesomeIcon
            icon={faTimes}
            className="text-gray-500 hover:text-gray-700 text-2xl"
          />
        </button>

        <div className="h-full flex flex-col justify-center items-center">
          <p className="text-lg font-semibold mb-10">
            Flashcard session settings
          </p>
          <div className="flex justify-between items-center w-full ml-2.5">
            <span className="font-medium text-gray-900 dark:text-gray-300">
              Shuffle terms
            </span>
            <label className="inline-flex items-center cursor-pointer">
              <input
                type="checkbox"
                value=""
                className="sr-only peer"
                checked={askRandomly}
                onChange={() => setAskRandomly(!askRandomly)}
              />
              <div
                className={`relative w-11 h-6 bg-gray-200 peer-focus:outline-none peer-focus:ring-4 peer-focus:ring-transparent dark:peer-focus:ring-transparent rounded-full 
                ${
                  askRandomly
                    ? "peer-checked:after:translate-x-full rtl:peer-checked:after:-translate-x-full peer-checked:after:border-white"
                    : ""
                } 
                after:content-[''] after:absolute after:top-[2px] after:start-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all dark:border-gray-600 peer-checked:bg-blue-600`}
              ></div>
            </label>
          </div>

          <div className="flex justify-between items-center w-full mt-4 ml-2.5">
            <label className="block font-medium text-gray-900 dark:text-gray-300">
              Answer with
            </label>
            <select className="block border border-gray-300 rounded-lg shadow-sm p-2.5 focus:outline-none mt-1" defaultValue={askType} value={askType} onChange={e => setAskType(e.target.value)}>
              <option value="definition">Definition</option>
              <option value="term">Term</option>
            </select>
          </div>
          <div className="flex justify-between items-center w-full mt-4 ml-2.5">
            <label className="block font-medium text-gray-900 dark:text-gray-300">
              Learn
            </label>
            {(
              (askMode === "flashcard" && !userHasStarred) ||
              (askMode === "write" && !userHasStarred && !userHasIncorrect)
            ) ? (
              <span className="block border border-gray-300 rounded-lg shadow-sm p-2.5 mt-1 font-medium">All terms</span>
            ) : (
              <select className="block border border-gray-300 rounded-lg shadow-sm p-2.5 focus:outline-none mt-1" defaultValue={questionFilter} onChange={e => setQuestionFilter(e.target.value)}>
                <option value="all">All terms</option>
                {userHasStarred && <option value="starred">Only learn ★-terms</option>}
                {askMode === "write" && userHasIncorrect && <option value="incorrect">Only wrong terms</option>}
              </select>
            )}
          </div>


          {askMode === "write" && (
            <div className="mt-4 ml-2.5 w-full">
              <div className="flex justify-between items-center cursor-pointer w-full">
                <span className="font-medium text-gray-900 dark:text-gray-300">AI feedback</span>
                <label className="inline-flex items-center cursor-pointer">
                  <input
                    type="checkbox"
                    value=""
                    className="sr-only peer"
                    checked={aiFeedback}
                    onChange={() => setAiFeedback(!aiFeedback)}
                  />
                  <div
                    className={`relative w-11 h-6 bg-gray-200 peer-focus:outline-none peer-focus:ring-4 peer-focus:ring-transparent dark:peer-focus:ring-transparent rounded-full 
                              ${
                                aiFeedback
                                  ? "peer-checked:after:translate-x-full rtl:peer-checked:after:-translate-x-full peer-checked:after:border-white"
                                  : ""
                              } 
                              after:content-[''] after:absolute after:top-[2px] after:start-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all dark:border-gray-600 peer-checked:bg-green-400`}
                  ></div>
                </label>
              </div>
              <div className="flex justify-start w-full mt-1">
                <div className="w-full" style={{ maxWidth: 'calc(100% - 100px)' }}>
                  <span className="text-sm text-neutral-400">
                    Ignoring spelling errors when marking, and check for understanding rather than a response that is exactly the same as the provided definition.
                  </span>
                </div>
              </div> 
            </div>
          )}

          <div className="flex justify-center w-full mt-10">
            <button
              onClick={handleStartSession}
              className="shadow-lg border-2 border-gray-300 py-3 px-10 rounded-lg hover:bg-blue-100  hover:border-blue-500 active:bg-blue-100 active:border-blue-500 font-medium"
            >
              Start Session
            </button>
            </div>

        </div>
      </div>
    </div>
  ) : null;
};

export default StartFlashcardSession;