import { useState, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import api from "../api";

//TODO: working on this page
const FlashcardEdit = () => {
  const [flashcards, setFlashcards] = useState([]);
  const [title, setTitle] = useState("");
  const [flashcardLimit, setFlashcardLimit] = useState(0);
  const [subscriptionPlan, setSubscriptionPlan] = useState("");
  const { uuid } = useParams();


  useEffect(() => {
    const urls = [`/api/flashcards/all/${uuid}/`, `/api/flashcards/flashcard_set/${uuid}/`, `/api/subscription/`,]
    Promise.all(urls.map(url => api.get(url)))
    .then(responses => {
      const flashcardResponse = responses[0].data;
      const flashcardSetResponse = responses[1].data;
      const subscriptionResponse = responses[2].data;

      setFlashcards(flashcardResponse);
      setTitle(flashcardSetResponse.title);

      if (subscriptionResponse.plan === "max") {
        setFlashcardLimit(5000);
        setSubscriptionPlan("max");
      } else {
        setFlashcardLimit(100);
        setSubscriptionPlan("basic");
      }
    })
  }, [uuid]);



  
  return (
    <div>

    </div>
  );
};
export default FlashcardEdit;