import useAxiosGet from '../hooks/useAxiosGet';
import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import api from './../api';
// display:  
// - user email
// - user name
// - password
// - past exercises in categories
// TODO: add special url for every quiz from a user


const UserInfo = () => {    
    const [username, setUsername] = useState("");
    const [email, setEmail] = useState("");
    const [userData, setUserData] = useState([]);
    const [userError, setUserError] = useState("");
    const [language, setLanguage] = useState("");
    const [initialLoad, setInitialLoad] = useState(true);

    const { data, error } = useAxiosGet("/api/auth/user/");

    useEffect(() => {
        // Only set displayData when data changes
        setUserData(data);
        setUserError(error);
        // setUsername(data.username);
        // setEmail(data.email);
        console.log("data", data);
        console.log("error", error);
    }, [data, error,]);

    useEffect(() => {
        if (initialLoad) {
            api.get("api/auth/user_profile/")
            .then((response) => {
                console.log("response from the backend")
                console.log(response.data);
                setLanguage(response.data.language);
                setInitialLoad(false);
            })
            .catch((error) => {
                console.error(error);
            });
        }
        setInitialLoad(false);
    }
    , []);
    const handleSaveLanguage = (e) => {
        e.preventDefault();
        api.patch("api/auth/user_profile/", {language: language})
        .then((response) => {
            console.log("response from the backend")
            console.log(response.data);
        })
        .catch((error) => {
            console.error(error);
        });
    }


    return (
        <div>
            <h1>User Info</h1>
            <div>
                <p>{JSON.stringify(userData)}</p>
            </div>
            <div>
                <select value={language} onChange={(e) => setLanguage(e.target.value)} >
                    <option value="spanish">spannish</option>
                    <option value="english">english</option>
                    <option value="german">german</option>
                </select>
                <button onClick={handleSaveLanguage}>Save Language</button>
            </div>
            

            <div>
                <Link to="/allquestions/summary/">
                    <button>Go to all quizes</button>
                </Link>
                <Link to="/allquestions/definition/">
                    <button>Go to all definition quizes</button>
                </Link>
                <Link to="/allquestions/grammar/">
                    <button>Go to all grammar quizes</button>
                </Link>
            </div>
            <div>
                <Link to="https://billing.stripe.com/p/login/test_7sI9CDeiB2nxabKfYY">
                    <button>Go to subscription</button>
                </Link>
            </div>
            <div>
                <Link to="/password/reset/">
                    <button>Password reset</button>
                </Link>
            </div>
            <div>
                <Link to="/logout">
                    <button>Logout</button>
                </Link>
            </div>
        </div>
    );
}
export default UserInfo;