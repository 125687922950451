import { useState, useEffect } from "react";
import axios from "axios";
import ResendEmailModal from "../components/OtherQuestionComponents/ResendEmailModal";

const ConfirmEmail = () => {
  const [email, setEmail] = useState(""); // Add state for email input
  const [maskedEmail, setMaskedEmail] = useState("");
  const [showEmailField, setShowEmailField] = useState(false);
  const [showEmailSuccessResend, setShowEmailSuccessResend] = useState(false);

  useEffect(() => {
    setMaskedEmail(sessionStorage.getItem("maskedEmail"));
  }, []);

  const onResendEmailButtonClicked = (e) => {
    e.preventDefault();
    const data = { email }; 
    axios
      .post("api/auth/register/resend-email/", data)
      .then((response) => {
        console.log(response.data);
        setShowEmailSuccessResend(true);
      })
      .catch((error) => {
        console.error(error);
      });

  };
  const onShowEmailResendField = () => {
    setShowEmailField(!showEmailField);
  }

 
  return (
    <div className="bg-gray-50 dark:bg-gray-900">
      <div className="flex flex-col items-center justify-center px-6 py-8 mx-auto md:h-screen lg:py-0">
        <p className="flex items-center mb-6 text-2xl font-semibold text-gray-900 dark:text-white">
          <img
            className="w-8 h-8 mr-2"
            src="https://tailwindui.com/img/logos/workflow-mark-indigo-500.svg"
            alt="logo"
          />
          Study buddy
        </p>
        <div className="w-full bg-white rounded-lg shadow dark:border md:mt-0 sm:max-w-md xl:p-0 dark:bg-gray-800 dark:border-gray-700">
          <div className="p-6 space-y-4 md:space-y-6 sm:p-8">
            <h2 className="text-xl font-bold text-gray-900 dark:text-white">
              Check your inbox
            </h2>
            <p className="text-sm text-gray-500 dark:text-gray-400 mb-4">
              We have sent an email to {" "}
              <span className="text-sm text-black font-semibold">
                {maskedEmail}
              </span>
              . Please click the link in the email to confirm your email address.
            </p>



            {showEmailField ? (
              <form className="space-y-4 md:space-y-6" onSubmit={(e) => onResendEmailButtonClicked(e)}>

                <label
                  htmlFor="email"
                  className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                >
                  Confirm your email
                </label>
                <input
                  type="email"
                  name="email"
                  id="email"
                  className="bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-blue-600 focus:border-blue-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                  placeholder="name@company.com"
                  autoComplete="email"
                  required
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                />
                <button
                  type="submit"
                  className="rounded-xl flex flex-row items-center justify-center border-2 transition hover:bg-neutral-100 border-neutral-200 w-full mt-3"
                  style={{ height: "50px" }}
                >
                  Resend email
                </button>
              </form>
            ):(
              <div>
                <button
                  className="rounded-xl flex flex-row items-center justify-center border-2 transition hover:bg-neutral-100 border-neutral-200 w-full mt-3"
                  style={{ height: "50px" }}
                  onClick={onShowEmailResendField}
                >
                  <p className="font-semibold text-lg text-center">
                    Resend email
                  </p>
                </button>
              </div>
            
            )}
          </div>
        </div>
      </div>
      {showEmailSuccessResend && (<ResendEmailModal setShowEmailSuccessResend={setShowEmailSuccessResend} />)}
      
    </div>
  );
};

export default ConfirmEmail;
