import { useState, useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes, faFileLines } from '@fortawesome/free-solid-svg-icons';
import Lottie from 'react-lottie';
import animationData from "../../Assets/Lottie/generate-from-file.json";
import api from '../../api';


const UploadDocumentModal = ({ showModal, setShowModal, type, processData, loading, setLoading, setLimitReason, setShowLimitModal, subscriptionPlan }) => {
  const [dragOver, setDragOver] = useState(false);
  const [fileUploadErrors, setFileUploadErrors] = useState("");
  const [fileSubmitted, setFileSubmitted] = useState(false);
  const [fileName, setFileName] = useState("");
  const [file, setFile] = useState(null);
  const [numberOfFlashcards, setNumberOfFlashcards] = useState(1);

  const userCharacterLimit = subscriptionPlan === "max" ? 50000 : 10000;

  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: animationData,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice'
    }
  };

  const numberOfFlashcardOptions = [];
  for (let i = 1; i <= 5; i++) {
    numberOfFlashcardOptions.push(
      <option key={i} value={i}>
        {i}
      </option>
    );
  }

  for (let i = 10; i <= 50; i += 5) {
    numberOfFlashcardOptions.push(
      <option key={i} value={i}>
        {i}
      </option>
    );
  }





  const handleDragEnter = (e) => {
      e.preventDefault();
      e.stopPropagation();
      setDragOver(true);
  };

  const handleDragLeave = (e) => {
      e.preventDefault();
      e.stopPropagation();
      setDragOver(false);
  };

  const handleDragOver = (e) => {
      e.preventDefault();
      e.stopPropagation();
      setDragOver(true);
  };

  const handleDrop = (e) => {
      e.preventDefault();
      e.stopPropagation();
      setDragOver(false);
      if (e.dataTransfer.files && e.dataTransfer.files.length > 0) {
        processFile(e.dataTransfer.files);
        e.dataTransfer.clearData();
      }
  };



  const processFile = (file) => {
    // Info: 5242880 = 5MB
    if (file.size > 5242880) {
      return setFileUploadErrors("File size is too large. Please upload a file less than 5MB.");
    } 
    console.log(file[0]);
    setFileUploadErrors("");
    setFileSubmitted(true);
    setFileName(file[0].name);
    setFile(file[0]);
  };


  const uploadFile = () => {
    if (!file) {
      return setFileUploadErrors('Please select a file.');
    }
    setLoading(true);
  
    const formData = new FormData();
    formData.append('file', file);
    formData.append('number_of_flashcards', numberOfFlashcards);
  
    api.post("/api/upload_file/" + type + "/", formData, { headers: { 'Content-Type': 'multipart/form-data' } })
      .then((response) => {
        console.log("This is the response data: ", response.data);

        if (!response.data.enough_tokens && response.data.enough_tokens !== undefined) {
          setLimitReason("tokens");
          setShowLimitModal(true);
          setLoading(false);
          return;
        }
        setFileUploadErrors('');
        processData(response.data);
      })
      .catch((error) => {
        if (error.response && error.response.data) {
          console.log(error.response.data);
          const errorMessage = error.response.data.error || 'An error occurred while uploading the file.';
          setFileUploadErrors(errorMessage);
        } else {
          setFileUploadErrors('Error uploading file. Please try again.');
        }
        console.error("Error uploading file:", error);
      });
  };
  


  return showModal && (
      <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center">
          <div className="bg-gray-100 rounded-lg shadow-lg w-full relative max-w-5xl">
            {loading ? (
              <div className='h-[698px] flex flex-col items-center justify-center'>
                <Lottie 
                  options={defaultOptions}
                  height={500}
                  width={500}
                />
                <h1 className='text-xl font-semibold text-purple-500'>Generating {type}...</h1>
              </div>
            ) : (
              <div>
                <div className='px-8 pt-8'>
                  <h2 className="text-2xl font-bold">Upload Document</h2>
                  <h3 className='mt-2 text-lg'>Please ensure your file does not exceed {userCharacterLimit} characters and is no larger than 5 MB.</h3>
                  <h3 className='text-lg mb-6'>Only PDF and DOCX files are allowed.</h3>
                  <div className={`h-[300px] border-4 border-dashed rounded-lg ${dragOver ? 'border-blue-600 bg-blue-200' : 'border-blue-400 bg-blue-100'} relative`}
                      onDragEnter={handleDragEnter}
                      onDragOver={handleDragOver}
                      onDragLeave={handleDragLeave}
                      onDrop={handleDrop}>
                      {!fileSubmitted && (
                        <input type="file" id="file-upload" className="opacity-0 w-full h-full absolute inset-0 cursor-pointer"
                          onChange={(e) => processFile(e.target.files)}
                          accept=".pdf,.docx"
                          onClick={(event) => { event.target.value = null; }}
                          multiple={false}
                        />
                      )}


                      <label htmlFor="file-upload" className={`text-center flex flex-col items-center justify-center h-full `}>
                        {fileSubmitted ? (
                          <div>
                            <FontAwesomeIcon icon={faFileLines} className='text-blue-500 text-8xl' />
                            <p className='mt-4 mb-8 text-lg text-blue-600 font-medium'>{fileName}</p>
                            <button className=' bg-blue-200 text-blue-600 rounded-full px-3 py-1.5 font-semibold hover:bg-blue-300 hover:text-blue-700' onClick={() => setFileSubmitted(false)}>
                              Change File
                            </button>
                          </div>
                        ) : (
                          <div>
                            <p className="text-blue-600 font-medium">Drag and drop your files here or click to select files.</p>
                            <p className="text-blue-600 font-medium">Only PDF and DOCX files are allowed.</p>
                          </div>
                        )}
                      </label>
                  </div>
                  <p className='text-lg font-medium text-red-500 mt-3'>{fileUploadErrors}</p>
                  <div className='mt-6'>
                    <h2 className='text-xl font-bold'>Number of Flashcards</h2>
                    <select 
                      className='border-2 border-gray-300 rounded px-3 py-2.5 mt-3 w-[205px] font-medium'
                      value={numberOfFlashcards}
                      onChange={(e) => setNumberOfFlashcards(e.target.value)}
                    >
                      {numberOfFlashcardOptions}
                    
                    </select>
                  </div>
                </div>
                <div className='border-b-2 border-blue-400 mt-8' />

                <div className="flex justify-between mt-4 px-8 pb-8">
                  <button className="bg-blue-500 text-white rounded-md px-4 py-2 font-semibold" onClick={() => setShowModal(false)}>Cancel</button>
                  <button className="bg-blue-500 text-white rounded-md px-4 py-2 font-semibold" onClick={uploadFile}>Generate</button>
                </div>
                <button
                  onClick={() => setShowModal(false)}
                  className="absolute top-5 right-5 text-2xl hover:text-gray-500 text-gray-800">
                  <FontAwesomeIcon icon={faTimes} />
                </button>
              </div>
            )}

          </div>
      </div>
  );
};

export default UploadDocumentModal;
