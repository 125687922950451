const ResendEmailModal = ({setShowEmailSuccessResend}) => {
  return (
    <div className="flex justify-center m-5">
      <div
        id="successModal"
        className="fixed top-0 right-0 left-0 z-50 flex justify-center items-center w-full md:inset-0 h-modal md:h-full bg-gray-800 bg-opacity-75"
        tabIndex="-1"
        aria-hidden="false"
      >
        <div className="relative p-4 w-full max-w-md h-full md:h-auto">
          <div className="relative p-4 text-center bg-white rounded-lg shadow sm:p-5 dark:bg-gray-800">
            <button
              type="button"
              onClick={() => setShowEmailSuccessResend(false)}
              className="text-gray-400 absolute top-2.5 right-2.5 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm p-1.5 ml-auto inline-flex items-center dark:hover:bg-gray-600 dark:hover:text-white"
            >
              <svg
                className="w-5 h-5"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth={2}
                  d="M6 18L18 6M6 6l12 12"
                />
              </svg>
              <span className="sr-only">Close modal</span>
            </button>

            <div className="w-12 h-12 rounded-full bg-green-100 dark:bg-green-900 p-2 flex items-center justify-center mx-auto mb-3.5">
              <svg
                className="w-8 h-8 text-green-500 dark:text-green-400"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth={2}
                  d="M5 13l4 4L19 7"
                />
              </svg>
              <span className="sr-only">Success</span>
            </div>

            <p className="mb-4 text-lg font-semibold text-gray-900 dark:text-white">
              Successfully resend email.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};
export default ResendEmailModal;
