import { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import useAxiosGet from "../hooks/useAxiosGet";


// the url will be formated like this = reacp/type=summary&uuid=1234

// DONT NEED THIS ANYMORE

const Recap = () => {
    const [url, setUrl] = useState("");
    const [questions, setQuestions] = useState([]);
    const [answers, setAnswers] = useState([]);
    const [correction, setCorrection] = useState([]);
    const [questionType, setQuestionType] = useState("");
    const [maxPoints, setMaxPoints] = useState(0);
    const [points, setPoints] = useState(0);
    const [title, SetTitle] = useState("");
    const [correctIncorrect, setCorrectIncorrect] = useState([]);




    const { type, uuid } = useParams();

    console.log("type", type);
    console.log("uuid", uuid);

    useEffect(() => {
        if (type==="summary") {
            setUrl("/api/summary/" + uuid + "/");
            console.log("url", url);
        }
        else if (type==="grammar") {
            setUrl("/api/grammar/" + uuid + "/");
        }
        else if (type==="definitions") {
            setUrl("/api/definition/" + uuid + "/");
        }
    }
    , [type, uuid]);


    const { data, error } =useAxiosGet(url);
    useEffect(() => {
        if (data) {
            console.log(data);
            setQuestions(data.questions);
            setAnswers(data.user_answers);
            setCorrection(data.corrections);
            setQuestionType(data.questions[0].question_type);
            setMaxPoints(data.total_points);
            setPoints(data.points);
            SetTitle(data.title);
            setCorrectIncorrect(data.correct_incorrect);




        }

        if (error) {
            console.log(error);
        }

    }
    , [data, error]);
    return(
        <div>
            <p>Recap</p>
        </div>
    )


};
export default Recap;