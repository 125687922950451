import { useEffect, useState } from "react";
import api from "./../api";
import { Link, useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPen } from "@fortawesome/free-solid-svg-icons";

const Account = () => {
  const [googleAccount, setGoogleAccount] = useState(false);
  const [email, setEmail] = useState("");
  const [passwordEdit, setPasswordEdit] = useState(false);
  const [hasStripeAccount, setHasStripeAccount] = useState(true);
  const [customerPlan, setCustomerPlan] = useState("");
  const [userLanguage, setUserLanguage] = useState("")
  const [oldUserLanguage, setOldUserLanguage] = useState("")
  const [showSaveLanguage, setShowSaveLanguage] = useState(false);

  const navigate = useNavigate();


  useEffect(() => {
    const urls = [
      "/api/auth/account-type/",
      "/api/auth/user/",
      "/api/payment/subscriptions/",
      "/api/userinfo/"
    ]

    Promise.all(urls.map(url => api.get(url)))
    .then((responses) => {
      console.log(responses);
      setGoogleAccount(responses[0].data.social_account);
      console.log("This is the google account response: ", responses[0].data.social_account);
      console.log("(!responses[0].data.social_account): ", (!responses[0].data.social_account));
      if (!responses[0].data.social_account) {
        setPasswordEdit(true);
      }
      setEmail(responses[1].data.email);
      if (responses[2].data.customer_id !== "") {
        setHasStripeAccount(true);
        setCustomerPlan(responses[2].data.subscription_tier);
      }
      setUserLanguage(responses[3].data.language);
      setOldUserLanguage(responses[3].data.language);
    })
    .catch((errors) => {
      console.error(errors);
    });


  }, []);





  const onLanguageChange = (event) => {
    const newLanguage = event.target.value;
    setUserLanguage(newLanguage);
    if (newLanguage !== oldUserLanguage) {
      saveLanguageToDatabase(newLanguage);
    }
  };

  const saveLanguageToDatabase = (language) => {
    api.put("/api/userinfo/", { language: language })
      .then((response) => {
        console.log("Language updated:", response.data);
        setOldUserLanguage(language);
      })
      .catch((error) => {
        console.error("Failed to save language:", error);
      });
  };



  
  


  return (
    <main className="max-w-5xl flex justify-center items-center flex-col" style={{ margin: "0px auto", padding: "60px 20px" }}>
      <div className="flex justify-between w-full items-center mb-7">
        <h1 className="text-4xl font-semibold">Your account</h1>
      </div>
      <div className="p-8 w-full rounded-3xl border-2 border-neutral-200 mb-5 shadow-lg">
        <p className="text-2xl font-semibold mb-3">Your details</p>
        <div className="py-4">
          <p className="font-medium text-sm text-neutral-400 mb-1">Your email address</p>
          <p className="font-semibold">{email}</p>
        </div>
        {passwordEdit && (
          <div className="pb-4 flex justify-between items-center">
            <div>
              <p className="font-medium text-sm text-neutral-400 mb-1">Your password</p>
              <p className="font-semibold text-lg">•••••••••••••</p>
            </div>
            <button className="text-blue-600 hover:text-blue-700 focus:outline-none" onClick={() => navigate("/changepassword/")}>
              <FontAwesomeIcon icon={faPen}/>
            </button>
          </div>
        )}
      <div className="border-b border-neutral-200" />

      <div className="pt-4 flex-row">
        <p className="font-medium text-sm text-neutral-400 mb-1 pb-2">Language for the <span className="font-bold">feedback</span> and <span className="font-bold">exams</span></p>

        <select className="font-semibold text-md p-2.5 pr-20 border border-gray-300 rounded-lg focus:outline-none" onChange={(event) => onLanguageChange(event)} value={userLanguage}>
          <option value={"en"}>English 🇺🇸</option>
          <option value={"de"}>Deutsch 🇩🇪</option>
        </select>


      </div>

      </div>
      <div className="p-8 w-full rounded-3xl border-2 border-neutral-200 mb-5 shadow-lg bg-white">
        <p className="text-2xl font-semibold mb-3">Your subscription</p>
        {hasStripeAccount ? (
          <div className="py-2 flex justify-between items-center">
            <div>
              <p className="font-medium text-sm text-neutral-400 mb-1">Your plan</p>
              <p className="font-semibold">{customerPlan}</p>
            </div>
            <Link to="https://billing.stripe.com/p/login/bIYg1haUscJZ6wE4gg" className="text-sm hover:bg-blue-500 transition font-semibold leading-6 text-white bg-blue-600 rounded-full px-5 py-2">Manage</Link>
          </div>
        ):(
          <div className="py-4 flex justify-between items-center">
            <p className="font-medium">You're not subscribed to Study Buddy.</p>
            <a href="/pricing/" className="text-sm hover:bg-blue-500 transition font-semibold leading-6 text-white bg-blue-600 rounded-full px-5 py-2">Upgrade</a>
          </div>
        )}
        
      </div>
      <div className="p-8 w-full rounded-3xl border-2 border-neutral-200 mb-5 shadow-lg bg-white">
        <p className="text-2xl">Support</p>
      </div>

    </main>
  );
}
export default Account;