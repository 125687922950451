import { useState, useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes, faGear } from "@fortawesome/free-solid-svg-icons";
import QuitModal from "../OtherQuestionComponents/QuitModal";
import FlashcardSettingsModal from "./FlashcardSettingsModal";
import { useNavigate } from "react-router-dom";

const FlashcardProgressBar = ({ index, numberOfQuestions, flashcards, uuid }) => {
  const [progress, setProgress] = useState(0);
  const [showQuitModal, setShowQuitModal] = useState(false);
  const [showSettingsModal, setShowSettingsModal] = useState(false);
  const navigate = useNavigate();

  sessionStorage.setItem("loadNavbar", "false");

  const handleHomeButtonClicked = () => {
    sessionStorage.setItem("loadNavbar", null);
    return navigate(`/flashcard/preview/${uuid}/`);
  };

  useEffect(() => {
    index === 0 && setProgress(0);
    index > 0 && setProgress((index / numberOfQuestions) * 100);
  }, [index, numberOfQuestions]);

  const handleQuitModalButtonClicked= (event) => {
    event.preventDefault();
    event.stopPropagation();
    setShowQuitModal(true)
  }

  const handleSettingsModalButtonClicked = (event) => {
    event.preventDefault();
    event.stopPropagation();
    setShowSettingsModal(true)
  }


  return (
    <div className="py-6">
      <QuitModal
        isOpen={showQuitModal}
        onQuit={handleHomeButtonClicked}
        onContinue={() => setShowQuitModal(false)}
      />
      <FlashcardSettingsModal showModal={showSettingsModal} setShowModal={setShowSettingsModal} flashcards={flashcards} askMode={"write"} />
      <div className="flex justify-between items-center">
        <div className="w-2/12 h-7 flex items-center justify-center">
          <button onClick={(event) => handleQuitModalButtonClicked(event)}>
            <FontAwesomeIcon
              icon={faTimes}
              style={{ fontSize: "28px" }}
              className="text-gray-500 hover:text-gray-600"
            />
          </button>
        </div>
        <div className="w-8/12 h-7">
          <div className="w-full bg-gray-200 rounded-full h-7 mb-4 dark:bg-gray-700 ">
            <div
              className="bg-green-400 h-7 rounded-full dark:bg-green-500"
              style={{ width: `${progress}%` }}
            ></div>
          </div>
        </div>
        <div className="w-2/12 h-7 flex items-center justify-center">
          <button onClick={(event) => handleSettingsModalButtonClicked(event)}>
            <FontAwesomeIcon icon={faGear} style={{fontSize:"21px"}} className="text-gray-500 hover:text-gray-600"/>
          </button>
        </div>
      </div>
    </div>
  );
};
export default FlashcardProgressBar;
