import React, { useState } from 'react';
import { loadStripe } from '@stripe/stripe-js';
import api from './../api';

const PricingTable = () => {
  const [stripe, setStripe] = useState(null);

  // Initialize Stripe.js once
  React.useEffect(() => {
    const initializeStripe = async () => {
      try {
        console.log("This is the stripe Key: ", process.env.REACT_APP_STRIPE_SESSION_KEY)
        const stripeInstance = await loadStripe(process.env.REACT_APP_STRIPE_SESSION_KEY);
        setStripe(stripeInstance);
      } catch (error) {
        console.error('Error initializing Stripe:', error);
      }
    };
    initializeStripe();
  }, []);

  const handleSubmit = async (priceId) => {
    try {
      const response = await api.post("/api/payment/stripesubscription/", { price_id: priceId },);
        console.log("This is the response: ", response)
        console.log("this is the session id from stripe", response.data.session_id)
      if (stripe && response.data.session_id) {
        const { error } = await stripe.redirectToCheckout({ sessionId: response.data.session_id });
        if (error) {
          console.error("Error redirecting to Stripe Checkout:", error);
        }
      } else {
        console.error("Stripe or Session ID not found");
      }
    } catch (error) {
      console.error("Error subscribing:", error);
    }
  };
  

  return (
    <section className="py-6 leading-7 text-gray-900 bg-white sm:py-12 md:py-16">
      <div className="box-border px-4 mx-auto border-solid sm:px-6 md:px-6 lg:px-0 max-w-7xl">
        <div className="flex flex-col items-center leading-7 text-center text-gray-900 border-0 border-gray-200">
          <h2
            id="pricing"
            className="box-border m-0 text-3xl font-semibold leading-tight tracking-tight text-black border-solid sm:text-4xl md:text-5xl"
          >
            Simple, Transparent Pricing
          </h2>
          <p className="box-border mt-2 text-xl text-gray-900 border-solid sm:text-2xl"></p>
        </div>

        <div
          id="pricing"
          className="grid grid-cols-1 gap-4 mt-4 leading-7 text-gray-900 border-0 border-gray-200 sm:mt-6 sm:gap-6 md:mt-8 md:gap-0 lg:grid-cols-3"
        >
          {/* Price 1 */}
          <div className="relative z-10 flex flex-col items-center max-w-md p-4 mx-auto my-0 border border-solid rounded-lg lg:-mr-3 sm:my-0 sm:p-6 md:my-8 md:p-8">
            <h3 className="m-0 text-2xl font-semibold leading-tight tracking-tight text-black border-0 border-gray-200 sm:text-3xl md:text-4xl">
              Free
            </h3>
            <div className="flex items-end mt-6 leading-7 text-gray-900 border-0 border-gray-200">
              <p className="box-border m-0 text-6xl font-semibold leading-none border-solid">
                $0
              </p>
              <p
                className="box-border m-0 border-solid"
                style={{ borderImage: "initial" }}
              >
                / month
              </p>
            </div>
            <ul className="flex-1 p-0 mt-4 ml-5 leading-7 text-gray-900 border-0 border-gray-200">
              <li className="inline-flex items-center  w-full mb-2 ml-5 font-semibold text-left border-solid">
                <svg
                  className="w-5 h-5 mr-2 font-semibold leading-7 text-green-600 sm:h-5 sm:w-5 md:h-6 md:w-6"
                  fill="none"
                  stroke="currentColor"
                  strokeWidth="3"
                  viewBox="0 0 24 24"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M5 13l4 4L19 7"
                  ></path>
                </svg>
                25 Tokens / week
              </li>
              <li className="inline-flex items-center  w-full mb-2 ml-5 font-semibold text-left border-solid">
                <svg
                  className="w-5 h-5 mr-2 font-semibold leading-7 text-green-600 sm:h-5 sm:w-5 md:h-6 md:w-6"
                  fill="none"
                  stroke="currentColor"
                  strokeWidth="3"
                  viewBox="0 0 24 24"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M5 13l4 4L19 7"
                  ></path>
                </svg>
                25 flashcards per set
              </li>
              <li className="inline-flex items-center  w-full mb-2 ml-5 font-semibold text-left border-solid">
                <svg
                  className="w-5 h-5 mr-2 font-semibold leading-7 text-green-600 sm:h-5 sm:w-5 md:h-6 md:w-6"
                  fill="none"
                  stroke="currentColor"
                  strokeWidth="3"
                  viewBox="0 0 24 24"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M5 13l4 4L19 7"
                  ></path>
                </svg>
                Exam AI: 10'000 char. / summary
              </li>
            </ul>
          </div>
          {/* Price 2 */}
          <div className="relative z-20 flex flex-col items-center max-w-md p-4 mx-auto my-0 bg-white border-4 border-blue-600 border-solid rounded-lg sm:p-6 md:px-8 md:py-16">
            <h3 className="m-0 text-2xl font-semibold leading-tight tracking-tight text-black border-0 border-gray-200 sm:text-3xl md:text-4xl">
              Pro
            </h3>
            <div className="flex items-end mt-6 leading-7 text-gray-900 border-0 border-gray-200">
              <p className="box-border m-0 text-6xl font-semibold leading-none border-solid">
                $3.99
              </p>
              <p
                className="box-border m-0 border-solid"
                style={{ borderImage: "initial" }}
              >
                / month
              </p>
            </div>
            <ul className="flex-1 p-0 mt-4 ml-5 leading-7 text-gray-900 border-0 border-gray-200">
              <li className="inline-flex items-center  w-full mb-2 ml-5 font-semibold text-left border-solid">
                <svg
                  className="w-5 h-5 mr-2 font-semibold leading-7 text-green-600 sm:h-5 sm:w-5 md:h-6 md:w-6"
                  fill="none"
                  stroke="currentColor"
                  strokeWidth="3"
                  viewBox="0 0 24 24"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M5 13l4 4L19 7"
                  ></path>
                </svg>
                250 Tokens / week
              </li>
              <li className="inline-flex items-center w-full mb-2 ml-5 font-semibold text-left border-solid">
                <svg
                  className="w-5 h-5 mr-2 font-semibold leading-7 text-green-600 sm:h-5 sm:w-5 md:h-6 md:w-6"
                  fill="none"
                  stroke="currentColor"
                  strokeWidth="3"
                  viewBox="0 0 24 24"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M5 13l4 4L19 7"
                  ></path>
                </svg>
                100 Flashcards per set
              </li>
              <li className="inline-flex items-center w-full mb-2 ml-5 font-semibold text-left border-solid">
                <svg
                  className="w-5 h-5 mr-2 font-semibold leading-7 text-green-600 sm:h-5 sm:w-5 md:h-6 md:w-6"
                  fill="none"
                  stroke="currentColor"
                  strokeWidth="3"
                  viewBox="0 0 24 24"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M5 13l4 4L19 7"
                  ></path>
                </svg>
                Exam AI: 25'000 char. / summary
              </li>
              <li className="inline-flex items-center w-full mb-2 ml-5 font-semibold text-left border-solid">
                <svg
                  className="w-5 h-5 mr-2 font-semibold leading-7 text-green-600 sm:h-5 sm:w-5 md:h-6 md:w-6"
                  fill="none"
                  stroke="currentColor"
                  strokeWidth="3"
                  viewBox="0 0 24 24"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M5 13l4 4L19 7"
                  ></path>
                </svg>
                AdFree
              </li>
              <li className="inline-flex items-center w-full mb-2 ml-5 font-semibold text-left border-solid">
                <svg
                  className="w-5 h-5 mr-2 font-semibold leading-7 text-green-600 sm:h-5 sm:w-5 md:h-6 md:w-6"
                  fill="none"
                  stroke="currentColor"
                  strokeWidth="3"
                  viewBox="0 0 24 24"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M5 13l4 4L19 7"
                  ></path>
                </svg>
                Unlimited flashcards sets
              </li>
            </ul>
            <button
              onClick={() => handleSubmit(process.env.REACT_APP_PRICE_ID_1)}
              className="inline-flex justify-center w-full px-4 py-3 mt-8 font-sans text-sm leading-none text-center text-white no-underline bg-blue-600 border rounded-md cursor-pointer hover:bg-blue-700 hover:border-blue-700 hover:text-white focus-within:bg-blue-700 focus-within:border-blue-700 focus-within:text-white sm:text-base md:text-lg"
            >
              Start Now
            </button>
          </div>
          {/* Price 3 */}
          <div className="relative z-10 flex flex-col items-center max-w-md p-4 mx-auto my-0 border border-solid rounded-lg lg:-ml-3 sm:my-0 sm:p-6 md:my-8 md:p-8">
            <h3 className="m-0 text-2xl font-semibold leading-tight tracking-tight text-black border-0 border-gray-200 sm:text-3xl md:text-4xl">
              Premium
            </h3>
            <div className="flex items-end mt-6 leading-7 text-gray-900 border-0 border-gray-200">
              <p className="box-border m-0 text-6xl font-semibold leading-none border-solid">
                $5.99
              </p>
              <p
                className="box-border m-0 border-solid"
                style={{ borderImage: "initial" }}
              >
                / month
              </p>
            </div>
            <ul className="flex-1 p-0 mt-4 leading-7 text-gray-900 border-0 border-gray-200">
              <li className="inline-flex items-center w-full mb-2 ml-5 font-semibold text-left border-solid">
                <svg
                  className="w-5 h-5 mr-2 font-semibold leading-7 text-green-600 sm:h-5 sm:w-5 md:h-6 md:w-6"
                  fill="none"
                  stroke="currentColor"
                  strokeWidth="3"
                  viewBox="0 0 24 24"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M5 13l4 4L19 7"
                  ></path>
                </svg>
                500 Tokens / week
              </li>
              <li className="inline-flex items-center w-full mb-2 ml-5 font-semibold text-left border-solid">
                <svg
                  className="w-5 h-5 mr-2 font-semibold leading-7 text-green-600 sm:h-5 sm:w-5 md:h-6 md:w-6"
                  fill="none"
                  stroke="currentColor"
                  strokeWidth="3"
                  viewBox="0 0 24 24"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M5 13l4 4L19 7"
                  ></path>
                </svg>
                250 Flashcards per set
              </li>
              <li className="inline-flex items-center w-full mb-2 ml-5 font-semibold text-left border-solid">
                <svg
                  className="w-5 h-5 mr-2 font-semibold leading-7 text-green-600 sm:h-5 sm:w-5 md:h-6 md:w-6"
                  fill="none"
                  stroke="currentColor"
                  strokeWidth="3"
                  viewBox="0 0 24 24"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M5 13l4 4L19 7"
                  ></path>
                </svg>
                Exam AI: 50'000 char. / summary
              </li>
              <li className="inline-flex items-center w-full mb-2 ml-5 font-semibold text-left border-solid">
                <svg
                  className="w-5 h-5 mr-2 font-semibold leading-7 text-green-600 sm:h-5 sm:w-5 md:h-6 md:w-6"
                  fill="none"
                  stroke="currentColor"
                  strokeWidth="3"
                  viewBox="0 0 24 24"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M5 13l4 4L19 7"
                  ></path>
                </svg>
                AdFree
              </li>
              <li className="inline-flex items-center w-full mb-2 ml-5 font-semibold text-left border-solid">
                <svg
                  className="w-5 h-5 mr-2 font-semibold leading-7 text-green-600 sm:h-5 sm:w-5 md:h-6 md:w-6"
                  fill="none"
                  stroke="currentColor"
                  strokeWidth="3"
                  viewBox="0 0 24 24"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M5 13l4 4L19 7"
                  ></path>
                </svg>
                Unlimited flashcard sets
              </li>
              <li className="inline-flex items-center w-full mb-2 ml-5 font-semibold text-left border-solid">
                <svg
                  className="w-5 h-5 mr-2 font-semibold leading-7 text-green-600 sm:h-5 sm:w-5 md:h-6 md:w-6"
                  fill="none"
                  stroke="currentColor"
                  strokeWidth="3"
                  viewBox="0 0 24 24"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M5 13l4 4L19 7"
                  ></path>
                </svg>
                More realistic exams
              </li>
            </ul>
            <button
              onClick={() => handleSubmit(process.env.REACT_APP_PRICE_ID_2)}
              className="inline-flex justify-center w-full px-4 py-3 mt-8 font-sans text-sm leading-none text-center text-blue-600 no-underline  border border-blue-600 rounded-md cursor-pointer hover:bg-blue-700 hover:border-blue-700 hover:text-white focus-within:bg-blue-700 focus-within:border-blue-700 focus-within:text-white sm:text-base md:text-lg"
            >
              Start Now
            </button>
          </div>
        </div>
      </div>
    </section>
  );
};
export default PricingTable;














// This was the old code for the pricing table page:

// return (
//   <section className="bg-white dark:bg-gray-900">
//     <div className="py-8 px-4 mx-auto max-w-screen-xl lg:py-16 lg:px-6">
//       <div className="mx-auto max-w-screen-md text-center mb-8 lg:mb-12">
//         <h2 className="mb-4 text-4xl tracking-tight font-extrabold text-gray-900 ">
//           Designed for business teams like yours
//         </h2>
//         <p className="mb-5 font-light text-gray-500 sm:text-xl dark:text-gray-400">
//           Here at Flowbite we focus on markets where technology, innovation,
//           and capital can unlock long-term value and drive economic growth.
//         </p>
//       </div>
//       <div className="space-y-8 lg:grid lg:grid-cols-3 sm:gap-6 xl:gap-10 lg:space-y-0">
//         <div className="flex flex-col p-6 mx-auto max-w-lg text-center text-gray-900 bg-white rounded-lg border border-gray-100 shadow dark:border-gray-600 xl:p-8 dark:bg-gray-800 ">
//           <h3 className="mb-4 text-2xl font-semibold">Free Plan</h3>
//           <p className="font-light text-gray-500 sm:text-lg dark:text-gray-400">
//             Best option for personal use & for your next project.
//           </p>
//           <div className="flex justify-center items-baseline my-8">
//             <span className="mr-2 text-5xl font-extrabold">$0</span>
//             <span className="text-gray-500 dark:text-gray-400">/month</span>
//           </div>

//           <ul role="list" className="mb-8 space-y-4 text-left">
//             <li className="flex items-center space-x-3">
//               <svg
//                 className="flex-shrink-0 w-5 h-5 text-green-500 dark:text-green-400"
//                 fill="currentColor"
//                 viewBox="0 0 20 20"
//                 xmlns="http://www.w3.org/2000/svg"
//               >
//                 <path
//                   fill-rule="evenodd"
//                   d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
//                   clip-rule="evenodd"
//                 ></path>
//               </svg>
//               <span>25 Tokens a week</span>
//             </li>
//             <li className="flex items-center space-x-3">
//               <svg
//                 className="flex-shrink-0 w-5 h-5 text-green-500 dark:text-green-400"
//                 fill="currentColor"
//                 viewBox="0 0 20 20"
//                 xmlns="http://www.w3.org/2000/svg"
//               >
//                 <path
//                   fill-rule="evenodd"
//                   d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
//                   clip-rule="evenodd"
//                 ></path>
//               </svg>
//               <span>No setup, or hidden fees</span>
//             </li>
//             <li className="flex items-center space-x-3">
//               <svg
//                 className="flex-shrink-0 w-5 h-5 text-green-500 dark:text-green-400"
//                 fill="currentColor"
//                 viewBox="0 0 20 20"
//                 xmlns="http://www.w3.org/2000/svg"
//               >
//                 <path
//                   fill-rule="evenodd"
//                   d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
//                   clip-rule="evenodd"
//                 ></path>
//               </svg>
//               <span>
//                 Team size: <span className="font-semibold">1 developer</span>
//               </span>
//             </li>
//             <li className="flex items-center space-x-3">
//               <svg
//                 className="flex-shrink-0 w-5 h-5 text-green-500 dark:text-green-400"
//                 fill="currentColor"
//                 viewBox="0 0 20 20"
//                 xmlns="http://www.w3.org/2000/svg"
//               >
//                 <path
//                   fill-rule="evenodd"
//                   d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
//                   clip-rule="evenodd"
//                 ></path>
//               </svg>
//               <span>
//                 Premium support:{" "}
//                 <span className="font-semibold">6 months</span>
//               </span>
//             </li>
//             <li className="flex items-center space-x-3">
//               <svg
//                 className="flex-shrink-0 w-5 h-5 text-green-500 dark:text-green-400"
//                 fill="currentColor"
//                 viewBox="0 0 20 20"
//                 xmlns="http://www.w3.org/2000/svg"
//               >
//                 <path
//                   fill-rule="evenodd"
//                   d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
//                   clip-rule="evenodd"
//                 ></path>
//               </svg>
//               <span>
//                 Free updates: <span className="font-semibold">6 months</span>
//               </span>
//             </li>
//           </ul>

//         </div>

//         <div className="flex flex-col p-6 mx-auto max-w-lg text-center text-gray-900 bg-white rounded-lg border border-gray-100 shadow dark:border-gray-600 xl:p-8 dark:bg-gray-800">
//           <div className="rounded bg-blue-100 text-blue-800 px-3 py-1">Most popular</div>
//           <h3 className="mb-4 text-2xl font-semibold">Pro Plan</h3>
//           <p className="font-light text-gray-500 sm:text-lg dark:text-gray-400">
//             Relevant for multiple users, extended & premium support.
//           </p>
//           <div className="flex justify-center items-baseline my-8">
//             <span className="mr-2 text-5xl font-extrabold">$2.99</span>
//             <span className="text-gray-500 dark:text-gray-400">/month</span>
//           </div>

//           <ul role="list" className="mb-8 space-y-4 text-left">
//             <li className="flex items-center space-x-3">
//               <svg
//                 className="flex-shrink-0 w-5 h-5 text-green-500 dark:text-green-400"
//                 fill="currentColor"
//                 viewBox="0 0 20 20"
//                 xmlns="http://www.w3.org/2000/svg"
//               >
//                 <path
//                   fill-rule="evenodd"
//                   d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
//                   clip-rule="evenodd"
//                 ></path>
//               </svg>
//               <span>100 Tokens a week</span>
//             </li>
//             <li className="flex items-center space-x-3">
//               <svg
//                 className="flex-shrink-0 w-5 h-5 text-green-500 dark:text-green-400"
//                 fill="currentColor"
//                 viewBox="0 0 20 20"
//                 xmlns="http://www.w3.org/2000/svg"
//               >
//                 <path
//                   fill-rule="evenodd"
//                   d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
//                   clip-rule="evenodd"
//                 ></path>
//               </svg>
//               <span>No setup, or hidden fees</span>
//             </li>
//             <li className="flex items-center space-x-3">
//               <svg
//                 className="flex-shrink-0 w-5 h-5 text-green-500 dark:text-green-400"
//                 fill="currentColor"
//                 viewBox="0 0 20 20"
//                 xmlns="http://www.w3.org/2000/svg"
//               >
//                 <path
//                   fill-rule="evenodd"
//                   d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
//                   clip-rule="evenodd"
//                 ></path>
//               </svg>
//               <span>
//                 Team size:{" "}
//                 <span className="font-semibold">10 developers</span>
//               </span>
//             </li>
//             <li className="flex items-center space-x-3">
//               <svg
//                 className="flex-shrink-0 w-5 h-5 text-green-500 dark:text-green-400"
//                 fill="currentColor"
//                 viewBox="0 0 20 20"
//                 xmlns="http://www.w3.org/2000/svg"
//               >
//                 <path
//                   fill-rule="evenodd"
//                   d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
//                   clip-rule="evenodd"
//                 ></path>
//               </svg>
//               <span>
//                 Premium support:{" "}
//                 <span className="font-semibold">24 months</span>
//               </span>
//             </li>
//             <li className="flex items-center space-x-3">
//               <svg
//                 className="flex-shrink-0 w-5 h-5 text-green-500 dark:text-green-400"
//                 fill="currentColor"
//                 viewBox="0 0 20 20"
//                 xmlns="http://www.w3.org/2000/svg"
//               >
//                 <path
//                   fill-rule="evenodd"
//                   d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
//                   clip-rule="evenodd"
//                 ></path>
//               </svg>
//               <span>
//                 Free updates: <span className="font-semibold">24 months</span>
//               </span>
//             </li>
//           </ul>
//           <button
//             onClick={() => handleSubmit(process.env.REACT_APP_PRICE_ID_1)}
//             className="text-white bg-blue-600 hover:bg-blue-700 focus:ring-4 focus:ring-blue-200 font-medium rounded-lg text-sm px-5 py-2.5 text-center   dark:focus:ring-blue-900"
//           >
//             Get started
//           </button>
//         </div>

//         <div className="flex flex-col p-6 mx-auto max-w-lg text-center text-gray-900 bg-white rounded-lg border border-gray-100 shadow dark:border-gray-600 xl:p-8 dark:bg-gray-800 ">
//           <h3 className="mb-4 text-2xl font-semibold">Premium Plan</h3>
//           <p className="font-light text-gray-500 sm:text-lg dark:text-gray-400">
//             Best for large scale uses and extended redistribution rights.
//           </p>
//           <div className="flex justify-center items-baseline my-8">
//             <span className="mr-2 text-5xl font-extrabold">$4.99</span>
//             <span className="text-gray-500 dark:text-gray-400">/month</span>
//           </div>

//           <ul role="list" className="mb-8 space-y-4 text-left">
//             <li className="flex items-center space-x-3">
//               <svg
//                 className="flex-shrink-0 w-5 h-5 text-green-500 dark:text-green-400"
//                 fill="currentColor"
//                 viewBox="0 0 20 20"
//                 xmlns="http://www.w3.org/2000/svg"
//               >
//                 <path
//                   fill-rule="evenodd"
//                   d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
//                   clip-rule="evenodd"
//                 ></path>
//               </svg>
//               <span>Individual configuration</span>
//             </li>
//             <li className="flex items-center space-x-3">
//               <svg
//                 className="flex-shrink-0 w-5 h-5 text-green-500 dark:text-green-400"
//                 fill="currentColor"
//                 viewBox="0 0 20 20"
//                 xmlns="http://www.w3.org/2000/svg"
//               >
//                 <path
//                   fill-rule="evenodd"
//                   d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
//                   clip-rule="evenodd"
//                 ></path>
//               </svg>
//               <span>No setup, or hidden fees</span>
//             </li>
//             <li className="flex items-center space-x-3">
//               <svg
//                 className="flex-shrink-0 w-5 h-5 text-green-500 dark:text-green-400"
//                 fill="currentColor"
//                 viewBox="0 0 20 20"
//                 xmlns="http://www.w3.org/2000/svg"
//               >
//                 <path
//                   fill-rule="evenodd"
//                   d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
//                   clip-rule="evenodd"
//                 ></path>
//               </svg>
//               <span>
//                 Team size:{" "}
//                 <span className="font-semibold">100+ developers</span>
//               </span>
//             </li>
//             <li className="flex items-center space-x-3">
//               <svg
//                 className="flex-shrink-0 w-5 h-5 text-green-500 dark:text-green-400"
//                 fill="currentColor"
//                 viewBox="0 0 20 20"
//                 xmlns="http://www.w3.org/2000/svg"
//               >
//                 <path
//                   fill-rule="evenodd"
//                   d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
//                   clip-rule="evenodd"
//                 ></path>
//               </svg>
//               <span>
//                 Premium support:{" "}
//                 <span className="font-semibold">36 months</span>
//               </span>
//             </li>
//             <li className="flex items-center space-x-3">
//               <svg
//                 className="flex-shrink-0 w-5 h-5 text-green-500 dark:text-green-400"
//                 fill="currentColor"
//                 viewBox="0 0 20 20"
//                 xmlns="http://www.w3.org/2000/svg"
//               >
//                 <path
//                   fill-rule="evenodd"
//                   d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
//                   clip-rule="evenodd"
//                 ></path>
//               </svg>
//               <span>
//                 Free updates: <span className="font-semibold">36 months</span>
//               </span>
//             </li>
//           </ul>
//           <button
//             onClick={() => handleSubmit(process.env.REACT_APP_PRICE_ID_2)}
//             className="text-white bg-blue-600 hover:bg-blue-700 focus:ring-4 focus:ring-blue-200 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:text-white  dark:focus:ring-blue-900"
//           >
//             Get started
//           </button>
//         </div>
//       </div>
//     </div>
//   </section>
// );
