import { useState, useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/free-solid-svg-icons";
import Logo from "../../Assets/auth_modal_google_logo.png";
import AppleAuthButton from "./../AppleAuthButton";


const AuthenticationModal = ({ children, showModal, setShowModal, homeRedirect }) => {
  const handleGoogleLogin = () => {
    window.location.href = process.env.REACT_APP_GOOGLE_LOGIN_URL;
  };

  const handleTimesClicked = () => {
    if (homeRedirect) {
      window.location.href = "/";
    }
    else if (homeRedirect === false) {
      setShowModal(false);
    }
  }

  return (
    <div>
      {showModal && (
        <div className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50">
          <div className="bg-white p-6 rounded-lg w-[480px] mx-4 sm:mx-auto relative">
            <div className="absolute top-5 right-5">
              <FontAwesomeIcon
                icon={faTimes}
                className="text-gray-600 cursor-pointer"
                onClick={handleTimesClicked}
                fontSize={25}
              />
            </div>

            <div className="bg-white px-8 pt-10 flex flex-col self-center text-center">
              <div className="w-full flex justify-center">
                <img
                  alt="Logo"
                  src="https://tailwindui.com/img/logos/workflow-mark-indigo-500.svg"
                  className="h-10 w-10 mb-5 mt-2"
                />
              </div>
              <h1 className="text-2xl font-semibold mb-3">
                Sign in to Studybuddy
              </h1>
              <h2 className="text-rg mb-20">
                Access all of Studybuddy's features for free, including Exam
                Generator, Grammar Exam Generator, Flashcard AI, and more.
              </h2>
              <div className="flex flex-col gap-3 w-full">
                <button
                  className="rounded-xl flex flex-row items-center justify-center border-2 transition hover:bg-neutral-100 border-neutral-200 w-full "
                  style={{ height: "50px" }}
                  onClick={handleGoogleLogin}
                >
                  <img
                    alt="Google Logo"
                    className="h-5 w-5 mr-3"
                    src={Logo}
                  />
                  <p className="font-semibold text-lg text-center">
                    Sign in with Google
                  </p>
                </button>
                <AppleAuthButton />
                <a
                  className="font-semibold h-[50px] text-lg flex justify-center items-center text-center rounded-xl border-2 border-neutral-200 hover:bg-neutral-100 transition"
                  href="/login/"
                >
                  Sign in with email
                </a>
                <a
                  className="font-semibold text-blue-600 text-sm h-[50px] flex justify-center items-center text-center rounded-lg hover:bg-blue-50 transition hover:text-blue-700"
                  href="/register/"
                >
                  Create account
                </a>
              </div>
            </div>
          </div>
        </div>
      )}
      <div>{children}</div>
    </div>
  );
};

export default AuthenticationModal;
