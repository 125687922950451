import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import LoadingSpinner from "../components/General/LoadingSpinner";
import FlashcardComponent from "../components/DefinitionQuestionComponents/FlashcardComponent";
import FlashcardModeProgressbar from "../components/DefinitionQuestionComponents/FlashcardModeProgressbar";
import { ReactComponent as PartyPopper} from "../Assets/party_popper.svg"
import ProgressBarCircle from "../components/DefinitionQuestionComponents/ProgressbarCircle";
import api from "./../api";


const FlashcardModePage = () => {
  const [loading, setLoading] = useState(true);
  const [flashcards, setFlashcards] = useState([]);
  const [numberOfFlashcards, setNumberOfFlashcards] = useState(0);
  const [originalFlashcards, setOriginalFlashcards] = useState([]);
  const [cardIndex, setCardIndex] = useState(0);
  const [numberOfCorrect, setNumberOfCorrect] = useState(0);
  const [numberOfIncorrect, setNumberOfIncorrect] = useState(0);
  const [showRecap, setShowRecap] = useState(false);
  const [goBackButtonDisabled, setGoBackButtonDisabled] = useState(false);
  const [numberOfGoBacks, setNumberOfGoBacks] = useState(0);


  const { uuid, type } = useParams();
  const navigate = useNavigate();



  const shuffleFlashcards = (flashcardsInput) => {
    for (let i = flashcardsInput.length - 1; i > 0; i--) {
      const j = Math.floor(Math.random() * (i + 1));
      [flashcardsInput[i], flashcardsInput[j]] = [flashcardsInput[j], flashcardsInput[i]];
    }
    return flashcardsInput;
  };



  const askTermsFormatter = (flashcardInput) => {
    return flashcardInput.map(flashcard => ({
      uuid: flashcard.uuid,
      front: flashcard.definition,
      back: flashcard.word,
      correct_incorrect: flashcard.flashcard_mode_correct_incorrect,
      starred: flashcard.starred,
    }));
  }
  const askDefinitionsFormatter = (flashcardInput) => {
    return flashcardInput.map(flashcard => ({
      uuid: flashcard.uuid,
      front: flashcard.word,
      back: flashcard.definition,
      correct_incorrect: flashcard.flashcard_mode_correct_incorrect,
      starred: flashcard.starred,
    }));
  }

  const updateFlashcardsThatAreAsked = (flashcardInput) => {
    const newUuidList = flashcardInput.map(flashcard => flashcard.uuid);
    const data = {
      flashcard_uuids: newUuidList,
      flashcard_set_uuid: uuid
    };
    api.put("/api/flashcards/update_flashcard_mode_uuid_getting_asked/", data)
    .then(() => {
      console.log("Flashcards have been updated!");
      setLoading(false);
    })
    .catch((error) => {
      console.error("There was an error!", error);
    });
  };


  const processFlashcards = (responseData, askRandomlyLocal, askDefinitionsLocal) => {
    let flashcardData = responseData;
    console.log("ask randomly local: ", askRandomlyLocal)
    if (askRandomlyLocal) {
      flashcardData = shuffleFlashcards(flashcardData);
    }
    if (askDefinitionsLocal) {
      flashcardData = askDefinitionsFormatter(flashcardData);
    } else {
      flashcardData = askTermsFormatter(flashcardData);
    }
    setFlashcards(flashcardData);
    setNumberOfFlashcards(flashcardData.length);
    return updateFlashcardsThatAreAsked(flashcardData);
  };
  

  const getAllFlashcards = (askRandomlyLocal, askDefinitionsLocal) => {
    api.get("/api/flashcards/all/"+uuid+"/")
    .then((response) => {
      setOriginalFlashcards(response.data);
      processFlashcards(response.data, askRandomlyLocal, askDefinitionsLocal);
    })
    .catch((error) => {
      console.error("Failed to fetch flashcards from set:", error);
    });
  };


  const getStarredFlashcards = (askRandomlyLocal, askDefinitionsLocal) => {
    api.get("/api/flashcards/starred/"+uuid+"/")
      .then((response) => {
        setOriginalFlashcards(response.data);
        processFlashcards(response.data, askRandomlyLocal, askDefinitionsLocal);
      })
      .catch((error) => {
        console.error("Failed to fetch flashcards from set:", error);
      });
  };


  const getIncorrectFlashcards = (askRandomlyLocal, askDefinitionsLocal) => {
    api.get("/api/flashcards/incorrect/flashcard_mode/"+uuid+"/")
      .then((response) => {
        console.log("incorrect data \n", response.data)
        setOriginalFlashcards(response.data);
        processFlashcards(response.data, askRandomlyLocal, askDefinitionsLocal);
      })
      .catch((error) => {
        console.error("Failed to fetch flashcards from set:", error);
      });
  };

  useEffect(() => {
    setLoading(true);
    const urls = [
      `/api/flashcards/flashcard_mode_settings/${uuid}/`,
      `/api/flashcards/flashcard_mode/question_index/${uuid}/`
    ]
    Promise.all(urls.map(url => api.get(url)))
    .then(responses => {
      const settingsResponse = responses[0].data;
      const questionIndex = responses[1].data.question_index;
      if (questionIndex !== 0 && questionIndex !== undefined) {
        setCardIndex(questionIndex);
        return handleSavedFlashcards(settingsResponse.ask_definitions, questionIndex);
      } else {
        setCardIndex(0);
        setNumberOfCorrect(0);
        setNumberOfIncorrect(0);
        switch (type) {
          case "all":
            getAllFlashcards(settingsResponse.ask_randomly, settingsResponse.ask_definition);
            break;
          case "starred":
            getStarredFlashcards(settingsResponse.ask_randomly, settingsResponse.ask_definition);
            break;
          case "incorrect":
            console.log("fetching incorrect flashcards")
            getIncorrectFlashcards(settingsResponse.ask_randomly, settingsResponse.ask_definition);
            break;
          case "starred-incorrect":
            getIncorrectFlashcards(settingsResponse.ask_randomly, settingsResponse.ask_definition);
            break;
          default:
            console.log("Invalid type");
            break;
        }
      }
    })
    .catch((error) => {
      console.error("There was an error!", error);
    });
  }, [uuid, type]);





  const handleSavedFlashcards = (askDefinitionsLocal, currecentCardIndex) => {
    api.get("/api/flashcards/saved_flashcard_mode_flashcards/" + uuid + "/")
    .then((response) => {
      setOriginalFlashcards(response.data);
      let flashcardData = response.data;
      let correctCount = 0;
      let incorrectCount = 0;
      flashcardData.forEach((flashcard, index) => {
        if (index < currecentCardIndex) {
          console.log("this is the index: ", index)
          console.log("this is the card index: ", currecentCardIndex)
          if (flashcard.flashcard_mode_correct_incorrect === true) {
            correctCount++;
          } else if (flashcard.flashcard_mode_correct_incorrect === false) {
            incorrectCount++;
          }
        }
      });
      console.log("correct count: ", correctCount)
      console.log("incorrect count: ", incorrectCount)
      setNumberOfCorrect(correctCount);
      setNumberOfIncorrect(incorrectCount);
      if (askDefinitionsLocal) {
        flashcardData = askDefinitionsFormatter(flashcardData);
      } else {
        flashcardData = askTermsFormatter(flashcardData);
      }
      setFlashcards(flashcardData);
      setNumberOfFlashcards(response.data.length);
      setLoading(false);
      console.log("ready")
      })
    .catch((error) => {
      console.error("Failed to fetch flashcards from set:", error);
    });
  };


  useEffect(() => {
    const handleSettingsUpdate = () => {
      setLoading(true);
      api.get("/api/flashcards/flashcard_mode_settings/" + uuid + "/")
      .then((settingsResponseData) => {
        const settingsResponse = settingsResponseData.data;
        const newUuidList = flashcards.map(flashcard => flashcard.uuid);


        api.post("/api/flashcards/get_flashcards_in_order/", {uuids: newUuidList})
        .then((response) => {

          let updatedFlashcards = response.data;
          if (settingsResponse.ask_randomly && !settingsResponse.have_been_shuffled) {
            updatedFlashcards = shuffleFlashcards(updatedFlashcards);
          } else if (!settingsResponse.ask_randomly && settingsResponse.have_been_shuffled) {
            updatedFlashcards = response.data;
          } else {
            updatedFlashcards = originalFlashcards;
          }

          setOriginalFlashcards(updatedFlashcards);

          if (settingsResponse.ask_definition) {
            updatedFlashcards = askDefinitionsFormatter(updatedFlashcards);
          } else {
            updatedFlashcards = askTermsFormatter(updatedFlashcards);
          }
          setFlashcards(updatedFlashcards);
          updateFlashcardsThatAreAsked(updatedFlashcards);
        })
        .catch((error) => {
          console.error("Failed to fetch flashcards from set:", error);
        });
      })
      .catch((error) => {
        console.error("Failed to fetch flashcards from set:", error);
      });
    };
  
    window.addEventListener("modalClosedAndSettingsUpdated", handleSettingsUpdate);

    return () => {
      window.removeEventListener("modalClosedAndSettingsUpdated", handleSettingsUpdate);
    };
  }, [flashcards]);



  const handleReviewTerms = () => {
    api.put("api/flashcards/update_flashcard_mode_index/", {uuid:uuid, index:0, question_type: "incorrect"})
    .then((response) => {
      setCardIndex(0);
      setNumberOfCorrect(0);
      setNumberOfIncorrect(0);
      setNumberOfGoBacks(0);
    })
    .catch((error) => {
      console.error("There was an error!", error);
    })
    .finally(() => {
      navigate(`/flashcard/mode/flashcard/${uuid}/incorrect/`)
      window.location.reload();
      setShowRecap(false);
    });
  }




  //TODO: this is the old code
  // const handleReviewTerms = () => {
  //   api.put("/api/flashcards/update_flashcard_mode_index/", {uuid:uuid, index:0, question_type: "incorrect"})
  //   .then((response) => {
  //     navigate(`/flashcard/mode/flashcard/${uuid}/incorrect/`)
  //     api.get("/api/flashcards/incorrect/flashcard_mode/"+uuid+"/")
  //     .then((response) => {
 
  //       let flashcardData = response.data.incorrect_flashcards;
  //       setOriginalFlashcards(response.data.incorrect_flashcards);
  //       setNumberOfCorrect(Number(response.data.number_of_flashcards_in_set) - response.data.incorrect_flashcards.length);
  //       if (sessionStorage.getItem("askType") === "term") {
  //         flashcardData = askTermsFormatter(flashcardData);
  //       }
  //       else {
  //         flashcardData = askDefinitionsFormatter(flashcardData);
  //       }

  //       if (sessionStorage.getItem("askRandomly") === "true") {
  //         flashcardData = shuffleFlashcards(flashcardData);
  //       }
  //       setFlashcards(flashcardData);
  //       setNumberOfFlashcards(response.data.incorrect_flashcards.length);
  //       setCardIndex(0);
  //       setNumberOfCorrect(0);
  //       setNumberOfIncorrect(0);
  //     })
  //     .catch((error) => {
  //       console.error("Failed to fetch flashcards from set:", error);
  //     });

  //   })
  //   .catch((error) => {
  //     console.error("There was an error!", error);
  //   })
  //   .finally(() => {
  //     setShowRecap(false);
  //   });
  // }





  const handleRedirect = () => {
    api.put("/api/flashcards/update_flashcard_mode_index/", {uuid:uuid, index:0, question_type: "all"})
    .then(() => {
      navigate(`/flashcard/preview/${uuid}/`)
    })
    .catch((error) => {
      console.error("There was an error!", error);
    });
  };

  const handleHomeButtonClicked = () => {
    if (showRecap && numberOfIncorrect === 0) {
      return handleRedirect();
    } else {
      return navigate(`/flashcard/preview/${uuid}/`);
    }
  }




  if (loading) {
    return <LoadingSpinner />;
  }

  return (
    <div className="min-h-screen " style={{ backgroundColor: "#F6F7FB" }}>
      <FlashcardModeProgressbar numberOfQuestions={numberOfFlashcards} index={showRecap ? numberOfFlashcards : cardIndex} flashcards={flashcards} showSettings={!showRecap} handleNavigateBack={handleHomeButtonClicked} />
      {showRecap ? (
        <div className="mt-8">
          <div className="flex flex-row justify-center items-center">
            <div className="w-7/12 md:w-4/12">
              <h1 className="sm:text-lg md:text-xl lg:text-3xl font-bold">You're doing great! Keep focusing on your tough terms.</h1>
            </div>
            <div className="w-3/12 md:w-2/12 flex flex-col items-end">
              <PartyPopper className="w-20 h-20 md:w-28 md:h-28 lg:w-32 lg:h-32" />
            </div>
          </div>
          <div className="flex flex-col justify-center items-center mt-6 mb-8">
            <ProgressBarCircle percent={Math.round((numberOfCorrect/numberOfFlashcards)*100)} />
          </div>
          <div className="flex flex-row justify-center items-center">
            <div className="w-10/12 md:w-6/12">
              <div className="bg-green-100 border-1 border-green-400 rounded-full">
                <div className="flex flex-row justify-between px-4 py-2">
                  <p className="justify-start font-semibold text-green-500 text-sm sm:text-md md:text-lg">Know</p>
                  <p className="justify-end font-semibold text-green-500 text-sm sm:text-md md:text-lg">{numberOfCorrect}</p>
                </div>
              </div>
            </div>
          </div>

          <div className="flex flex-row justify-center items-center mt-3">
            <div className="w-10/12 md:w-6/12">
              <div className="bg-flashcard-orange-light border-1 border-flashcard-orange rounded-full">
                <div className="flex flex-row justify-between px-4 py-2">
                  <p className="justify-start font-semibold text-flashcard-orange text-sm sm:text-md md:text-lg">Still learning</p>
                  <p className="justify-end font-semibold text-flashcard-orange text-sm sm:text-md md:text-lg">{numberOfFlashcards-numberOfCorrect}</p>
                </div>
              </div>
            </div>
          </div>
          {numberOfIncorrect === 0 ? (
            <div className="mt-12 flex flex-col items-center">
              <button className="rounded-lg transition w-10/12 md:w-6/12 bg-blue-600 px-3.5 py-2.5 text-sm md:text-md font-semibold text-white shadow hover:bg-blue-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-blue-600 disabled:opacity-60" onClick={handleRedirect}>
                Go to flashcard preview
              </button>
            </div>
          ): (
            <div className="mt-12 flex flex-col items-center">
              <button className="rounded-lg transition w-10/12 md:w-6/12 bg-blue-600 px-3.5 py-2.5 text-sm md:text-md font-semibold text-white shadow hover:bg-blue-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-blue-600 disabled:opacity-60" onClick={handleReviewTerms}>
                Review {numberOfFlashcards-numberOfCorrect} terms
              </button>
            </div>
          )}

        </div>
      ) : (
        <div className="flex flex-col justify-center items-center mt-16">
          <div className="w-full flex justify-center mb-6">
            <div className="flex justify-between items-center w-[325px] sm:w-[585px] md:w-[732px] lg:w-[975px]">
              <div className="flex items-center gap-2"> 
                <div className="border-1 border-flashcard-orange px-3 rounded-2xl bg-flashcard-orange-light">
                  <p className="text-flashcard-orange text-lg font-bold">{numberOfIncorrect}</p>
                </div>
                <p className="text-flashcard-orange font-bold text-sm hidden sm:block">Still learning</p> 
              </div>
              <div className="flex items-center gap-2">
                <p className="text-green-500 font-bold text-sm hidden sm:block">Know</p> 
                <div className="border-1 border-green-400 px-3 rounded-2xl bg-green-100">
                  <p className="text-green-600 text-lg font-bold">{numberOfCorrect}</p>
                </div>
              </div>
            </div>
          </div>
          <FlashcardComponent cards={flashcards} askMode={"correction"} cardIndex={cardIndex} setCardIndex={setCardIndex} setNumberOfCorrect={setNumberOfCorrect} setNumberOfIncorrect={setNumberOfIncorrect} setShowRecap={setShowRecap} flashcardSetUuid={uuid} questionType={type} />
        </div>
      )}

    </div>
  );
  
  
  
  
  
}
export default FlashcardModePage;