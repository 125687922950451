import { useState, useEffect } from "react";
import AuthenticationModal from "./OtherQuestionComponents/AuthenticationModal";
import { useAuth } from "../router/isAuthenticated";

const AuthGuard = ({ children }) => {
  const { isAuthenticated } = useAuth();
  const [showModal, setShowModal] = useState(!isAuthenticated);
  
  
  useEffect(() => {
    setShowModal(!isAuthenticated);
  }, [isAuthenticated]); 

  return (
    <AuthenticationModal children={children} showModal={showModal} setShowModal={setShowModal} homeRedirect={true} />
  );
};

export default AuthGuard;
