import { useEffect, useState, useRef } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrash, faFileLines, faImage } from "@fortawesome/free-solid-svg-icons";
import LoadingSpinner from "../components/General/LoadingSpinner";
import ConfirmDeletionModal from "../components/ConfirmDeletionModal";
import UploadModal from "../components/UploadModal";
import { ReactComponent as TextIcon } from "../Assets/SVG/TextIcon.svg";
import UploadDocumentModal from "../components/Modals/UploadDocumentModal";
import UploadTextModal from "../components/Modals/UploadTextModal";
import UploadImageModal from "../components/Modals/UploadImageModal";
import LimitModal from "../components/Modals/LimitModal";

import api from "../api";

const FlashcardInput = () => {
  const [words, setWords] = useState([""]);
  const [definitions, setDefinitions] = useState([""]);
  const [title, setTitle] = useState("");
  const [showMaxFieldsMessage, setShowMaxFieldsMessage] = useState(false);
  const [loading, setLoading] = useState(true);
  const [wordLimit, setWordLimit] = useState(100);
  const [errors, setErrors] = useState({"definitions": [], "title": false, "words": []});
  const [showLimitModal, setShowLimitModal] = useState(false);
  const [limitReason, setLimitReason] = useState("");



  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [showUplaodModal, setShowUploadModal] = useState(true);

  const [showUploadDocumentModal, setShowUploadDocumentModal] = useState(false);
  const [showUploadTextModal, setShowUploadTextModal] = useState(false);
  const [showUploadImageModal, setShowUploadImageModal] = useState(false);

  const [loadingGeneratingFlashcards, setLoadingGeneratingFlashcards] = useState(false);

  const [savingDraft, setSavingDraft] = useState(false);
  const [subscriptionPlan, setSubscriptionPlan] = useState("basic");

  const saveAbortController = useRef(null);
  const originalData = useRef({ words: [""], definitions: [""], title: "" });


  const navigate = useNavigate();
  const { uuid } = useParams();
  console.log("uuid: ", uuid);


  const titleInputRef = useRef(null);




  useEffect(() => {
    const urls = ["/api/subscription/"];
    if (uuid) {
      urls.push(`/api/flashcards/get_flashcard_draft/${uuid}/`);
    } 
    Promise.all(urls.map(url => api.get(url)))
    .then((responses) => {
      const subscriptionData = responses[0].data;
      if (subscriptionData.plan === "max") {
        setWordLimit(5000);
        setSubscriptionPlan("max");
      } else {
        setWordLimit(100);
        setSubscriptionPlan("basic");
      }

      if (uuid && responses.length > 1) {
        const draftData = responses[1].data;
        setTitle(draftData.title);

        const loadedWords = draftData.words.length > 0 ? draftData.words : [""];
        const loadedDefinitions = draftData.definitions.length > 0 ? draftData.definitions : [""];
        

        setWords(loadedWords);
        setDefinitions(loadedDefinitions);

        originalData.current = {
          words: loadedWords,
          definitions: loadedDefinitions,
          title: draftData.title,
        };
      } 
      setLoading(false);
    })
    .catch((error) => {
      console.error("Error getting subscription data: ", error);
      setLoading(false);
    });
  }, [uuid])




  const hasChanges = (currentData) => {
    const { words, definitions, title } = originalData.current;
    return (
      JSON.stringify(currentData.words) !== JSON.stringify(words) ||
      JSON.stringify(currentData.definitions) !== JSON.stringify(definitions) ||
      currentData.title !== title
    );
  }





  const saveDraft = async (data) => {
    setSavingDraft(true);

    if (saveAbortController.current) {
      saveAbortController.current.abort(); // Cancel the previous request
    }

    saveAbortController.current = new AbortController();

    try {
      const config = { signal: saveAbortController.current.signal };
      if (uuid) {
        await api.put(`/api/flashcards/check_flashcard_draft_update/${uuid}/`, data, config);
      } else {
        const response = await api.post("/api/flashcards/save_flashcard_draft/", data, config);
        navigate("/flashcard/input/" + response.data.new_uuid + "/");
      }
      console.log("Draft saved successfully");

      originalData.current = { ...data };
    } catch (error) {
      if (error.name === "AbortError") {
        console.log("Save cancelled:", error.message);
      } else {
        console.error("Error saving draft:", error);
      }
    } finally {
      setSavingDraft(false);
      saveAbortController.current = null;
    }
  };


  const handleBeforeUnload = (event) => {
    const data = {
      words,
      definitions,
      title,
    };

    if (hasChanges(data)) {
      saveDraft(data);
    }
    event.preventDefault();
    event.returnValue = '';
  };

  useEffect(() => {
    window.addEventListener('beforeunload', handleBeforeUnload);

    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload);
    };
  }, [words, definitions, title]);




  useEffect(() => {
    const handleSave = () => {
      const data = {
        words: words,
        definitions: definitions,
        title: title,
      }
      if (hasChanges(data)) {
        saveDraft(data);
      }
    };

    const saveTimer = setTimeout(handleSave, 1000);

    return () => clearTimeout(saveTimer);
  }, [definitions, words, title]);







  const handleInputChange = (index, type, value) => {
    setWords((prevWords) => {
      setDefinitions((prevDefinitions) => {

        const newWords = [...prevWords];
        const newDefinitions = [...prevDefinitions];


        if (type === "word") {
          newWords[index] = value;
        } else if (type === "definition") {
          newDefinitions[index] = value;
        }

        const isLastIndex = index === newWords.length - 1;
        const isWordNotEmpty = newWords[index]?.trim().length > 0;
        const isDefinitionNotEmpty = newDefinitions[index]?.trim().length > 0;

        if (
          isLastIndex &&
          isWordNotEmpty &&
          isDefinitionNotEmpty &&
          newWords.length < wordLimit
        ) {
          newWords.push(""); 
          newDefinitions.push("");
        }

        setWords(newWords);
        setDefinitions(newDefinitions);

        return newDefinitions;
      });

      return prevWords;
    });
  };


  useEffect(() => {
    const lastWordIndex = wordLimit - 1;

    const wordAtLimit = words[lastWordIndex] && words[lastWordIndex].trim() !== "";
    const definitionAtLimit = definitions[lastWordIndex] && definitions[lastWordIndex].trim() !== "";

    if (wordAtLimit && definitionAtLimit) {
      if (!showMaxFieldsMessage) {
        setLimitReason("flashcards");
        setShowLimitModal(true);
        setShowMaxFieldsMessage(true);
      }
    } else if (showMaxFieldsMessage) {
      setShowLimitModal(false);
      setShowMaxFieldsMessage(false);
    }
  }, [words, definitions, wordLimit, showMaxFieldsMessage]);



  

  const handleDeleteField = (indexToRemove) => {
    if (words.length > 1) {
      const newWords = words.filter((_, index) => index !== indexToRemove);
      setWords(newWords);
      if (showMaxFieldsMessage && words.length < wordLimit) {
        setShowMaxFieldsMessage(false);
      }
    }
    if (definitions.length > 1) {
      const newDefinitions = definitions.filter((_, index) => index !== indexToRemove);
      setDefinitions(newDefinitions);
      if (showMaxFieldsMessage && definitions.length < wordLimit) {
        setShowMaxFieldsMessage(false);
      }
    }
  };




  const handleSave = () => {
    setLoading(true);
    const nonEmptyWords = words.filter((word) => word.trim() !== "");
    const nonEmptyDefinitions = definitions.filter((definition) => definition.trim() !== "");
    
    const newErrors = {
      title: title.trim() === "",
      words: [],
      definitions: [],
    };

    if (newErrors.title) {
      titleInputRef.current.focus();
    };
    

    const lastIndex = words.length - 1;
    words.forEach((word, index) => {
      if (word.trim() === "" && !(index === lastIndex && word.trim() === "" && definitions[lastIndex].trim() === "")) {
        newErrors.words.push(index + 1);
      }
      if ((!definitions[index] || definitions[index].trim() === "") && !(index === lastIndex && word.trim() === "" && definitions[lastIndex].trim() === "")) {
        newErrors.definitions.push(index + 1);
      }
    });

    setErrors(newErrors);

    if (newErrors.title || newErrors.words.length > 0 || newErrors.definitions.length > 0) {
      setLoading(false);
      return;
    }

    const flashcards = nonEmptyWords.map((word, index) => ({
      word,
      definition: nonEmptyDefinitions[index] || "",
      position: index + 1,
    }));

    const data = {
      title: title,
      flashcards: flashcards,
    };


    api.post("/api/new_flashcard_set/", data)
      .then((response) => {

        if (uuid) {
          api.delete("/api/flashcards/delete_flashcard_draft/" + uuid + "/")
            .then(() => {
              console.log("Draft deleted successfully");
              navigate("/flashcard/preview/" + response.data.uuid + "/");
              setLoading(false);
            })
            .catch((error) => {
              console.error("Error deleting draft:", error);
            });
        } else {
          navigate("/flashcard/preview/" + response.data.uuid + "/");
          setLoading(false);
        }

      })
      .catch((error) => {
        console.error(error);
      });
  };




  const cleanArray = (array) => array.filter(item => item.trim() !== ""); 


  const isNotEmpty = () => {
    const cleanWords = cleanArray(words);
    const cleanDefinitions = cleanArray(definitions);
    return cleanWords.length > 0 && cleanDefinitions.length > 0 && title.trim() !== "";
  };



  const handleClearAll = () => {
    setWords([""]);
    setDefinitions([""]);
    setTitle("");
  }

  const nonEmptyPairCount = () => {
    return words.reduce((count, word, index) => {
      const definition = definitions[index];
      if (word.trim() !== "" && definition && definition.trim() !== "") {
        count++;
      }
      return count;
    }, 0);
  };

  const processGeneratedFlashcards = (data) => {
    const newWords = data.map(item => item.word.trim());
    const newDefinitions = data.map(item => item.definition.trim());

    setWords(prevWords => {
      const prevDefinitions = definitions;
      let filteredWords = [];
      let filteredDefinitions = [];

      prevWords.forEach((word, index) => {
          const trimmedWord = word.trim();
          const trimmedDefinition = prevDefinitions[index] ? prevDefinitions[index].trim() : "";
          if (trimmedWord !== "" || trimmedDefinition !== "") {
              filteredWords.push(trimmedWord);
              filteredDefinitions.push(trimmedDefinition);
          }
      });

      let spaceAvailable = wordLimit - filteredWords.length;
      if (spaceAvailable <= 0) {
        setShowUploadDocumentModal(false);
        setShowUploadTextModal(false);
        setShowUploadImageModal(false);
        setLoadingGeneratingFlashcards(false);
        setShowLimitModal(true);
        return filteredWords;
      }

      const addableWords = newWords.slice(0, spaceAvailable);
      const addableDefinitions = newDefinitions.slice(0, spaceAvailable);

      filteredWords = [...filteredWords, ...addableWords];
      filteredDefinitions = [...filteredDefinitions, ...addableDefinitions];

      if (filteredWords.length >= wordLimit) {
        setShowLimitModal(true);
      }


      if (filteredWords.length < wordLimit) {
        filteredWords.push("");
        filteredDefinitions.push("");
      }
      setLoadingGeneratingFlashcards(false);
      setShowUploadDocumentModal(false);
      setShowUploadTextModal(false);
      setShowUploadImageModal(false);
      setDefinitions(filteredDefinitions);

      return filteredWords;
    });
  };




  // This is so that the cursor does not work when a modal is open

  useEffect(() => {
    const showModal = showUploadDocumentModal || showUploadTextModal || showUploadImageModal;

    if (showModal) {
      document.body.style.overflow = 'hidden'; 
      document.body.style.overflow = 'unset';
    }

    return () => {
      document.body.style.overflow = 'unset';
    };
  }, [showUploadDocumentModal, showUploadTextModal, showUploadImageModal]); 








  if (loading) {
    return (
      <div style={{padding: "0 13vw"}}>
        <LoadingSpinner />
      </div>
    );
  }


  return (
    <div style={{ backgroundColor: "#F6F7FB", minHeight: "100vh", paddingTop:"2rem"}} className="min-h-screen">

      <ConfirmDeletionModal showModal={showDeleteModal} setShowModal={setShowDeleteModal} handleDelete={handleClearAll} header={"Clear all fields?"} text={"Are you sure you want to clear all fields? This action cannot be undone."} buttonLabel={"Clear all"} />
      
      <UploadModal showModal={showUplaodModal} setShowModal={setShowUploadModal} />

      <UploadDocumentModal showModal={showUploadDocumentModal} setShowModal={setShowUploadDocumentModal} type={"flashcards"} loading={loadingGeneratingFlashcards} setLoading={setLoadingGeneratingFlashcards} processData={processGeneratedFlashcards} setLimitReason={setLimitReason} setShowLimitModal={setShowLimitModal} subscriptionPlan={subscriptionPlan} />

      <UploadTextModal showModal={showUploadTextModal} setShowModal={setShowUploadTextModal} type={"flashcards"} loading={loadingGeneratingFlashcards} setLoading={setLoadingGeneratingFlashcards} processData={processGeneratedFlashcards} setLimitReason={setLimitReason} setShowLimitModal={setShowLimitModal} subscriptionPlan={subscriptionPlan} />

      <UploadImageModal showModal={showUploadImageModal} setShowModal={setShowUploadImageModal} type={"flashcards"} loading={loadingGeneratingFlashcards} setLoading={setLoadingGeneratingFlashcards} processData={processGeneratedFlashcards} setLimitReason={setLimitReason} setShowLimitModal={setShowLimitModal} />

      <LimitModal showModal={showLimitModal} setShowModal={setShowLimitModal} limitReason={limitReason} />



      <div style={{ padding: "0 13vw" }}>

        <h1 className="font-semibold text-lg md:text-2xl lg:text-3xl mb-4">Create a flashcard set</h1>

        <div className="flex flex-col justify-start w-full bg-white rounded-lg shadow-lg">
          <input
            id="title" 
            ref={titleInputRef}
            type="text" 
            className= {`rounded-md text-lg font-medium ${errors.title ? "border-2 border-red-500" : ""} py-2.5 px-3 focus:outline-none`}
            placeholder="Enter title"
            onChange={(event) => setTitle(event.target.value)}
            value={title}
          />
            
        </div>
        {errors.title && <p className="text-red-500 text-sm font-semibold mt-2">Title is required</p>}




        <div className="flex-row items-start justify-start mt-10">
          <div className="flex flex-row items-center ">
            <h1 className="text-3xl font-semibold">Generate flashcards with AI</h1>

            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 24 24"
              fill="currentColor"
              aria-hidden="true"
              className="inline-block w-8 h-8 text-purple-400 ml-3"
            >
              <path
                fillRule="evenodd"
                d="M9 4.5a.75.75 0 01.721.544l.813 2.846a3.75 3.75 0 002.576 2.576l2.846.813a.75.75 0 010 1.442l-2.846.813a3.75 3.75 0 00-2.576 2.576l-.813 2.846a.75.75 0 01-1.442 0l-.813-2.846a3.75 3.75 0 00-2.576-2.576l-2.846-.813a.75.75 0 010-1.442l2.846-.813A3.75 3.75 0 007.466 7.89l.813-2.846A.75.75 0 019 4.5zM18 1.5a.75.75 0 01.728.568l.258 1.036c.236.94.97 1.674 1.91 1.91l1.036.258a.75.75 0 010 1.456l-1.036.258c-.94.236-1.674.97-1.91 1.91l-.258 1.036a.75.75 0 01-1.456 0l-.258-1.036a2.625 2.625 0 00-1.91-1.91l-1.036-.258a.75.75 0 010-1.456l1.036-.258a2.625 2.625 0 001.91-1.91l.258-1.036A.75.75 0 0118 1.5zM16.5 15a.75.75 0 01.712.513l.394 1.183c.15.447.5.799.948.948l1.183.395a.75.75 0 010 1.422l-1.183.395c-.447.15-.799.5-.948.948l-.395 1.183a.75.75 0 01-1.422 0l-.395-1.183a1.5 1.5 0 00-.948-.948l-1.183-.395a.75.75 0 010-1.422l1.183-.395c.447-.15.799-.5.948-.948l.395-1.183A.75.75 0 0116.5 15z"
                clipRule="evenodd"
              ></path>
            </svg>

          </div>
          <p className="text-xl font-medium mt-2 mb-4">Upload your materials to receive custom-made flashcards tailored to your content.</p>

          <div className="flex flex-row items-start justify-start gap-3">
            <button 
              className="flex items-center px-4 py-2 rounded-full font-semibold border-blue-500 border-2 text-blue-600 bg-blue-100 hover:bg-blue-200 hover:border-blue-600" 
              onClick={() => setShowUploadDocumentModal(true)}
            >
              <FontAwesomeIcon icon={faFileLines} className="mr-2" />
              Upload Document
            </button>

            <button 
              className="flex items-center px-4 py-2 rounded-full font-semibold bg-blue-100 border-blue-500 border-2 text-blue-600 hover:bg-blue-200 hover:border-blue-600"
              onClick={() => setShowUploadTextModal(true)}
            >
              <TextIcon className="mr-2 w-4 h-4 " />
              Upload Text
            </button>
            <button 
              className="flex items-center px-4 py-2 rounded-full font-semibold bg-blue-100 border-blue-500 border-2 text-blue-600 hover:bg-blue-200 hover:border-blue-600"
              onClick={() => setShowUploadImageModal(true)}  
            >
              <FontAwesomeIcon icon={faImage} className="mr-2" />
              Upload Images
            </button>
          </div>


        </div>



          
        <div className="mb-4 mt-14 flex justify-between">
          <span className="font-semibold text-lg md:text-xl lg:text-2xl">Terms in this set ({nonEmptyPairCount()})</span>
          <button className="text-red-600 font-semibold hover:bg-red-100 rounded-lg py-2 px-3"
            onClick={handleClearAll}
            style={{ display: words.length > 0 && definitions.length > 0 ? '' : 'none' }}
            disabled={words.length === 0 && definitions.length === 0}
          >
            Clear all
          </button>
        </div>

        <div>
          {words.map((word, index) => (
            <div className="bg-white rounded-2xl shadow px-4 pt-4 pb-3 mb-10">
              <div className="grid grid-cols-1 md:grid-cols-2 gap-3 md:gap-5 ">
                <div>
                  <label className="mb-3 font-medium text-sm text-neutral-700 block">Term</label>
                  <textarea 
                    className={`p-2.5 ${errors[`word${index + 1}`] ? "border-2 border-red-500": "border border-gray-300"}  rounded-lg focus:none w-full bg-gray-50 resize-none`}
                    rows="2"
                    value={word}
                    onChange={(event) => handleInputChange(index, 'word', event.target.value)}
                  />
                  {errors[`word${index + 1}`] && <p className="text-red-500 text-sm font-semibold mt-2">Word is required</p>}
                </div>
                <div>
                  <label className="mb-3 font-medium text-sm text-neutral-700 block">Definition</label>
                  <textarea 
                    className={`p-2.5 ${errors[`definition${index + 1}`] ? "border-2 border-red-500": "border border-gray-300"}  rounded-lg focus:none w-full bg-gray-50 resize-none`}
                    rows="2"
                    value={definitions[index]}
                    onChange={(event) => handleInputChange(index, 'definition', event.target.value)}
                  />
                  {errors[`definition${index + 1}`] && <p className="text-red-500 text-sm font-semibold mt-2">Definition is required</p>}
                </div>
              </div>
              <div className="flex justify-end mt-3" style={{ display: word.trim() !== "" && definitions[index].trim() !== "" ? '' : 'none' }}>
                <button className="text-red-600 font-semibold hover:bg-red-100 rounded-lg py-2 px-3" onClick={() => handleDeleteField(index)} tabIndex={-1}>
                  <FontAwesomeIcon icon={faTrash} style={{ fontSize: "16px" }} />
                  <span className="ml-2">Delete</span>
                </button>
              </div>
            </div>
          ))}
        </div>


        <div className="flex justify-center">
          <div className="rounded-lg bg-white mt-3 shadow-lg">
            <button 
              className="border-2 border-blue-500 py-3 px-10 rounded-lg font-medium text-lg text-blue-600 transition-colors duration-300 ease-in-out
                        hover:bg-blue-200 hover:border-blue-600 hover:shadow-md 
                        active:bg-blue-300 active:border-blue-700 active:shadow-inner
                        focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-opacity-50
                        disabled:bg-gray-200 disabled:border-gray-300 disabled:text-gray-500 disabled:cursor-not-allowed"
              onClick={handleSave}
              disabled={!isNotEmpty()}
            >
              Create flashcard set
            </button>
          </div>
        </div>
        

      
      </div>
      <div className="h-20" />
    </div>

  );
};

export default FlashcardInput;