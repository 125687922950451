import { useState, useEffect } from "react"

const SummaryInputTextarea = ({ summary, setSummary, submitted, maxCharacters}) => {
  const [summaryLenght, setSummaryLength] = useState(0);
  const [error, setError] = useState(false);
  useEffect(() => {
    if (submitted && summary.trim() === "") {
      setError(true);
    }
  }, [submitted, summary]);

  const handleSummaryChange = (event) => {
    const { value } = event.target;
    setSummaryLength(value.length);
    if (summaryLenght <= maxCharacters) {
      setSummary(value);
    }
  };
  return (
    <div className="my-6">
      <label className="block font-sans font-medium">Your summary</label>
      <textarea 
        id="summaryInput"
        className={`my-3 h-96 p-2.5 w-full ${error ? "border-2 border-red-500" : "border-1 border-grey-300"} rounded-md focus:outline-none focus:border-blue-500 resize-none`} placeholder="Paste your summary"
        onChange={handleSummaryChange}
        value={summary}
        maxLength={40000}
        />
      <p id="charCount" className="text-sm text-gray-500">{summaryLenght} / {maxCharacters} characters</p>
      {error && <p className="text-red-500 text-sm">Summary is required</p>}
  </div>
  )
}
export default SummaryInputTextarea