import { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import api from "./../api"

const ChangePasswordPage = () => {
  const [oldPassword, setOldPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [confirmNewPassword, setConfirmNewPassword] = useState("");
  const [oldPasswordError, setOldPasswordError] = useState(false);
  const [oldPasswordErrorMessage, setOldPasswordErrorMessage] = useState("");
  const [newPasswordError, setNewPasswordError] = useState(false);
  const [newPasswordErrorMessage, setNewPasswordErrorMessage] = useState("");
  const [confirmNewPasswordError, setConfirmNewPasswordError] = useState(false);
  const [confirmNewPasswordErrorMessage, setConfirmNewPasswordErrorMessage] = useState("");
  const [nonFieldError, setNonFieldError] = useState("");
  const navigate = useNavigate();

  const handleButtonClicked = (event) => {
    event.preventDefault();
    const data = {
      old_password: oldPassword,
      new_password1: newPassword,
      new_password2: confirmNewPassword,
    };
    console.log("Data: ", data);
    api.post("/api/auth/password/change/", data)
      .then((response) => {
        console.log(response);
        navigate("/account");
      })
      .catch((error) => {
        console.error(error);
        if (error.response.data.old_password) {
          setOldPasswordError(true);
          setOldPasswordErrorMessage(error.response.data.old_password);
        }
        if (error.response.data.new_password1) {
          setNewPasswordError(true);
          setNewPasswordErrorMessage(error.response.data.new_password1);
        }
        if (error.response.data.new_password2) {
          setConfirmNewPasswordError(true);
          setConfirmNewPasswordErrorMessage(error.response.data.new_password2);
        }
        if (error.response.data.non_field_errors) {
          setOldPasswordError(true);
          setNewPasswordError(true);
          setConfirmNewPasswordError(true);
          setNonFieldError(error.response.data.non_field_errors);
        }
      });
  }

  return (
    <section className="bg-gray-50 dark:bg-gray-900">
      <div className="flex flex-col items-center justify-center px-6 py-8 mx-auto md:h-screen lg:py-0">
        <Link to="/account" className="absolute left-6 top-6 dark:text-white">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            className="h-6 w-6 text-gray-900 dark:text-white" // Adjusted color here
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth={2}
              d="M15 19l-7-7 7-7"
            />
          </svg>
        </Link>
        <p className="flex items-center mb-6 text-2xl font-semibold text-gray-900 dark:text-white">
          <img
            className="w-8 h-8 mr-2"
            src="https://tailwindui.com/img/logos/workflow-mark-indigo-500.svg"
            alt="logo"
          />
          Study buddy
        </p>
        <div className="w-full bg-white rounded-lg shadow dark:border md:mt-0 sm:max-w-md xl:p-0 dark:bg-gray-800 dark:border-gray-700">
          <div className="p-6 space-y-4 md:space-y-6 sm:p-8">
            <h1 className="text-xl font-bold leading-tight tracking-tight text-gray-900 md:text-2xl dark:text-black">
              Change your password
            </h1>
            <form className="space-y-4 md:space-y-6" onSubmit={event => handleButtonClicked(event)}>
              <div>
                <label
                  htmlFor="oldPassword"
                  className="block mb-2 text-sm font-medium text-gray-900 dark:text-black"
                >
                  Old Password
                </label>
                <input
                  type="password"
                  name="oldPassword"
                  value={oldPassword}
                  onChange={(e) => setOldPassword(e.target.value)}
                  autoComplete="new-password"
                  id="oldPassword"
                  className={`bg-gray-50 ${oldPasswordError ? 'border-2 border-red-500' : 'border border-gray-300'} text-gray-900 sm:text-sm rounded-lg focus:ring-blue-600 focus:border-blue-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500`}
                  placeholder="••••••••"
                  required
                />
                {oldPasswordErrorMessage && <span className="text-red-500 text-sm">{oldPasswordErrorMessage}</span>}
              </div>
              <div>
                <label
                  htmlFor="newPassword"
                  className="block mb-2 text-sm font-medium text-gray-900 dark:text-black"
                >
                  New Password
                </label>
                <input
                  type="password"
                  name="newPassword"
                  value={newPassword}
                  onChange={(e) => setNewPassword(e.target.value)}
                  autoComplete="new-password"
                  id="newPassword"
                  className={`bg-gray-50 ${newPasswordError ? 'border-2 border-red-500' : 'border border-gray-300'} text-gray-900 sm:text-sm rounded-lg focus:ring-blue-600 focus:border-blue-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500`}
                  placeholder="••••••••"
                  required
                />
                {newPasswordErrorMessage && <span className="text-red-500 text-sm">{newPasswordErrorMessage}</span>}
              </div>
              <div>
                <label
                  htmlFor="confirmNewPassword"
                  className="block mb-2 text-sm font-medium text-gray-900 dark:text-black"
                >
                  Confirm New Password
                </label>
                <input
                  type="password"
                  name="confirmNewPassword"
                  value={confirmNewPassword}
                  onChange={(e) => setConfirmNewPassword(e.target.value)}
                  autoComplete="new-password"
                  id="confirmNewPassword"
                  className={`bg-gray-50 ${confirmNewPasswordError ? 'border-2 border-red-500' : 'border border-gray-300'} text-gray-900 sm:text-sm rounded-lg focus:ring-blue-600 focus:border-blue-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500`}
                  placeholder="••••••••"
                  required
                />
                {confirmNewPasswordErrorMessage && <span className="text-red-500 text-sm">{confirmNewPasswordErrorMessage}</span>}
              </div>
              {nonFieldError && <div className="text-red-500 text-sm font-bold mt-4">{nonFieldError}</div>}
              <button
                type="submit"
                className="mt-5 w-full text-white bg-blue-600 hover:bg-blue-700 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
              >
                Change Password
              </button>
            </form>
          </div>
        </div>
      </div>
    </section>
  )
}

export default ChangePasswordPage;
